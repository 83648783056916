// @ts-strict-ignore
import _ from 'lodash';
import { PersistenceLevel, Store } from '@/core/flux.service';

interface TimebarRegion {
  capsuleSetId: number;
  start: number;
  end: number;
  count: number;
  color: string;
  opacity: number;
}

/**
 * This store does not dehydrate or rehydrate. It is used to communicate
 * capsule regions. The timebar controller computes and updates the regions
 * in this store and the scatter plot controller listens for changes so it
 * can put the regions on scope for the minimap.
 */
export class TimebarStore extends Store {
  persistenceLevel: PersistenceLevel = 'NONE';
  static readonly storeName = 'sqTimebarStore';

  initialize() {
    this.state = this.immutable({ regions: [] });
  }

  get regions(): TimebarRegion[] {
    return this.state.get('regions');
  }

  protected readonly handlers = {
    /**
     * Sets the regions for a specified condition, overwriting any previous regions for that condition.
     *
     * @param {Object} payload - Object container for parameters
     * @param {String} payload.id - ID of the condition whose regions are being replaced
     * @param {Object[]} payload.regions - Regions to add for the specified condition
     */
    TIMEBAR_SET_REGIONS_FOR_CONDITION: (payload) => {
      this.state.set('regions', _.reject(this.state.get('regions'), ['capsuleSetId', payload.id]));
      this.state.concat('regions', payload.regions);
    },

    /**
     * Clears all the regions
     */
    TIMEBAR_CLEAR_REGIONS: () => this.state.set('regions', []),

    /**
     * Updates the color of regions belonging to an updated set
     *
     * @param {Object} payload - Object container for parameters
     * @param {String} payload.id - ID of the capsule whose color is being updated
     * @param {String} payload.color - The color of the recently updated set
     */
    TREND_SET_COLOR: (payload) => {
      _.forEach(this.state.get('regions'), (region: any, index) => {
        if (region.capsuleSetId === payload.id) {
          this.state.set(['regions', index, 'color'], payload.color);
        }
      });
    },
  };
}
