import React from 'react';
import { Icon } from '@seeqdev/qomponents';

interface MinimapHandleIF {
  color: string;
  id: string;
}

/**
 * Base minimap handle (just an icon)
 */
export const MinimapHandle: React.FunctionComponent<MinimapHandleIF> = (props) => {
  const { color, id } = props;

  return <Icon icon="fa-caret-down" large={true} type="color" color={color} testId={id} />;
};
