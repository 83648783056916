// @ts-strict-ignore
import _ from 'lodash';
import React from 'react';
import { Field, useForm } from 'react-final-form';
import { InputWithFormatter } from '@/core/InputWithFormatter.molecule';
import { useTranslation } from 'react-i18next';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import { FormatOptions } from '@/utilities/numberHelper.utilities';
import { ChartRegion } from '@/chart/chart.constants';

type Formatter = (number: any, options?: FormatOptions, error?: (...args: any[]) => void) => string;

export interface ScatterPlotRegionIF extends ValidatingFormComponent<ChartRegion> {
  component: 'ScatterPlotRegionFormComponent';
  formatter: Formatter;
}

export const ScatterPlotRegionFormComponent: React.FunctionComponent<ScatterPlotRegionIF> = (props) => {
  const { name, value, validation, extendValidation, formatter, onChange } = props;

  const { t } = useTranslation();
  const formState = useForm().getState();

  const defaultValidation = (value: ChartRegion) =>
    _.isNil(value) ||
    _.isNil(value.xMin) ||
    _.isNil(value.xMax) ||
    _.isNil(value.yMin) ||
    _.isNil(value.yMax) ||
    value.xMax <= value.xMin ||
    value.yMax <= value.yMin;

  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);

  const handleChange = (regionValue: string, field: 'xMin' | 'xMax' | 'yMin' | 'yMax', input) => {
    const parsedValue = parseFloat(regionValue);
    const newRegion = {
      ...value,
      [field]: _.isNaN(parsedValue) ? null : parsedValue,
    };
    onChange(newRegion);
    input.onChange(newRegion);
  };

  return (
    <FormFieldWrapper testId="scatterCondition" label="INVESTIGATE_TOOLS.SCATTER_CONDITION.HOW_TO">
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          const { value, showError } = getFormFieldProps(formState, input, meta, props);

          return (
            <table className="table table-fixed table-striped mt10 mb10" data-testid="scatterConditionTable">
              <tbody>
                <tr>
                  <th>{t('INVESTIGATE_TOOLS.SCATTER_CONDITION.AXIS_COLUMN')}</th>
                  <th>{t('INVESTIGATE_TOOLS.SCATTER_CONDITION.MIN_COLUMN')}</th>
                  <th>{t('INVESTIGATE_TOOLS.SCATTER_CONDITION.MAX_COLUMN')}</th>
                </tr>
                <tr>
                  <th className="verticalAlignMiddle">{t('INVESTIGATE_TOOLS.SCATTER_CONDITION.X_AXIS')}</th>
                  <td>
                    <InputWithFormatter
                      onChange={(event) => handleChange(event.currentTarget.value, 'xMin', input)}
                      value={value.xMin}
                      formatter={formatter}
                      inputType="number"
                      inputClassName="input input-number-no-spin"
                      testId="xMinInput"
                      maxLength={7}
                      isInvalid={showError && (_.isNil(value.xMin) || value.xMin >= value.xMax)}
                    />
                  </td>
                  <td>
                    <InputWithFormatter
                      onChange={(event) => handleChange(event.currentTarget.value, 'xMax', input)}
                      value={value.xMax}
                      formatter={formatter}
                      inputType="number"
                      inputClassName="input input-number-no-spin"
                      testId="xMaxInput"
                      maxLength={7}
                      isInvalid={showError && (_.isNil(value.xMax) || value.xMax <= value.xMin)}
                    />
                  </td>
                </tr>
                <tr>
                  <th className="verticalAlignMiddle">{t('INVESTIGATE_TOOLS.SCATTER_CONDITION.Y_AXIS')}</th>
                  <td>
                    <InputWithFormatter
                      onChange={(event) => handleChange(event.currentTarget.value, 'yMin', input)}
                      value={value.yMin}
                      formatter={formatter}
                      inputType="number"
                      inputClassName="input input-number-no-spin"
                      testId="yMinInput"
                      maxLength={7}
                      isInvalid={showError && (_.isNil(value.yMin) || value.yMin >= value.yMax)}
                    />
                  </td>
                  <td>
                    <InputWithFormatter
                      onChange={(event) => handleChange(event.currentTarget.value, 'yMax', input)}
                      value={value.yMax}
                      formatter={formatter}
                      inputType="number"
                      inputClassName="input input-number-no-spin"
                      testId="yMaxInput"
                      maxLength={7}
                      isInvalid={showError && (_.isNil(value.yMax) || value.yMax <= value.yMin)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          );
        }}
      </Field>
    </FormFieldWrapper>
  );
};
