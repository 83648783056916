import React from 'react';
import _ from 'lodash';
import { Img } from 'react-image';
import { useTranslation } from 'react-i18next';
import { Icon, Select } from '@seeqdev/qomponents';

export interface ImageSelectOption {
  text: string;
  value: any;
  imagePath: string;
}

interface ImageSelectProps {
  selectOptions: ImageSelectOption[];
  onChange: (
    option: {
      value: any;
    },
    property?: string,
  ) => void;
  name?: string;
  extraClassNames?: string;
  placeholder?: string;
  value?: any;
  testId?: string;
  insideModal?: boolean;
}

/** Select dropdown element that supports text+image as the individual items */
export const ImageSelect: React.FunctionComponent<ImageSelectProps> = (props) => {
  const { name, value, selectOptions, extraClassNames, placeholder, onChange, insideModal } = props;

  const { t } = useTranslation();

  const menuPosition = insideModal ? 'absolute' : 'fixed';

  const makeLabel = (option: ImageSelectOption) => {
    const isSelectedValue = value === option.value;
    return (
      <span className="flexColumnContainer flexAlignCenter">
        <span className="col-sm-6 flexColumnContainer flexAlignCenter">
          <Icon large={true} icon={isSelectedValue ? 'fa-check-circle' : 'fa-circle'} type="text" />
          <span className="ml10">{t(option.text)}</span>
        </span>
        <Img src={option.imagePath} height={25} alt={`${option.text}_image`} className="col-sm-6" />
      </span>
    );
  };

  const options = _.map(selectOptions, (option) => ({
    ...option,
    label: makeLabel(option),
  }));

  // This enables a different label to display for the selected item once the menu is closed
  const getOptionLabel = (data: ImageSelectOption) => (
    <span className="flexColumnContainer">
      <span className="mt2 pr10">{t(data.text)}</span>
      <Img src={data.imagePath} height={20} alt={`${data.text}_image`} />
    </span>
  );

  return (
    <div data-testid={`${name}_image-select`}>
      <Select
        extraClassNames={extraClassNames}
        placeholder={_.isEmpty(placeholder) ? '' : t(placeholder as string)}
        value={_.find(options, { value })}
        menuPortalTarget={document.body}
        menuPlacement="auto"
        options={options}
        isSearchable={false}
        getSelectedValueLabel={getOptionLabel}
        onChange={(selectedOption: { value: unknown }) => onChange(selectedOption, name)}
      />
    </div>
  );
};
