import { PersistenceLevel, Store } from '@/core/flux.service';

/**
 * The Flux store for workstep state
 */

export class WorkstepsStore extends Store {
  persistenceLevel: PersistenceLevel = 'NONE';
  static readonly storeName = 'sqWorkstepStore';

  initialize() {
    this.state = this.immutable({
      previous: undefined,
      current: {
        id: undefined,
        state: {},
      },
      next: undefined,
      last: undefined,
      isError: false,
    });
  }

  /**
   * Gets the unique ID of the previous workstep
   *
   * @returns The previous workstep or undefined there is no previous workstep
   */
  get previous(): string | undefined {
    return this.state.get('previous');
  }

  /**
   * Gets the id and worksheet state of the current workstep
   *
   * @returns A container object
   *  - object.id - The unique ID of the current workstep
   *  - object.state - The current state of the worksheet
   */
  get current(): { id: string; state: any } {
    return this.state.get('current');
  }

  /**
   * Gets the unique ID of the next workstep
   *
   * @returns The next unique ID workstep or undefined there is no previous workstep
   */
  get next(): string | undefined {
    return this.state.get('next');
  }

  /**
   * Gets the unique ID of the last workstep
   *
   * @returns The last unique ID workstep
   */
  get last(): string {
    return this.state.get('last');
  }

  /**
   * Returns true if the workstep subsystem is in an error state.
   *
   * @returns True if this store is empty because of an error
   */
  get isError(): boolean {
    return !!this.state.get('isError');
  }

  /**
   * Sets workstep state
   *
   * @param payload - Object container for arguments
   * @param payload.previous - The ID of the previous workstep
   * @param payload.current - Object container for current state
   * @param payload.current.id - The ID of the current workstep
   * @param payload.current.state - The current workstep state
   * @param payload.next - The ID of the next workstep
   * @param payload.last - The ID of the last workstep
   * @param payload.isError - True if data is empty due to an error
   */
  protected readonly handlers = {
    WORKSTEPS_SET: (payload: {
      previous: string;
      current: { id: string; state: any };
      next: string;
      last: string;
      isError: boolean;
    }) => this.state.set(payload),
  };
}
