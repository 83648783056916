export const ALL = [{ key: 'all', display: 'PERIODIC_CONDITION.FREQUENCY_ALL' }];
export const STANDARD_DURATIONS = [
  { key: 'hours', display: 'PERIODIC_CONDITION.DURATION_HOUR' },
  { key: 'shifts', display: 'PERIODIC_CONDITION.DURATION_HOURS' },
  { key: 'days', display: 'PERIODIC_CONDITION.DURATION_DAY' },
  { key: 'weeks', display: 'PERIODIC_CONDITION.DURATION_WEEK' },
  { key: 'workweeks', display: 'PERIODIC_CONDITION.DURATION_WEEK_WORK' },
  { key: 'weekends', display: 'PERIODIC_CONDITION.DURATION_WEEK_END' },
  { key: 'months', display: 'PERIODIC_CONDITION.DURATION_MONTH' },
  { key: 'quarters', display: 'PERIODIC_CONDITION.DURATION_QUARTER' },
  { key: 'years', display: 'PERIODIC_CONDITION.DURATION_YEAR' },
  { key: 'periods', display: 'PERIODIC_CONDITION.DURATION_CUSTOM' },
];
export const DAYS = [
  { key: 'Day.Sunday', display: 'PERIODIC_CONDITION.DAY.SUNDAY' },
  { key: 'Day.Monday', display: 'PERIODIC_CONDITION.DAY.MONDAY' },
  { key: 'Day.Tuesday', display: 'PERIODIC_CONDITION.DAY.TUESDAY' },
  { key: 'Day.Wednesday', display: 'PERIODIC_CONDITION.DAY.WEDNESDAY' },
  { key: 'Day.Thursday', display: 'PERIODIC_CONDITION.DAY.THURSDAY' },
  { key: 'Day.Friday', display: 'PERIODIC_CONDITION.DAY.FRIDAY' },
  { key: 'Day.Saturday', display: 'PERIODIC_CONDITION.DAY.SATURDAY' },
];
export const MONTHS = [
  {
    key: 'Month.January',
    days: 31,
    display: 'PERIODIC_CONDITION.MONTH.JANUARY',
  },
  {
    key: 'Month.February',
    days: 28,
    display: 'PERIODIC_CONDITION.MONTH.FEBRUARY',
  },
  { key: 'Month.March', days: 31, display: 'PERIODIC_CONDITION.MONTH.MARCH' },
  { key: 'Month.April', days: 30, display: 'PERIODIC_CONDITION.MONTH.APRIL' },
  { key: 'Month.May', days: 31, display: 'PERIODIC_CONDITION.MONTH.MAY' },
  { key: 'Month.June', days: 30, display: 'PERIODIC_CONDITION.MONTH.JUNE' },
  { key: 'Month.July', days: 31, display: 'PERIODIC_CONDITION.MONTH.JULY' },
  { key: 'Month.August', days: 31, display: 'PERIODIC_CONDITION.MONTH.AUGUST' },
  {
    key: 'Month.September',
    days: 30,
    display: 'PERIODIC_CONDITION.MONTH.SEPTEMBER',
  },
  {
    key: 'Month.October',
    days: 31,
    display: 'PERIODIC_CONDITION.MONTH.OCTOBER',
  },
  {
    key: 'Month.November',
    days: 30,
    display: 'PERIODIC_CONDITION.MONTH.NOVEMBER',
  },
  {
    key: 'Month.December',
    days: 31,
    display: 'PERIODIC_CONDITION.MONTH.DECEMBER',
  },
];
export const QUARTERS = [
  { key: 'Quarter.First', display: 'PERIODIC_CONDITION.QUARTER.FIRST' },
  { key: 'Quarter.Second', display: 'PERIODIC_CONDITION.QUARTER.SECOND' },
  { key: 'Quarter.Third', display: 'PERIODIC_CONDITION.QUARTER.THIRD' },
  { key: 'Quarter.Fourth', display: 'PERIODIC_CONDITION.QUARTER.FOURTH' },
];
export const FREQUENCY_CHOICES = [
  { key: 'days', choices: DAYS },
  { key: 'months', choices: MONTHS },
  { key: 'quarters', choices: QUARTERS },
];
