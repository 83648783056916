import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useState } from 'react';
import { getStorageSafely } from '@/utilities/storage.utilities';
import { sqAuthApi } from '@/sdk';
import { successToast } from '@/utilities/toast.utilities';
import { goTo } from '@/main/routing.utilities';

interface ResetPasswordFormData {
  newPassword: string;
  passwordCheck: string;
  [key: string]: string | undefined;
}

export const useResetPassword = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<ResetPasswordFormData>({
    newPassword: '',
    passwordCheck: '',
  });
  const [resetPasswordError, setResetPasswordError] = useState<string | undefined>('');

  const { newPassword } = formData;
  const urlParams = Object.fromEntries(new URLSearchParams(window.location.search));
  const { email, token } = _.pick(urlParams, ['email', 'token']);

  const updateFormData = (data: Partial<typeof formData>) =>
    setFormData((formData) => ({
      ...formData,
      ...data,
    }));

  /**
   * Performs the actual reset call.
   * Also resets all form fields.
   *
   * @returns {Promise} that resolves when the reset attempt is completed, either success or failure.
   * If the reset fails this method also sets a member variable to show an error message.
   */
  const doResetPassword = (): Promise<any> => {
    setResetPasswordError('');

    // In some cases, params such as returnTo may be lost during authentication with an outside identity provider
    const urlParams = Object.fromEntries(new URLSearchParams(window.location.search));
    if (_.has(urlParams, 'returnTo')) {
      getStorageSafely().setItem('stateParams', JSON.stringify(urlParams));
    }

    return sqAuthApi
      .resetPassword({
        email,
        token,
        newPassword,
      })
      .then(() => {
        successToast({ messageKey: 'RESET_PASSWORD_PANEL.SUCCESS_MESSAGE' });
        goTo('/login');
      })
      .catch((e) => {
        const errorMessage = e?.response?.data?.statusMessage ?? t('LOGIN_PANEL.FRONTEND_ERROR');
        setResetPasswordError(errorMessage);
      });
  };

  return {
    formData,
    doResetPassword,
    resetPasswordError,
    updateFormData,
  };
};
