import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { Button } from '@seeqdev/qomponents';
import {
  addSample as addSampleAction,
  setEditingSampleId,
  updateSample as updateSampleAction,
} from '@/tools/manualSignal/manualSignal.actions';
import { EditableText } from '@/core/EditableText.atom';
import { DateTimeEntry } from '@/core/DateTimeEntry.atom';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { FormError } from '@/core/FormError.atom';
import { STRING_UOM } from '@/main/app.constants';
import { SAMPLE_VALUE_ERROR } from '@/tools/manualSignal/manualSignal.constants';
import { sqManualSignalStore } from '@/core/core.stores';

interface SampleInputProps {
  testId: string;
  sample?: { dateTime: any; value: any; id: string };
  timezone: {
    name: string;
    displayName: string;
    offset: string;
    offsetMinutes: number;
  };
}

export const SampleInput: React.FunctionComponent<SampleInputProps> = ({ sample, timezone, testId }) => {
  const { t } = useTranslation();

  const samples = useFluxPath(sqManualSignalStore, () => sqManualSignalStore.samples);
  const isStringUom = useFluxPath(sqManualSignalStore, () => sqManualSignalStore.uom === STRING_UOM);
  const isStringSignal = isStringUom || _.isNaN(_.toNumber(sqManualSignalStore.samples[0]?.value));
  const [dateTime, setDateTime] = useState<moment.Moment>(
    sample?.dateTime ? moment.utc(sample.dateTime) : moment.utc().startOf('minute'),
  );
  const [value, setValue] = useState(sample?.value ?? '');
  const [valueError, setValueError] = useState<string | undefined>(undefined);
  const [dateError, setDateError] = useState(false);

  useEffect(() => {
    const newDateTimeValue = dateTime.valueOf();
    const sampleId = sample?.id;
    const hasError = _.some(samples, (sample) => sample.dateTime === newDateTimeValue && sample.id !== sampleId);
    setDateError(hasError && !_.isEmpty(value));
  }, [samples, dateTime, value]);

  useEffect(() => {
    const hasValueTypeError = !isStringSignal && _.isNaN(_.toNumber(value));
    setValueError(hasValueTypeError ? SAMPLE_VALUE_ERROR.INVALID_TYPE : undefined);
  }, [value, isStringSignal, samples, isStringUom]);

  const resetState = () => {
    setValue('');
    setDateTime(moment.utc().startOf('minute'));
    setValueError(undefined);
    setDateError(false);
  };

  const addSample = () => {
    if (_.isEmpty(value)) {
      setValueError(SAMPLE_VALUE_ERROR.EMPTY);
      return;
    }
    if (dateError || !_.isUndefined(valueError)) return;

    addSampleAction({ dateTime: dateTime.valueOf(), value });
    resetState();
  };

  const updateSample = () => {
    if (dateError || !_.isUndefined(valueError) || !sample) return;

    updateSampleAction({ id: sample.id, dateTime: dateTime.valueOf(), value });
    setEditingSampleId(undefined);
    resetState();
  };

  return (
    <div className="sampleInput flexRowContainer pt10 pb10" data-testid={testId}>
      <div className="flexColumnContainer flexFill text-bold pl10 pr10 text-center">
        <div className="flexFillEqually">{t('MANUAL_SIGNAL.DATE_TIME')}</div>
        <div className="flexFillEqually">{t('MANUAL_SIGNAL.VALUE')}</div>
      </div>
      <div className="flexColumnContainer flexFill sq-text-primary pl10 pr10 text-center">
        <div className="flexFillEqually flexSelfCenter">
          <DateTimeEntry
            testId="manualSignalDateTimeEntry"
            hideTimezone={true}
            fieldIdentifier="MANUAL_SIGNAL_TOOL_DATE_TIME"
            trackCategory="Workbench_Tool"
            trackAction="Manual_Signal"
            trackInformation="Date time changed"
            textExtraClassNames={classNames({ 'sq-text-danger': dateError })}
            updateDate={setDateTime}
            timezone={timezone}
            date={dateTime}
          />
        </div>
        <div className="flexFillEqually flexSelfCenter">
          <div className="nowrap">
            <EditableText
              value={_.isEmpty(value) ? t('MANUAL_SIGNAL.VALUE_PLACEHOLDER') : value}
              inputClasses="text-center"
              testId={sample?.id ? `sampleValue-${sample.id}` : 'newSampleValue'}
              maxDisplayChars={15}
              onUpdate={(newValue) => {
                setValueError(_.isEmpty(newValue) ? SAMPLE_VALUE_ERROR.EMPTY : undefined);
                setValue(newValue);
              }}
              textClasses={classNames(
                'pt1 pb1',
                { 'text-italic': _.isEmpty(value) },
                { 'sq-text-danger': !_.isUndefined(valueError) },
              )}
            />
          </div>
        </div>
      </div>

      {sample?.id ? (
        <div className="flexJustifyCenter flexColumnContainer mt5">
          <Button
            label={t('CANCEL')}
            extraClassNames="inlineBlock mr10"
            onClick={() => setEditingSampleId(undefined)}
          />
          <Button
            testId="saveSampleButton"
            label={t('SAVE')}
            variant="theme"
            disabled={_.isEmpty(value)}
            extraClassNames="inlineBlock"
            onClick={updateSample}
          />
        </div>
      ) : (
        <div className="text-center mt5">
          <Button
            testId="addSampleButton"
            label={t('MANUAL_SIGNAL.ADD_SAMPLE')}
            extraClassNames="inlineBlock"
            onClick={addSample}
          />
        </div>
      )}

      {dateError && <FormError extraClassNames="mt5 ml10 mr1 mb0" errorText="MANUAL_SIGNAL.DATE_TIME_ERROR" />}

      {valueError === SAMPLE_VALUE_ERROR.INVALID_TYPE && (
        <FormError extraClassNames="mt5 ml10 mr1 mb0" errorText="MANUAL_SIGNAL.VALUE_TYPE_ERROR" />
      )}
    </div>
  );
};
