import React, { useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { TooltipPosition } from '@seeqdev/qomponents/dist/Tooltip/Tooltip.types';
import { ButtonWithPopover, Icon } from '@seeqdev/qomponents';
import { FakeLink } from '@/core/FakeLink';
import { CustomPriority } from '@/tools/thresholdMetric/metric.types';

type Placement = 'top' | 'bottom' | 'left' | 'right';

interface PriorityPickerProps {
  onChange: (value: number) => void;
  placement: Placement;
  buttonTooltip?: string;
  buttonTooltipPlacement?: TooltipPosition;
  buttonTestId: string;
  buttonClasses: string;
  value: CustomPriority[];
}

export const PriorityPicker: React.FunctionComponent<PriorityPickerProps> = ({
  onChange,
  buttonTooltip,
  buttonTooltipPlacement,
  placement,
  buttonTestId,
  buttonClasses,
  value: priorities,
}) => {
  const { t } = useTranslation();

  const [showPriorityPicker, setShowPriorityPicker] = useState(false);

  const selectPriority = (event: React.MouseEvent, priority: CustomPriority) => {
    onChange(priority.level);
    isPriorityAvailable();
    event.stopPropagation();
  };

  /**
   * Determines if a priority is is still available (i.e. they are not already all in use)
   *
   * @returns true if available, false otherwise.
   */
  const isPriorityAvailable = (): boolean => _.chain(priorities).reject({ level: 0 }).some({ inUse: false }).value();

  return (
    <div className="width-25 cursorPointer">
      <ButtonWithPopover
        isOpen={showPriorityPicker}
        onOpenChange={setShowPriorityPicker}
        trigger={
          <div data-testid={buttonTestId}>
            <FakeLink>
              <Icon
                icon={buttonClasses}
                tooltip={buttonTooltip === undefined ? '' : t(buttonTooltip)}
                tooltipPlacement={buttonTooltipPlacement}
                extraClassNames="mr5"
              />
            </FakeLink>
          </div>
        }
        hasArrow
        isCloseOnContentClick
        placement={placement}>
        <div
          id="priorityPickerPopover"
          data-testid="priorityPickerPopover"
          className="popover priorityPopover"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        >
          <div className="popover-header">{t('INVESTIGATE_TOOLS.THRESHOLD_METRIC.CHOOSE_PRIORITY_LEVEL')}</div>
          <div className="p5">
            <div className="picker-close">
              <Icon onClick={() => setShowPriorityPicker(false)} icon="fa-close cursorPointer" />
            </div>
            <div className="flexColumnContainer text-bolder dividerBorderBottom">
              <div className="text-nowrap">{t('INVESTIGATE_TOOLS.THRESHOLD_METRIC.DEFAULT_COLOR')}</div>
              <div className="flexFill text-center">{t('NAME')}</div>
            </div>
            <div className="flexRowContainer positionRelative overflowYAuto msOverflowStyleAuto max-height-275">
              {_.map(
                priorities.filter((priority) => priority.level),
                (priority) => (
                  <div
                    key={priority.level}
                    data-testid={`${priority.name}ThresholdPriority`}
                    className={classNames('positionRelative flexColumnContainer flexNoGrowNoShrink lightGreyBorder', {
                      'cursorPointer hoverBorder focusBorder': !priority.inUse,
                    })}
                    onClick={(event) => selectPriority(event, priority)}>
                    <div
                      className={classNames({
                        selectedOverlay: priority.inUse,
                      })}
                    />
                    <div style={{ backgroundColor: priority.color }} className="width-70" />
                    <div className="flexFill aggressiveWordBreak pl5">{priority.name}</div>
                  </div>
                ),
              )}
              {!isPriorityAvailable() && (
                <div className="positionAbsoluteFill flexRowContainer flexCenter">
                  <div className="mt5 sq-fairly-dark-gray text-italic">
                    {t('INVESTIGATE_TOOLS.THRESHOLD_METRIC.ALL_PRIORITIES_IN_USE')}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </ButtonWithPopover>
    </div>
  );
};
