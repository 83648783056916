import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  formatSimpleDuration,
  parseDuration,
  parseDurationIntoDates,
  parseDurationOffset,
} from '@/datetime/dateTime.utilities';
import { ControlledTooltip } from '@/core/ControlledTooltip.atom';
import { EditableText } from '@/core/EditableText.atom';
import { Timezone } from '@/datetime/timezone.service';

interface DurationEntryProps {
  startDate: moment.Moment;
  endDate: moment.Moment;
  updateDuration: (duration: moment.Moment | number | moment.Duration) => void;
  updateDates: (start: moment.Moment | number, end: moment.Moment | number) => void;
  timezone: Timezone;
  readOnly?: boolean;
  extraClassNames?: string;
  textExtraClassNames?: string;
  inputExtraClassNames?: string;
  autoCorrect?: boolean;
}

export const DurationEntry: React.FunctionComponent<DurationEntryProps> = ({
  startDate,
  endDate,
  updateDates,
  updateDuration,
  timezone,
  readOnly,
  textExtraClassNames,
  inputExtraClassNames,
  extraClassNames,
  autoCorrect = true,
}) => {
  const [isError, setIsError] = useState(false);
  const [parseError, setParseError] = useState('');
  const target = useRef(null);

  const { t } = useTranslation();

  const formatNewDuration = () => formatSimpleDuration(moment.duration(endDate.diff(startDate)));
  const formatDuration = () => {
    const newDuration = formatNewDuration();
    if (endDate.isSameOrBefore(startDate) && autoCorrect) {
      showError(newDuration);
      return originalText;
    } else {
      return newDuration;
    }
  };

  const [originalText, setOriginalText] = useState(formatNewDuration());
  const [editText, setEditText] = useState(formatNewDuration());

  useEffect(() => {
    setOriginalText(formatNewDuration());
    setEditText(formatNewDuration());
  }, [t]);

  const updateDisplayValue = () => {
    if (startDate.isValid() && endDate.isValid() && !isError) {
      const newEditText = formatDuration();
      setEditText(newEditText);
      setOriginalText(newEditText);
    } else {
      setEditText(originalText);
    }
  };

  const revertCorrectValue = () => {
    setEditText(originalText);
    setOriginalText(originalText);
  };

  useEffect(() => {
    updateDisplayValue();
  }, [startDate.valueOf(), endDate.valueOf(), timezone]);

  const onDurationChange = (value: string) => {
    setOriginalText(editText);
    if (value.length > 50) {
      showError(`${value.substring(0, 40)}...`);
      return;
    }
    let parser;
    let newDuration, newDates;
    let trimmedInput = _.trim(value);
    const inputValueNumberOnly = !_.isEmpty(trimmedInput) && !_.isNaN(_.toNumber(trimmedInput));
    const origUnits = editText.replace(/^[\w.]+/, '');

    // If no units were supplied and we have original units, then use the original units
    if (inputValueNumberOnly && !_.isEmpty(origUnits)) {
      trimmedInput = `${trimmedInput}${origUnits}`;
    }

    // attempt to parse as an anchored duration offset
    if (!parser) {
      newDates = parseDurationIntoDates(trimmedInput, moment.utc(), undefined, undefined);
      if (newDates.start.isValid() && newDates.end.isValid()) {
        updateDates(newDates.start, newDates.end);
        parser = 'parseDurationIntoDates';
      }
    }

    // attempt to parse as a duration offset
    if (!parser) {
      newDuration = parseDurationOffset(trimmedInput);
      if (newDuration.valueOf() !== 0) {
        updateDates(moment.utc(startDate).add(newDuration), moment.utc(endDate).add(newDuration));
        parser = 'parseDurationOffset';
      }
    }

    // attempt to parse as a duration
    if (!parser) {
      newDuration = parseDuration(trimmedInput);
      if (newDuration.valueOf() !== 0) {
        updateDuration(newDuration);
        parser = 'parseDuration';
      }
    }

    if (!parser) {
      showError(trimmedInput);
    } else {
      clearError();
    }
  };

  const clearError = () => {
    setIsError(false);
    setParseError('');
  };

  const showError = (input: string) => {
    if (autoCorrect) {
      revertCorrectValue();
      setIsError(true);
      setParseError(input);
      setTimeout(clearError, 3000);
    }
  };

  const errorTooltip = (
    <span>
      {t('PARSE_DURATION_ERROR')}
      <span className="text-bolder">{parseError}</span>
    </span>
  );

  return (
    <div
      className={classNames('nowrap durationTimeEntry small text-center pl5 pr5', extraClassNames)}
      data-testid="durationTimeEntry">
      {readOnly && (
        <span className="readOnly" data-testid="durationEntryReadOnly">
          {editText}
        </span>
      )}
      {!readOnly && (
        <>
          {target.current ? (
            <ControlledTooltip
              extraClassNames="errorTooltip"
              target={target.current}
              show={isError}
              id="error-tooltip-duration"
              formattedText={errorTooltip}
            />
          ) : null}

          <span ref={target}>
            <EditableText
              id="durationEntryField"
              testId="durationEntryField"
              textClasses={classNames('overflowHidden', 'inlineFlex', textExtraClassNames)}
              inputClasses={classNames(
                'overflowHidden',
                'inlineFlex',
                'max-width-100',
                'width-auto',
                'fs11',
                inputExtraClassNames,
              )}
              value={editText}
              autoWidth={true}
              onUpdate={onDurationChange}
            />
          </span>
        </>
      )}
    </div>
  );
};
