import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { ButtonWithDropdown, Icon } from '@seeqdev/qomponents';
import { ColumnAction } from '@/core/tableUtilities/tableUtilities.types';
import { TextHeaderMenuAction } from '@/tableBuilder/tableBuilder.constants';
import { CapsulesPanelColumnHeaderMenuActions } from '@/trend/panels/capsulesPanel/capsulesPanel.types';

type GenericColumnAction = ColumnAction<TextHeaderMenuAction | CapsulesPanelColumnHeaderMenuActions>;

export interface TableColumnMenuProps {
  columnActions: GenericColumnAction[];
  columnKey: string;
}

/**
 * A three-dot "kebab" menu, with a dropdown. menuActions are dropdown options that are passed in as arguments.
 */
export const TableColumnMenu: React.FunctionComponent<TableColumnMenuProps> = ({ columnActions, columnKey }) => {
  const { t } = useTranslation();

  const generateDropdownEntry = (menuEntry: GenericColumnAction) => {
    return {
      key: `dropdown_${menuEntry.translationKey}`,
      id: `dropdown_${menuEntry.translationKey}`,
      icon: menuEntry.iconClass,
      onClick: () => {
        menuEntry.action?.();
      },
      subMenuItems: _.map(menuEntry.subMenuItems, (submenuItem) => ({
        iconClass: submenuItem.iconClass,
        label: t(submenuItem.translationKey),
        onClick: submenuItem.action,
        disabled: submenuItem.disabled,
        tooltip: submenuItem.tooltip,
      })),
      testId: `actions_${menuEntry.translationKey}`,
      label: t(menuEntry.translationKey),
      disabled: menuEntry.disabled,
    };
  };

  return (
    <ButtonWithDropdown
      triggerIcon={
        <div className="sq-icon-hover">
          <Icon icon="fc-more" extraClassNames="fa-fw mt2" type="gray" testId="columnMoreActions" />
        </div>
      }
      keepFocusInsideDropdown={false}
      placement="bottom"
      setFocusOnTriggerOnClose={false}
      id={`dropdown-${columnKey}`}
      containerTestId={`dropdown-${columnKey}Content`}
      extraClassNames="tableColumnMenu"
      dropdownItems={columnActions.map((action) => generateDropdownEntry(action)).filter(Boolean)}
    />
  );
};
