// @ts-strict-ignore
import _ from 'lodash';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead';
import { IdentityPreviewV1 } from '@/sdk/model/IdentityPreviewV1';
import { sqUsersApi } from '@/sdk/api/UsersApi';
import { Icon, TextField } from '@seeqdev/qomponents';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { isAdmin } from '@/services/authorization.service';
import { DoneLoadingIndicator } from '@/core/DoneLoadingIndicator.atom';

export type IdentityOption = Pick<IdentityPreviewV1, 'id' | 'name' | 'username' | 'email' | 'datasource'> & {
  customOption?: boolean;
  label?: string;
};

type IdentityOptionType<T> = T extends true ? IdentityOption[] : IdentityOption;

interface SelectIdentityProps<T> {
  idForLabel?: string;
  setIdentity: (object: IdentityOptionType<T>) => void;
  onIdentityChange?: (identity: IdentityOptionType<T>) => void;
  identity: IdentityOptionType<T>;
  allowGroups?: boolean;
  showDirectory?: boolean;
  includeAllProperties?: boolean;
  startEditable?: boolean;
  placeholder?: string;
  tooltip?: string;
  isInvalid?: boolean;
  unauthorizedTooltip?: string;
  multiple?: T;
  clearIdentityWhenEmpty?: boolean;
  allowNew?: boolean;
  maxHeight?: string;
  positionFixed?: boolean;
  skipIdentity?: string | null;
}

/** Input field for searching and selecting Seeq Identities (Users or Groups) */
export const SelectIdentity = <T extends boolean = false>(props: SelectIdentityProps<T>) => {
  const {
    setIdentity,
    onIdentityChange,
    identity,
    placeholder,
    tooltip,
    unauthorizedTooltip,
    idForLabel,
    allowGroups = false,
    showDirectory = false,
    includeAllProperties = false,
    startEditable = false,
    multiple = false,
    isInvalid,
    clearIdentityWhenEmpty = false,
    allowNew = false,
    maxHeight = undefined,
    positionFixed = false,
    skipIdentity = null,
  } = props;
  const { t } = useTranslation();

  const [loadingIdentities, setLoadingIdentities] = useState(false);
  const [options, setOptions] = useState<IdentityOption[]>([]);
  const [editingIdentity, setEditingIdentity] = useState(startEditable);
  const ref = createRef<Typeahead>();
  const selectedIdentities = _.reject(_.castArray(identity), _.isEmpty);

  useEffect(() => {
    if (_.isEmpty(identity) && clearIdentityWhenEmpty && !loadingIdentities) {
      ref.current.clear();
    }
  }, [identity]);

  const renderMenu = (option, props) => (
    <div className="acl-user-menu-options">
      {option.type === 'User' && <Icon icon="fa-user" extraClassNames="width-18 icon-hover" type="text" />}
      {option.type === 'UserGroup' && <Icon icon="fa-user-group" extraClassNames="width-18 icon-hover" type="text" />}
      <Highlighter search={props.text}>{option.name}</Highlighter>
      {option.type === 'User' && (
        <span className="ml3">
          <small>({option.email || option.username})</small>
        </span>
      )}
      {showDirectory ? (
        <div className="ml18">
          <small>{`${t('ADMIN.USER.DIRECTORY')}: ${option.datasource?.name}`}</small>
        </div>
      ) : null}
    </div>
  );

  const handleIdentitiesSearch = useCallback((query: string) => {
    setLoadingIdentities(true);

    return (
      sqUsersApi
        .autocompleteUsersAndGroups({ query })
        // Filter out user groups
        .then(({ data: { items } }) => {
          const usersAndGroups = allowGroups ? items : _.filter(items, { type: 'User' });
          const filtered: IdentityOption[] = includeAllProperties
            ? usersAndGroups
            : _.map(usersAndGroups, (userOrGroup) =>
                _.pick(userOrGroup, ['id', 'name', 'username', 'type', 'email', 'datasource']),
              );

          if (skipIdentity) {
            const skip = _.find(filtered, { id: skipIdentity });
            _.pull(filtered, skip);
          }

          setOptions(filtered);
        })
        .finally(() => {
          setLoadingIdentities(false);
        })
    );
  }, []);

  const onChange = (selected) => {
    if (multiple) {
      setIdentity(selected);
      onIdentityChange?.(selected);
    } else {
      const selectedIdentity = (selected.length === 1 ? _.first(selected) : {}) as IdentityOptionType<T>;
      setIdentity(selectedIdentity);
      onIdentityChange?.(selectedIdentity);
    }
  };

  const editIdentity = () => {
    setEditingIdentity(true);
    setIdentity({} as IdentityOptionType<T>);
  };

  const renderIdentity = (option): string => {
    if (_.isNil(option)) {
      return '';
    }

    if (option?.email) {
      return `${option.name} (${option.email})`;
    } else if (option?.username) {
      return `${option.name} (${option.username})`;
    } else {
      return option?.name;
    }
  };

  const renderEditable = (
    <div className="input-group width-maximum selectIdentity" data-testid={`selectIdentityInput_${idForLabel}`}>
      {loadingIdentities && <DoneLoadingIndicator />}
      <AsyncTypeahead
        id={idForLabel}
        ref={ref}
        flip={true}
        labelKey={(option) => renderIdentity(option)}
        allowNew={allowNew}
        multiple={multiple}
        options={options}
        isLoading={loadingIdentities}
        filterBy={(option: IdentityOption) => !_.some(selectedIdentities, { id: option.id })}
        onSearch={handleIdentitiesSearch}
        onChange={onChange}
        renderMenuItemChildren={renderMenu}
        placeholder={t(placeholder)}
        selected={selectedIdentities}
        isInvalid={isInvalid}
        positionFixed={positionFixed}
        maxHeight={maxHeight}
      />
    </div>
  );

  const renderDisplayMode = (
    <div className="input-group width-maximum nowrap" data-testid={`selectIdentityViewMode_${idForLabel}`}>
      <TextField id={idForLabel} type="text" readonly={true} value={renderIdentity(identity)} />
      <span
        className="sq-btn input-group-btn input-group-text cursorPointer fs15"
        data-testid={`selectIdentityEditIdentity_${idForLabel}`}
        onClick={editIdentity}>
        <Icon icon="fc-edit" type="text" tooltip={t(tooltip)} />
      </span>
    </div>
  );

  const renderViewOnly = (
    <div className="input-group width-maximum nowrap" data-testid={`selectIdentityViewMode_${idForLabel}`}>
      <HoverTooltip text={unauthorizedTooltip} placement="top">
        <TextField
          id={idForLabel}
          extraClassNames="form-control"
          type="text"
          readonly={true}
          value={renderIdentity(identity)}
        />
      </HoverTooltip>
    </div>
  );

  if (editingIdentity) {
    return renderEditable;
  } else if (isAdmin()) {
    return renderDisplayMode;
  } else {
    return renderViewOnly;
  }
};
