import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup } from 'react-bootstrap';
import { Checkbox } from '@seeqdev/qomponents';
import { DailyScheduleType } from '@/schedule/schedule.types';

const SCHEDULE_TYPE_TRANSLATIONS = {
  [DailyScheduleType.EVERY_DAY]: 'SCHEDULE_TYPE.DAILY.EVERY_DAY',
  [DailyScheduleType.EVERY_WEEKDAY]: 'SCHEDULE_TYPE.DAILY.WEEKDAYS',
};

interface DailyScheduleProps {
  selectedType: DailyScheduleType;
  onSelect: (dailyScheduleType: DailyScheduleType) => void;
}

/**
 * Selector for day frequency
 */
export const DailySchedule: React.FunctionComponent<DailyScheduleProps> = ({ selectedType, onSelect }) => {
  const { t } = useTranslation();

  return (
    <FormGroup data-testid="daily-schedule-config" className="mb0">
      {_.map(Object.entries(SCHEDULE_TYPE_TRANSLATIONS), ([type, translation]) => {
        const id = `schedule-type-${type}`;
        const isCurrent = type === selectedType;

        return (
          <Checkbox
            id={id}
            testId={id}
            key={type}
            type="radio"
            label={t(translation)}
            checked={isCurrent}
            onChange={() => !isCurrent && onSelect(type as DailyScheduleType)}
          />
        );
      })}
    </FormGroup>
  );
};
