import { SearchPanes } from '@/search/search.constants';
import { BaseSearchStore } from '@/search/baseSearch.store';
import { PersistenceLevel } from '@/core/flux.service';

/**
 * Provides an instance of search state used when the search widget is used in a modal. Does not dehyrdate any state
 * since the tool is shown in a modal.
 */

export class ModalSearchStore extends BaseSearchStore {
  static readonly storeName = 'sqModalSearchStore';
  pane: SearchPanes = 'modal';
  persistenceLevel: PersistenceLevel = 'NONE';
}
