import React, { forwardRef, Suspense } from 'react';
import { LoadingFallback } from '@/main/LoadingFallback.atom';
import type { AgGridReact } from '@ag-grid-community/react';
import { AgGridAsyncProps } from '@/core/tableUtilities/tableUtilities.types';

const AgGrid = React.lazy(() => import('@/defaultExportShims/AgGrid'));

export const AgGridAsync = forwardRef<AgGridReact, AgGridAsyncProps>((props, ref) => {
  return (
    <Suspense fallback={<LoadingFallback />}>
      <AgGrid {...props} ref={ref}></AgGrid>
    </Suspense>
  );
});
