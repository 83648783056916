import React from 'react';

export interface WrapperWithLinkProps {
  link: string;
  children: React.ReactNode;
}

/**
 * This component is used to wrap another component with a link. Enables "open in new tab" functionality.
 * Best for wrapping components that have a single link associated with their click, without complex logic.
 * */
export const WrapperWithLink: React.FunctionComponent<WrapperWithLinkProps> = ({ link, children }) => {
  return (
    <a
      onClick={(e) => {
        e.preventDefault();
      }}
      href={link}>
      {children}
    </a>
  );
};
