import _ from 'lodash';

/**
 * Determines if the app is rendered by in the headless browser for screen capture
 *
 * @return {Boolean} whether or not the application is rendered in the headless browser
 *
 * To emulate headless capture mode, add the following code to the function (which is helpful for debugging since it
 * emulates what Puppeteer does with the headless browser):
 * if (!_.isFunction(window.seeqHeadlessCapture)) {
 *   window.seeqHeadlessCapture = _.noop;
 *   window.seeqHeadlessCategory = _.constant(Promise.resolve('Screenshot'));
 *   window.seeqHeadlessLoading = _.noop;
 *   window.seeqHeadlessError = _.noop;
 *   window.seeqHeadlessWarning = _.noop;
 *   window.seeqHeadlessCancellation = _.noop;
 *   window.seeqJobFormat = _.constant(Promise.resolve('PDF'));
 * }
 */
export function headlessRenderMode(): boolean {
  return _.isFunction(window.seeqHeadlessCapture);
}
