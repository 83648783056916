// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Button } from '@seeqdev/qomponents';
import { useTranslation } from 'react-i18next';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { getAllItems } from '@/trend/trendDataHelper.utilities';
import { AddToDisplayPane } from '@/workbook/AddToDisplayPane.molecule';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { SelectItemWrapper } from '@/core/SelectItemWrapper.organism';
import { FormControl } from 'react-bootstrap';
import {
  addItemType,
  base64guid,
  decorateItemWithProperties,
  isStepSignal,
  isStringSeries,
  validateGuid,
} from '@/utilities/utilities';
import { errorToast } from '@/utilities/toast.utilities';
import { DETAILS_PANE_ITEM_TYPES } from '@/trendData/trendData.constants';
import { sqWorkbookStore } from '@/core/core.stores';
import { decorate } from '@/trend/trendViewer/itemDecorator.utilities';
import { FakeLink } from '@/core/FakeLink';

interface SelectItemIF {
  // true if the select can be toggled between a text input field and a select.
  textEntryAllowed?: boolean;
  textPlaceholder?: string;
  textToggleDisabled?: boolean;
  selectPlaceholder?: string;
  excludeStoreItems?: boolean;
  excludeWorkbookItems?: boolean;
  // true does not automatically select even if there's only one item
  disableAutoSelect?: boolean;
  showAddToDisplayPane?: boolean;
  includeMetadata?: boolean;
  excludeStringSignals?: boolean;
  includeOnlyStringSignals?: boolean;
  includeOnlyStepSignals?: boolean;
  isMultipleSelect?: boolean;
  selectedItemId: string | string[];
  excludedIds?: string[];
  additionalItems?: object[];
  // to limit items to only certain item types
  itemTypes?: string[];
  // index used to set the threshold in scorecard
  thresholdLevel?: string;
  // used by formula, the name
  identifier?: string;
  // used by formula, index
  paramIndex?: string;
  items?: object[];
  allowClear?: boolean;
  extraClassNames?: any;
  // for Asset Groups we need to compare the items to the group as well as the item ids will be the same for asset
  // column items
  selectByIdAndGroup?: boolean;
  // Determines if the select element is allowed to wrap when the name is long
  noWrap?: boolean;
  onSelect: (selectedItemOrValue: object | string, level?: number | string) => void;
  onRemove?: (selectedItem: object) => void;
  // called when input is toggled from text input to signal select or the other way around. used for formBuilder
  onToggle?: () => void;
  isGrouped?: boolean;
  dataTestId?: string;
  addAllEnabled?: boolean;
  removeAllEnabled?: boolean;
  stopPropagation?: boolean;
  testId?: string;
  showError?: boolean;
}

/** Select dropdown for searching for and selecting Seeq Items */
export const SelectItemUnwrapped: React.FunctionComponent<SelectItemIF> = (props) => {
  const { t } = useTranslation();

  const {
    textEntryAllowed,
    textPlaceholder,
    textToggleDisabled,
    selectPlaceholder,
    disableAutoSelect,
    showAddToDisplayPane = true,
    selectedItemId = undefined,
    onRemove,
    onSelect,
    isMultipleSelect,
    excludeStoreItems,
    excludeWorkbookItems,
    additionalItems,
    excludeStringSignals,
    includeOnlyStringSignals = false,
    includeOnlyStepSignals = false,
    itemTypes,
    includeMetadata,
    identifier,
    thresholdLevel,
    paramIndex,
    allowClear,
    onToggle,
    extraClassNames,
    noWrap = false,
    selectByIdAndGroup = false,
    isGrouped = true,
    dataTestId = '',
    addAllEnabled = false,
    removeAllEnabled = false,
    stopPropagation = false,
    testId = '',
    showError = false,
  } = props;
  const isMountedRef = React.useRef(null);

  useEffect(() => {
    if (!_.isArray(selectedItemId)) {
      const validItemSelected = validateGuid(selectedItemId);
      if (textEntryAllowed) {
        setDisplayTextEntry(!validItemSelected);
        if (!validItemSelected) {
          setTextInputText(selectedItemId ?? '');
        }
      }
    }
  }, [selectedItemId]);

  const [loadingMetadata, setLoadingMetadata] = useState(false);
  const [displayTextEntry, setDisplayTextEntry] = useState(false);
  const [displayItemsStepSignals, setDisplayItemsStepSignals] = useState([]);
  const [previousValue, setPreviousValue] = useState<string | string[]>('');

  // This is a bit of a hack to work around the fact that text input is too slow when running system tests.
  // To prevent errors, we store values from ongoing typing in the state and only update the store when the onBlur
  // is triggered. Once all forms use FormBuilder the Form can be used to store the state and we won't need to keep
  // values in local state.
  const textInputValue = validateGuid(selectedItemId) || _.isUndefined(selectedItemId) ? '' : selectedItemId;
  const [textInputText, setTextInputText] = useState(textInputValue);
  const cancellationGroup = `selectItemMetadata_${base64guid()}`;

  if (textEntryAllowed && isMultipleSelect) {
    throw new Error('Item Select can not be isMultipleSelect and textEntryAllowed');
  }

  const displayAddToDisplayPane =
    showAddToDisplayPane && !textEntryAllowed && !_.isEmpty(selectedItemId) && !isMultipleSelect;

  const trendItems = _.concat(getAllItems({}), additionalItems || []);

  const excludedIds = _.chain(trendItems)
    .flatMap((items) => _.chain(items).filter('swapSourceId').map('swapSourceId').value())
    .concat(props.excludedIds as any)
    .compact()
    .value();

  const displayItems = excludeStoreItems
    ? additionalItems
    : _.chain(trendItems)
        .concat(
          excludeWorkbookItems
            ? []
            : _.map(sqWorkbookStore.pinned, (item) => ({
                ...item,
                selectGroup: 'pinned',
              })),
        )
        .concat(
          excludeWorkbookItems
            ? []
            : _.map(sqWorkbookStore.recentlyAccessed, (item) => ({
                ...item,
                selectGroup: 'recentlyAccessed',
              })),
        )
        .map((item) => addItemType(item))
        .map((item) => (_.includes(DETAILS_PANE_ITEM_TYPES, item.itemType) ? decorate({ items: item }) : item))
        .reject((item) => (excludeStringSignals ? isStringSeries(item) : false))
        .reject((item) => (includeOnlyStringSignals ? !isStringSeries(item) : false))
        .reject((item) => _.includes(excludedIds, item.id))
        .filter((item) => (_.isEmpty(itemTypes) ? true : _.includes(_.castArray(itemTypes), item.itemType)))
        .uniqBy('id')
        .value();

  useEffect(() => {
    if (includeOnlyStepSignals) {
      const itemsWithoutInterpolation = _.reject(displayItems, (item) => item?.interpolationMethod);
      _.chain(itemsWithoutInterpolation)
        .map((item) => isStepSignal(item?.id))
        .thru((results) => Promise.all(results))
        .value()
        .then((responses) => {
          const stepSignals = [];
          _.forEach(displayItems, (item, index) => {
            if (item?.interpolationMethod?.value === 'Step' || item?.interpolationMethod === 'Step') {
              stepSignals.push(displayItems[index]);
            }
          });
          _.forEach(responses, (response, index) => {
            if (response) {
              stepSignals.push(itemsWithoutInterpolation[index]);
            }
          });
          setDisplayItemsStepSignals(stepSignals);
        });
    }
  }, []);

  // This is necessary to ensure the dropdown options render in the correct width
  const charCount: number = _.chain(displayItems)
    .map((item) => item?.name?.length)
    .max()
    .value();

  const dropdownWidth = `${8 * charCount + 30}px`;

  const toggleTextEntryDisplay = () => {
    if (_.isFunction(onToggle)) {
      onToggle();
    }
    const previouslyEntered = previousValue;
    const toggleToDisplayText = !displayTextEntry;

    setPreviousValue(selectedItemId);

    const item = toggleToDisplayText ? previouslyEntered : _.find(displayItems, { id: previouslyEntered });

    onItemSelect(item);

    setDisplayTextEntry(toggleToDisplayText);
  };

  const onItemSelect = (item) => {
    if (!_.isFunction(onSelect)) {
      return;
    }

    if (_.isNil(item) && allowClear) {
      onSelect(null);
    }

    // Retrieving metadata is not supported if multiple selection is enabled or if item is a text value or
    // undefined
    if (!_.isObject(item) || !includeMetadata || isMultipleSelect) {
      if (isMultipleSelect) {
        const test = _.reject(item, (obj) => !_.has(obj, 'name'));
        onSelect(_.first(test));
      } else if (textEntryAllowed) {
        if (!_.isNil(item)) {
          onSelect(item, thresholdLevel);
        }
      } else if (identifier) {
        // formula
        onSelect({ identifier, item });
      } else {
        onSelect(item);
      }
    } else {
      isMountedRef.current && setLoadingMetadata(true);

      sqItemsApi
        .getItemAndAllProperties({ id: item.id }, { cancellationGroup })
        .then(({ data: fetchedItem }) => {
          const decoratedItem = fetchedItem ? decorateItemWithProperties(fetchedItem) : item;
          textEntryAllowed ? onSelect(decoratedItem, thresholdLevel) : onSelect(decoratedItem);
        })
        .catch((e) => {
          errorToast({ httpResponseOrError: e });
        })
        .finally(() => {
          isMountedRef.current && setLoadingMetadata(false);
        });
    }
  };

  const setThreshold = (event) => setTextInputText(event.target.value);
  const updateThresholdInStore = (event) => onSelect(event.target.value, thresholdLevel);

  const renderTextInput = (
    <FormControl
      type="text"
      value={textInputText}
      onChange={setThreshold}
      onBlur={updateThresholdInStore}
      className={classNames(
        'selectItemTextInput',
        'textEntryInput',
        'transitionNone',
        'flexFill',
        'pl10',
        'btlr4' + 'bblr4' + 'borderStyleSolid',
        'borderColorGray',
        'height-34',
        extraClassNames,
      )}
      size="sm"
      placeholder={t(textPlaceholder ? textPlaceholder : 'ENTER_VALUE')}
    />
  );

  const selectedItem = isMultipleSelect
    ? _.difference(selectedItemId, excludedIds)
    : _.find(displayItems, { id: selectedItemId });

  const [fetchedSelectedItem, setFetchedSelectedItem] = useState(undefined);

  useEffect(() => {
    if (selectedItemId && validateGuid(selectedItemId) && _.isUndefined(selectedItem)) {
      sqItemsApi
        .getItemAndAllProperties({ id: selectedItemId as string })
        .then(({ data }) => {
          setFetchedSelectedItem(decorate({ items: addItemType(data) }));
        })
        // The ID is malformed, or is otherwise a string, so ignore errors
        .catch(_.noop);
    }
  }, []);

  const renderSelect = (
    <SelectItemWrapper
      selectPlaceholder={selectPlaceholder}
      selected={selectedItem ?? fetchedSelectedItem}
      isMultipleSelect={isMultipleSelect}
      items={_.concat(
        includeOnlyStepSignals ? displayItemsStepSignals : displayItems,
        fetchedSelectedItem ? [fetchedSelectedItem] : [],
      )}
      onChange={onItemSelect}
      onRemove={onRemove}
      loadingMetadata={loadingMetadata}
      allowClear={allowClear}
      disableAutoSelect={disableAutoSelect}
      noWrap={noWrap || textEntryAllowed}
      selectByIdAndGroup={selectByIdAndGroup}
      isGrouped={isGrouped}
      addAllEnabled={addAllEnabled}
      stopPropagation={stopPropagation}
      testId={`${testId}_select`}
      showError={showError}
    />
  );

  const textInputTooltip = textToggleDisabled ? 'VALUE_SEARCH.STRING_SIGNAL_TOOLTIP' : 'USE_ITEM_SELECTOR';
  const renderTextInputToggle = (
    <HoverTooltip text={displayTextEntry ? textInputTooltip : 'USE_VALUE_ENTRY'}>
      <span className="d-inline-block input-group width-35">
        <Button
          label=""
          icon={displayTextEntry ? 'fc-scalar' : 'fc-all-items'}
          testId="item-select-toggle"
          onClick={toggleTextEntryDisplay}
          extraClassNames={classNames('flexRowContainer flexCenter pl4 pr4 width-35', {
            disabledBehavior: textToggleDisabled,
          })}
          disabled={textToggleDisabled}
        />
      </span>
    </HoverTooltip>
  );

  const removeAllLink = (
    <div className="pt5 pb5 flexColumnContainer flexAlignBottom flexFill flexJustifyEnd">
      <FakeLink
        testId="removeAllLink"
        onClick={() => {
          _.compact(displayItems).forEach(onRemove);
        }}>
        {t('DETAILS_SELECTION.REMOVE_ALL')}
      </FakeLink>
    </div>
  );

  return (
    <div>
      <div
        className={classNames(
          'flexFillOverflow flexColumnContainer',
          textEntryAllowed ? 'textEntryAllowed' : 'flexAlignCenter',
        )}
        data-testid={`itemSelect${dataTestId}`}>
        {textEntryAllowed && displayTextEntry && renderTextInput}
        {!displayTextEntry && renderSelect}
        {textEntryAllowed && renderTextInputToggle}
        {displayAddToDisplayPane && <AddToDisplayPane itemId={selectedItemId as string} tooltipPlacement="left" />}
      </div>
      {removeAllEnabled && removeAllLink}
    </div>
  );
};

export const SelectItem = React.memo(
  SelectItemUnwrapped,
  (prev, next) =>
    !(
      prev.selectedItemId !== next.selectedItemId ||
      next.textPlaceholder !== prev.textPlaceholder ||
      prev.extraClassNames !== next.extraClassNames ||
      prev.additionalItems !== next.additionalItems ||
      prev.excludedIds !== next.excludedIds
    ),
);
export default SelectItem;
