import { DURATION_TIME_UNITS } from '@/main/app.constants';
import { AutoUpdateTimeScheduleEntry, LiveUpdateRate } from './schedule.types';

export const DEFAULT_LIVE_RATE: LiveUpdateRate = {
  unit: DURATION_TIME_UNITS.find(({ translationKey }) => translationKey === 'DURATION_UNITS.HOURS')!,
  value: 1,
};

export const DEFAULT_TIME_ENTRY: AutoUpdateTimeScheduleEntry = {
  time: '08:00',
};

export const MIN_DAY = 1;
export const MAX_DAY = 31;
export const MIN_MONTH = 1;
export const MAX_MONTH = 12;
