import React from 'react';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { Field, useForm } from 'react-final-form';
import { getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { CapsuleGroup } from '@/tools/manualCondition/CapsuleGroup';
import _ from 'lodash';

import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import { CapsuleGroupCapsule } from '@/tools/manualCondition/manualCondition.constants';

export interface CapsuleGroupIF extends ValidatingFormComponent<CapsuleGroupCapsule[]> {
  component: 'CapsuleGroupFormComponent';
  previewId: string;
}

export const CapsuleGroupFormComponent: React.FunctionComponent<CapsuleGroupIF> = (props) => {
  const {
    name,
    testId = 'capsuleGroup',
    label,
    validation,
    extendValidation,
    customErrorText,
    previewId,
    value,
  } = props;

  const defaultValidation = () => !value || !_.isArray(value) || value.length === 0;

  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);

  const formState = useForm().getState();
  const showError = _.has(formState.errors, name) && formState.touched?.[name];

  return (
    <FormFieldWrapper testId={testId} label={label} showError={showError} customErrorText={customErrorText}>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          return <CapsuleGroup previewId={previewId} />;
        }}
      </Field>
    </FormFieldWrapper>
  );
};
