import React from 'react';
import { ButtonWithPopover, Icon } from '@seeqdev/qomponents';

export interface IconWithPopoverTooltipProps {
  title?: string | React.ReactNode;
  content: React.ReactNode;
  testId?: string;
  placement?: 'bottom' | 'top' | 'left' | 'right';
  isHoverEnabled?: boolean;
}

/**
 * Icon with a customizable/flexible popover
 */
export const IconWithPopoverTooltip: React.FunctionComponent<IconWithPopoverTooltipProps> = ({
  content,
  title,
  testId = 'popover',
  placement = 'bottom',
  isHoverEnabled = false,
}) => {
  return (
    <ButtonWithPopover
      placement={placement}
      isHoverEnabled={isHoverEnabled}
      hasArrow
      trigger={<Icon icon="fa-question-circle" testId={`${testId}Icon`} large={true} extraClassNames="pt3" />}>
      <div id={testId} data-testid={testId} className="text-opaque">
        {title && <div className="popover-header">{title}</div>}
        <div>{content}</div>
      </div>
    </ButtonWithPopover>
  );
};
