import React, { useEffect, useState } from 'react';

const modalCallbacks: Map<React.FC<any>, (modalValue: any, modalProps: any) => void> = new Map();

export function setShowModal<T = unknown>(modal: React.FC<T>, showModal: any, modalProps?: any) {
  const callback = modalCallbacks.get(modal);
  if (!callback) {
    throw new Error(`No component is managing modal ${modal.name}`);
  }
  callback(showModal, modalProps);
}

type ShowModal = any;
type SetShowModal = (showModal: any, modalProps?: any) => void;
type ModalProps = any;

type ModalValues = [ShowModal, SetShowModal, ModalProps];

/**
 * This hook declares that a given component manages the Modal that is passed in, declaring itself to be the only
 * active parent of the given modal. This is used with the setShowModal up above, which passes the above modal value
 * (true, false, or any other value used by the parent component to control the rendering of the modal) and an
 * optional set of modal props to update the display of the modal from anywhere.
 *
 * If a second component tries to manage the modal, an error will be thrown.
 *
 * Example:
 * Modal:
 * <div>
 *   I am a modal!
 * </div>
 *
 * Parent:
 *
 * const { showModal } = useModalManager(Modal); // This is the actual Modal object from above
 * <div>
 *   {showModal && <Modal/>}
 * </div>
 *
 * Some other component:
 * useEffect(() => {
 *   setShowModal(
 *    Modal // Same Modal from above,
 *    true,
 *    { large: true }
 *    )
 * }, [field])
 *
 * @param modalComponent
 */
export function useModalManager<T>(modalComponent: React.FC<T>): ModalValues {
  const setterCallback = (show: ShowModal, props?: ModalProps) => setShowModal(modalComponent, show, props);
  const [modalValues, setModalValues] = useState<ModalValues>([undefined, setterCallback, {}]);

  useEffect(() => {
    const callback = (showModal: any, modalProps: any = {}) => {
      setModalValues([showModal, setterCallback, modalProps]);
    };
    const current = modalCallbacks.get(modalComponent);
    if (current) {
      throw new Error(`Tried to register multiple parents for modal ${modalComponent.name}. Please register only one.`);
    }
    modalCallbacks.set(modalComponent, callback);
    return () => {
      modalCallbacks.delete(modalComponent);
    };
  }, []);

  return modalValues;
}
