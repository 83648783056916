// @ts-strict-ignore
import _ from 'lodash';
import { createSelector, createStructuredSelector } from 'reselect';
import { SeriesHeatmapOptions } from 'highcharts';
import { DENSITY_PLOT_ID } from '@/scatterPlot/scatterPlot.constants';
import { getParenthesizedUnitString, ItemWithUnits, resolveUnitOfMeasure } from '@/utilities/unit.utilities';

// White out the cells with no significant data
export const TIME_COLOR_STOPS_WITH_ZERO: [number, string][] = [
  [0, '#ffffff'],
  [0.00001, '#3a8af2'],
  [0.3, '#fff700'],
  [0.7, '#e87217'],
  [1, '#f51c07'],
];

// Sometimes, all cells will have some data, so we don't need to white out
// the cells with the shortest duration
const TIME_COLOR_STOPS_WITHOUT_ZERO: [number, string][] = [
  [0, '#3a8af2'],
  [0.3, '#fff700'],
  [0.7, '#e87217'],
  [1, '#f51c07'],
];

// Make the bin size for display slightly larger to avoid empty pixels in the plot. This occasionally has the side
// effect of density plot cells changing size when the user mouses over them, but makes the plot look much better in
// organizer screenshots.
export const BIN_SIZE_MULTIPLIER = 1.001;

const makeTrendSignalSelector = () =>
  createStructuredSelector({
    id: (item) => _.get(item, 'id', ''),
    name: (item) => _.get(item, 'name', ''),
    color: (item) => _.get(item, 'color', 'transparent'),
    valueUnitOfMeasure: (item) => {
      if (item) {
        return resolveUnitOfMeasure(<ItemWithUnits>item).value;
      } else {
        return undefined;
      }
    },
    format: (item) => _.get(item, 'formatOptions.format'),
  });

const densityPlotSelector = createStructuredSelector({
  xBinSize: (state: any) => state.xBinSize,
  yBinSize: (state: any) => state.yBinSize,
  showTooltips: (state: any) => state.showTooltips,
});

const densityPlotDataSelector = createStructuredSelector({
  densityPlotData: (densityPlotData: any) => densityPlotData,
});

const colorAxisRangeSelector = createStructuredSelector({
  start: (colorAxisRange: any) => colorAxisRange.start,
  end: (colorAxisRange: any) => colorAxisRange.end,
  mid: (colorAxisRange: any) => (colorAxisRange.start + colorAxisRange.end) / 2,
});
const getNameForAxis = (item) => [item.name, getParenthesizedUnitString(item)].join('');
const getColorForAxis = (item) => (item.color ? item.color : 'transparent');

export interface SeeqDensityPlotPoint extends Highcharts.Point {
  value: any;
  series: Highcharts.Series & { userOptions: SeriesHeatmapOptions };
}

/**
 * A selector that takes in the heat map state and the X and Y signals and produces Highcharts config object
 * that it can use to re-render.
 *
 * @param {Object} densityPlotState - The state from the sqScatterPlotStore
 * @param {Object} xSignalState - The state from trendSignalSelector(xSignal)
 * @param {Object} ySignalState - The state from trendSignalSelector(ySignal)
 * @return {Function => Object} Highcharts config for the axes, the scatter plot series, and regression lines.
 */
export const getDensityPlotChartConfig: any = createSelector(
  createSelector(({ state }) => state, densityPlotSelector),
  createSelector(({ densityPlotData }) => densityPlotData, densityPlotDataSelector),
  createSelector(({ xSignal }) => xSignal, makeTrendSignalSelector()),
  createSelector(({ ySignal }) => ySignal, makeTrendSignalSelector()),
  createSelector(({ colorAxisRange }) => colorAxisRange, colorAxisRangeSelector),
  (densityPlot, densityPlotData, xSignal, ySignal, colorAxisRange): Highcharts.Options => ({
    xAxis: {
      id: xSignal.id,
      signalId: xSignal.id,
      title: {
        text: getNameForAxis(xSignal),
        style: { color: getColorForAxis(xSignal) },
      },
    },
    yAxis: {
      id: ySignal.id,
      signalId: ySignal.id,
      title: {
        text: getNameForAxis(ySignal),
        style: { color: getColorForAxis(ySignal) },
      },
    },
    tooltip: {
      enabled: densityPlot.showTooltips,
    },
    colorAxis: {
      stops: colorAxisRange.start > 0 ? TIME_COLOR_STOPS_WITHOUT_ZERO : TIME_COLOR_STOPS_WITH_ZERO,
      tickPositions: [colorAxisRange.start, colorAxisRange.mid, colorAxisRange.end],
    },
    series: [
      {
        type: 'heatmap',
        id: DENSITY_PLOT_ID,
        borderWidth: 0,
        data: densityPlotData.densityPlotData,
        xNumberFormat: xSignal.format,
        yNumberFormat: ySignal.format,
        rowsize: densityPlot.yBinSize * BIN_SIZE_MULTIPLIER,
        colsize: densityPlot.xBinSize * BIN_SIZE_MULTIPLIER,
        xSignalName: xSignal.name,
        ySignalName: ySignal.name,
      },
    ],
  }),
);
