import React, { useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Icon } from '@seeqdev/qomponents';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { IconWithSpinner } from '@/core/IconWithSpinner.atom';
import { onEnterKeypress, onEventPreventPropagation } from '@/core/onEnterKeypress.util';
import { swapAsset as sqSearchUtilitiesSwapAsset } from '@/search/searchResult.utilities.service';
import { pinToWorkbook, unpinToWorkbook } from '@/workbook/workbook.actions';
import { isAsset, isDatafile as isDatafileUtil, isDisplay as isDisplayUtil } from '@/utilities/utilities';
import { errorToast } from '@/utilities/toast.utilities';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.constants';
import { DisplayDropdown } from '@/displays/DisplayDropdown.molecule';
import { sqSearchStore, sqWorkbookStore } from '@/core/core.stores';
import { doTrack } from '@/track/track.service';
import { fetchItemUsages } from '@/search/search.actions';
import { setActiveTool, setNonStoreItem } from '@/toolSelection/investigate.actions';
import { loadDatafile } from '@/tools/importDatafile/importDatafile.actions';
import { SearchModes } from '@/search/search.constants';
import { AnyProperty } from '@/utilities.types';

export interface SearchResultIconsProps {
  item: any;
  pinned: any[];
  workbookDisplay: WORKBOOK_DISPLAY | undefined;
  workbookId: string;
  onItemClick: (item: AnyProperty, mode: SearchModes) => any;
  allowAssetReplacement: boolean;
  restrictExploration?: boolean;
}

export const SearchResultIcons: React.FunctionComponent<SearchResultIconsProps> = (props) => {
  const {
    item,
    pinned,
    workbookDisplay,
    workbookId,
    onItemClick,
    allowAssetReplacement,
    restrictExploration = false,
  } = props;

  const [isSwapping, setIsSwapping] = useState(false);
  const [isPinning, setIsPinning] = useState(false);

  const { t } = useTranslation();
  const isDatafile = isDatafileUtil(item);
  const isDisplay = isDisplayUtil(item);
  const showInfoButton = isAsset(item) || isDatafile || item?.hasChildren;
  const showTrendIcon = !isAsset(item) && !isDatafile && !isDisplay && item?.hasChildren;
  const showSwapIcon = isAsset(item) || item?.hasChildren;
  const isLoadingUsages = sqSearchStore.searching === true;
  const isSearchingUsages = sqSearchStore.mode === 'usages';
  const isPinned = _.chain(pinned).map('id').includes(item.id).value();
  const isEdit = workbookDisplay === WORKBOOK_DISPLAY.EDIT;
  const displayParentProperties = () => {
    setActiveTool(TREND_TOOLS.PROPERTIES);
    setNonStoreItem(item);
  };

  const swapAsset = () => {
    doTrack('Search results', 'Swap Assets');
    setIsSwapping(true);

    sqSearchUtilitiesSwapAsset(item)
      .catch((error) => errorToast({ httpResponseOrError: error, displayForbidden: true }))
      .finally(() => setIsSwapping(false));
  };

  const togglePin = () => {
    doTrack('Search results', 'pin to Workbook');
    const pinFunction = isPinned ? unpinToWorkbook : pinToWorkbook;
    setIsPinning(true);
    return pinFunction(workbookId, item.id).finally(() => setIsPinning(false));
  };

  const findUsages = () => {
    doTrack('Analysis', 'Usages', 'from search');
    return fetchItemUsages(item, 'main', sqWorkbookStore.workbookId ? [sqWorkbookStore.workbookId] : [], false);
  };

  return (
    <>
      {showInfoButton && (
        <span onClick={onEventPreventPropagation(displayParentProperties)}>
          <Icon
            icon="fa-info-circle"
            tooltip={isDatafile ? t('PROPERTIES.DATAFILE') : t('PROPERTIES.ASSET')}
            extraClassNames="searchBtn"
            testId="infoIcon"
          />
        </span>
      )}

      {showTrendIcon && (
        <HoverTooltip text="SEARCH_DATA.TREND_ITEM" placement="top">
          <span
            onClick={onEventPreventPropagation(() => isSwapping || onItemClick(item, sqSearchStore.mode))}
            onKeyUp={onEnterKeypress(() => isSwapping || onItemClick(item, sqSearchStore.mode))}>
            <IconWithSpinner
              icon="fa-chart-area"
              extraClassNames="searchBtn"
              spinning={isSwapping}
              testId="trendIcon"
              correctSpin={false}
            />
          </span>
        </HoverTooltip>
      )}

      {isDatafile && (
        <span
          onClick={onEventPreventPropagation(() => loadDatafile(item.id))}
          onKeyUp={onEnterKeypress(() => loadDatafile(item.id))}>
          <Icon
            icon="fa-pencil"
            tooltip={t('SEARCH_DATA.EDIT_CSV')}
            extraClassNames="searchBtn"
            testId="editDatafileIcon"
          />
        </span>
      )}

      {isDisplay && (
        <DisplayDropdown
          item={item}
          isPinning={isPinning}
          isPinned={isPinned}
          togglePin={togglePin}
          restrictExploration={restrictExploration}
        />
      )}

      {isSearchingUsages && (
        <HoverTooltip text="SEARCH_DATA.USAGES">
          <span onClick={onEventPreventPropagation(() => findUsages())} onKeyUp={onEnterKeypress(() => findUsages())}>
            <IconWithSpinner
              icon="fc-usage"
              extraClassNames="searchBtn"
              testId="usagesIcon"
              spinning={isLoadingUsages}
              correctSpin={false}
            />
          </span>
        </HoverTooltip>
      )}

      {allowAssetReplacement && showSwapIcon && (
        <HoverTooltip text="ASSET_REPLACE.TOOLTIP" placement="bottom">
          <span
            onClick={onEventPreventPropagation(() => !isSwapping && swapAsset())}
            onKeyUp={onEnterKeypress(() => !isSwapping && swapAsset())}>
            <IconWithSpinner
              icon="fa-arrow-right-arrow-left"
              extraClassNames="searchBtn"
              spinning={isSwapping}
              testId="assetSwapIcon"
              correctSpin={false}
            />
          </span>
        </HoverTooltip>
      )}

      {isEdit && !isSearchingUsages && !isDisplay && (
        <HoverTooltip text={isPinned ? 'SEARCH_DATA.UNPIN' : 'SEARCH_DATA.PIN'}>
          <span
            onClick={onEventPreventPropagation(() => !isPinning && togglePin())}
            onKeyUp={onEnterKeypress(() => !isPinning && togglePin())}>
            <IconWithSpinner
              icon={isPinned ? 'fc-pin' : 'fc-pin-o'}
              extraClassNames="searchBtn"
              testId="pinIcon"
              spinning={isPinning}
              correctSpin={false}
            />
          </span>
        </HoverTooltip>
      )}
    </>
  );
};
