import React from 'react';
import { LegendWrapper } from '@/trend/LegendWrapper.atom';
import { ScatterPlotLegendColorConditionItems } from '@/scatterPlot/ScatterPlotLegendColorConditionItems.molecule';
import { ScatterPlotLegendFxLinesItems } from '@/scatterPlot/ScatterPlotLegendFxLines.molecule';
import { ScatterPlotLegendGradientItems } from '@/scatterPlot/ScatterPlotLegendGradientItems.molecule';
import { ScatterPlotLegendColorRainbowCapsuleProperty } from '@/scatterPlot/ScatterPlotLegendColorRainbowCapsuleProperty.molecule';
import { Timezone } from '@/datetime/timezone.service';
import {
  NumericCapsulePropertyColorsConfig,
  StringCapsulePropertyColorsConfig,
} from '@/scatterPlot/scatterPlot.constants';

export interface ScatterPlotLegendProps {
  colorConditions: any[];
  colorSignalName: string;
  gradientConfig: any;
  fxLines: any[];
  colorRanges: any[];
  colorCapsuleProperty: string;
  capsulePropertyColorsConfig: StringCapsulePropertyColorsConfig | NumericCapsulePropertyColorsConfig | undefined;
  timezone: Timezone;
}

/**
 * Used in the ScatterPlot component to render the legend for color conditions, color ranges,
 * f(x) lines, and colorSignals.
 */
export const ScatterPlotLegend: React.FunctionComponent<ScatterPlotLegendProps> = ({
  colorConditions = [],
  colorSignalName,
  gradientConfig,
  fxLines = [],
  colorRanges = [],
  colorCapsuleProperty,
  capsulePropertyColorsConfig,
  timezone,
}) => {
  const isDisplayed = !!(
    fxLines.length ||
    colorSignalName ||
    colorRanges.length ||
    colorConditions.length ||
    (colorCapsuleProperty && capsulePropertyColorsConfig)
  );

  return (
    <>
      {isDisplayed && (
        <LegendWrapper>
          <div className="scatterPlotLegend overflowYAuto">
            <ScatterPlotLegendFxLinesItems fxLines={fxLines} />
            <ScatterPlotLegendGradientItems
              displayName={colorCapsuleProperty}
              colorsConfig={capsulePropertyColorsConfig as NumericCapsulePropertyColorsConfig}
            />
            <ScatterPlotLegendColorRainbowCapsuleProperty
              propertyName={colorCapsuleProperty}
              timezone={timezone}
              colorsConfig={capsulePropertyColorsConfig as StringCapsulePropertyColorsConfig}
            />
            <ScatterPlotLegendColorConditionItems
              colorRanges={colorRanges}
              colorConditions={colorConditions}
              timezone={timezone}
            />
            <ScatterPlotLegendGradientItems displayName={colorSignalName} colorsConfig={gradientConfig} />
          </div>
        </LegendWrapper>
      )}
    </>
  );
};
export default ScatterPlotLegend;
