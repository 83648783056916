import SelectUnit from '@/core/SelectUnit.molecule';
import { CustomCellEditorProps } from '@ag-grid-community/react';
import { TextField } from '@seeqdev/qomponents';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export interface NumericWithUomEditorProps extends CustomCellEditorProps {
  columnUom: string;
}

export const NumericWithUomEditor = (props: NumericWithUomEditorProps) => {
  const { t } = useTranslation();
  let numericValue = props.value.value;
  let uomValue = props.value.uom;

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    inputRef?.current?.focus();
    inputRef?.current?.select();
  }, []);

  function setValue(value: number) {
    numericValue = value;
    props.onValueChange({ value: numericValue, uom: uomValue });
  }

  function setUom(unit: string): void {
    uomValue = unit;
    props.onValueChange({ value: numericValue, uom: uomValue });
  }

  return (
    <div>
      <TextField
        ref={inputRef}
        extraClassNames="width-maximum"
        testId="numericValueOverrideInput"
        value={numericValue}
        onChange={({ target: { value } }) => setValue(parseFloat(value))}
        type="number"
      />
      {!props.columnUom && <SelectUnit placeholder="uom" value={uomValue} onChange={setUom} />}
    </div>
  );
};

export const UUIDEditor = (props: CustomCellEditorProps) => {
  const { t } = useTranslation();
  let uuidValue = props.value.uuid;

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    inputRef?.current?.focus();
    inputRef?.current?.select();
  }, []);

  function setValue(value: string) {
    uuidValue = value;
    props.onValueChange({ uuid: uuidValue });
  }

  return (
    <div>
      <TextField
        extraClassNames="width-maximum"
        testId="uuidValueOverrideInput"
        value={uuidValue}
        onChange={({ target: { value } }) => setValue(value)}
        type="text"
      />
    </div>
  );
};

export const TextEditor = (props: CustomCellEditorProps) => {
  const { t } = useTranslation();
  let textValue = props.value.value;

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    inputRef?.current?.focus();
    inputRef?.current?.select();
  }, []);

  function setValue(value: string) {
    textValue = value;
    props.onValueChange({ value: textValue });
  }

  return (
    <div>
      <TextField
        ref={inputRef}
        extraClassNames="width-maximum"
        testId="textValueOverrideInput"
        value={textValue}
        onChange={({ target: { value } }) => setValue(value)}
        type="text"
      />
    </div>
  );
};
