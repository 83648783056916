// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { Icon } from '@seeqdev/qomponents';
import { LegendWrapper } from '@/trend/LegendWrapper.atom';

interface SignalGradientLegendItemsIF {
  getCapsuleName: (any) => string;
  capsules: any[];
  title: string;
}

export const SignalGradientLegendItems: React.FunctionComponent<SignalGradientLegendItemsIF> = (props) => {
  const { getCapsuleName, capsules, title } = props;

  return (
    capsules.length && (
      <LegendWrapper title={title}>
        {_.chain([_.first(capsules), _.last(capsules)])
          .compact()
          .uniqBy('id')
          .value()
          .map((capsule, i, collection) => (
            <div className="text-nowrap" key={capsule.id}>
              <Icon icon="fc-series" extraClassNames="pr5" type="color" color={capsule.childColor} />
              {getCapsuleName(capsule)}
              {i === 0 && collection.length > 1 && <span className="ml5 mr5">...</span>}
            </div>
          ))}
      </LegendWrapper>
    )
  );
};
