// @ts-strict-ignore
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Icon } from '@seeqdev/qomponents';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { canUseMemo } from '@/formbuilder/formbuilder.utilities';
import { FakeLink } from '@/core/FakeLink';

export interface ClickableLinkIF extends ValidatingFormComponent<string> {
  component: 'ClickableLinkFormComponent';
  linkTestId?: string;
  icon?: string;
  linkAction?: () => void;
}

const ClickableLinkFormComponentUnwrapped: React.FunctionComponent<ClickableLinkIF> = (props) => {
  const { value, linkAction, icon, extraClassNames = '', linkTestId = 'linkTestId' } = props;
  const { t } = useTranslation();

  return (
    <div className={classNames(extraClassNames)}>
      <FakeLink onClick={linkAction} testId={linkTestId}>
        {icon && <Icon icon={icon} extraClassNames="mr5" />}
        <span>{t(value)}</span>
      </FakeLink>
    </div>
  );
};
export const ClickableLinkFormComponent = React.memo(ClickableLinkFormComponentUnwrapped, canUseMemo);
