import _ from 'lodash';
import { emit as socketEmit, subscribe as socketSubscribe } from '@/utilities/socket.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';

export const UPDATE_NAMES = {
  THUMBNAIL: 'thumbnail',
  WORKBOOKS: 'workbooks',
  WORKBOOK: 'workbook',
  WORKSTEP: 'workstep',
  COMMENT: 'comment',
  REPORT: 'report',
  PERMISSIONS: 'permissions',
  SYSTEM_MESSAGE: 'systemMessage',
  ALL_SERVER_REQUESTS_CANCELED: 'allServerRequestsCanceled',
};

export let active = true;

type CallbackParam = {
  data: {
    name: string;
    interests?: any[];
    workbookId: string;
    worksheetId: string;
    itemId?: string;
    systemMessage?: string;
  };
};

export function addEventListeners(): void {
  if (!headlessRenderMode()) {
    _.forEach(['mousedown', 'keydown', 'wheel'], _.partial(window.addEventListener, _, activate, true));
  }
}

/**
 * Activates the notifier
 */
export function activate(): void {
  active = true;
}

/**
 * Deactivates the notifier
 */
export function deactivate(): void {
  active = false;
}

/**
 * Websocket messages are allowed to be emitted only while the state is active.
 *
 * @returns {boolean} - The active state
 */

/**
 * Emits a message indicating a workbook has been updated (i.e. added, removed, renamed, or restored)
 */
export function emitWorkbooks(): void {
  if (active) {
    emit({ name: UPDATE_NAMES.WORKBOOKS });
  }
}

/**
 * Registers a callback function that is called whenever the contents of a remote workbook is updated
 *
 * @param callback - A callback function (e.g. function() {})
 * @return The unsubscribe function for the callback
 */
export function onWorkbooks(callback: () => void): () => void {
  return subscribe(({ data }: CallbackParam) => {
    if (data.name === UPDATE_NAMES.WORKBOOKS) {
      deactivate();
      return callback();
    }
  });
}

/**
 * Emits a message indicating the contents of a workbook have been updated
 *
 * @param workbookId - A workbookId
 */
export function emitWorkbook(workbookId: string) {
  if (active) {
    emit({ name: UPDATE_NAMES.WORKBOOK, workbookId });
  }
}

/**
 * Registers a callback function that is called whenever the contents of a remote workbook is updated
 *
 * @param callback - A callback function (e.g. function(workbookId) {})
 * @return The unsubscribe function for the callback
 */
export function onWorkbook(callback: (workbookId: string) => void): () => void {
  return subscribe(({ data }: CallbackParam) => {
    if (data.name === UPDATE_NAMES.WORKBOOK) {
      deactivate();
      callback(data.workbookId);
    }
  });
}

/**
 * Emits a message indicating a comment has been updated (i.e. added, removed, or edited). `activate()` is not
 * checked since it is not part of the fast-follow flow.
 *
 * @param interests - ids of interest that should be updated. The listener will check if the one of the
 *  interests for the worksheet is one of the interests.
 */
export function emitComment(interests: string[]): void {
  emit({ name: UPDATE_NAMES.COMMENT, interests });
}

/**
 * Registers a callback function that is called whenever a comment is updated (i.e. added, removed, or edited).
 * `deactivate()` is not called since it is not part of the fast-follow flow.
 *
 * @param callback - A callback function (e.g. function(interests) {})
 * @return The unsubscribe function for the callback
 */
export function onComment(callback: (interests?: any[]) => void): () => void {
  return subscribe(({ data }: CallbackParam) => {
    if (data.name === UPDATE_NAMES.COMMENT) {
      callback(data.interests);
    }
  });
}

/**
 * Registers a callback function that is called whenever the server updates a screenshot thumbnail. This
 * event is emitted when a screenshot is captured, then bounced off appserver back to all other clients.
 *
 * @param callback - A callback function (e.g. function(workbookId, worksheetId) {})
 * @return The unsubscribe function for the callback
 */
export function onThumbnail(callback: (workbookId: string, worksheetId: string) => void) {
  return subscribe(({ data }: CallbackParam) => {
    if (data.name === UPDATE_NAMES.THUMBNAIL) {
      callback(data.workbookId, data.worksheetId);
    }
  });
}

/**
 * Emits a message indicating all server requests have been canceled by an admin user
 */
export function emitAllServerRequestsCanceled() {
  if (active) {
    emit({ name: UPDATE_NAMES.ALL_SERVER_REQUESTS_CANCELED });
  }
}

/**
 * Registers a callback function that is called whenever the all server requests have been canceled by an admin user
 *
 * @param callback - A callback function (e.g. function() {})
 * @return The unsubscribe function for the callback
 */
export function onAllServerRequestsCanceled(callback: () => void) {
  return subscribe(({ data }: CallbackParam) => {
    if (data.name === UPDATE_NAMES.ALL_SERVER_REQUESTS_CANCELED) {
      deactivate();
      callback();
    }
  });
}

/**
 * Emits a message indicating the permissions have been changed by the local client
 *
 * @param workbookId - The workbook ID
 * @param worksheetId - The worksheet ID
 * @param itemId - The ID of the item for which permissions are being changed
 */
export function emitPermissions(workbookId: string, worksheetId: string, itemId?: string) {
  if (active) {
    emit({ name: UPDATE_NAMES.PERMISSIONS, workbookId, worksheetId, itemId });
  }
}

/**
 * Registers a callback function that is called whenever a remote client changes permissions
 *
 * @param callback - A callback function (e.g. function(workbookId, worksheetId, itemId) {})
 * @return The unsubscribe function for the callback
 */
export function onPermissions(callback: (workbookId: string, worksheetId: string, itemId?: string) => void) {
  return subscribe(({ data }: CallbackParam) => {
    if (data.name === UPDATE_NAMES.PERMISSIONS) {
      deactivate();
      callback(data.workbookId, data.worksheetId, data.itemId);
    }
  });
}

/**
 * Registers a callback function that is called when the system message changes
 * The change is emitted on the backend via MessageCenter, which listens to the config option
 *
 * @param callback
 * @return The unsubscribe function for the callback
 */
export function onSystemMessage(callback: (systemMessage?: string) => void) {
  return subscribe(({ data }: CallbackParam) => {
    if (data.name === UPDATE_NAMES.SYSTEM_MESSAGE) {
      callback(data.systemMessage);
    }
  });
}

/**
 * Emits an event on the broadcast channel when not in screenshot render mode
 *
 * @param data - data to pass to sqSocket
 */
export function emit(data: any) {
  if (!headlessRenderMode()) {
    socketEmit([SeeqNames.Channels.Broadcast], data);
  }
}

/**
 * Subscribes to the broadcast channel if not in screenshot render mode. Note that if the subscription will
 * be invoking an async call as part of the callback it should probably use the sqUtilities.debounceAsync method
 * to ensure order-of-operation and avoid unnecessary fetches if the publish events come faster than the async calls.
 *
 * @param callback - function to be called when an event arrives from the broadcast channel
 * @return The unsubscribe function for the callback
 */
export function subscribe(callback: (arg: CallbackParam) => void): () => void {
  if (!headlessRenderMode()) {
    return socketSubscribe({
      channelId: [SeeqNames.Channels.Broadcast],
      onMessage: callback,
      useSubscriptionsApi: false, // Channel is auto-subscribed by the backend
    });
  } else {
    return () => {};
  }
}
