// @ts-strict-ignore
import _ from 'lodash';
import { findItemIn, getAllItems, getTrendStores } from '@/trend/trendDataHelper.utilities';
import { formatMessage } from '@/utilities/logger.utilities';
import { logError } from '@/utilities/logger';
import {
  isPresentationWorkbookMode,
  isUserCreatedType,
  itemIconClass,
  isInWorkbookRouteAndWorkbookLoaded,
} from '@/utilities/utilities';
import { flux } from '@/core/flux.module';
import { PUSH_IGNORE } from '@/core/flux.service';
import { getDependencies } from './formula.utilities';
import { DEBOUNCE } from '@/core/core.constants';
import { sqInvestigateStore, sqWorksheetStore } from '@/core/core.stores';

/**
 * Encapsulates logic for fetching, building, and dispatching an INVESTIGATE_SET_DERIVED_DATA_TREE message containing
 * the current state of the derived data tree.
 */

let items, relationships, usedAsChild;
let updateInProgress = false;
const TREE_ITEM_PROPS = ['id', 'name', 'type', 'iconClass', 'isArchived', 'color'];

export function update() {
  /**
   * Updates the derived data tree
   */
  function updateDataTree(forceUpdate) {
    const tabs = sqWorksheetStore.getTabset('sidebar');
    const activeTab = tabs?.tabs[tabs?.activeTabIndex];
    if (
      !forceUpdate &&
      (!isInWorkbookRouteAndWorkbookLoaded() ||
        isPresentationWorkbookMode() ||
        updateInProgress ||
        !sqInvestigateStore.derivedDataPanelOpen ||
        activeTab !== 'investigate')
    ) {
      return;
    }

    updateInProgress = true;
    items = {};
    usedAsChild = {};
    relationships = {};
    const detailsPaneItems = _.filter(getAllItems({}), 'calculationType');

    _.chain(detailsPaneItems)
      .map(createMaps)
      .thru((result) => Promise.all(result))
      .value()
      .then(() => buildFlatTree(detailsPaneItems))
      .then((tree) => {
        flux.dispatch('INVESTIGATE_SET_DERIVED_DATA_TREE', { derivedDataTree: tree }, PUSH_IGNORE);
      })
      .finally(() => {
        updateInProgress = false;
      });
  }

  /**
   * Builds two maps that will allow us later to easily construct the derived data tree:
   *
   *  1) An "items" map, which maps each item ID to an object containing the required item properties
   *
   *  2) A "relationships" map, which is a map containing a map. The first level map maps the ID of each
   *     calculation in the details pane to the another map. The other map maps the ID of each of the
   *     calculation's formula dependencies to an array containing all the IDs of calculated items that are
   *     parameters of the key item.
   */
  function createMaps(item) {
    return getDependencies(_.pick(item, 'id'))
      .then((result) => {
        pickItem(result);
        return Promise.all(
          _.chain(result.dependencies)
            .reject(['isGenerated', true])
            .map((dependency: any) => {
              pickItem(dependency);
              return Promise.all(
                _.map(dependency.parameterOf, (pOf: any) => {
                  pickItem(pOf);
                  addRelation(result.id, pOf.id, dependency.id);
                }),
              );
            })
            .value(),
        );
      })
      .catch((e) => {
        logError(formatMessage`Couldn't fetch dependencies for derived data panel: ${e}`);
      });
  }

  /**
   * Builds a flat derived data tree data structure that is suitable for display using a non-recursive template
   *
   * @param {Object[]} detailsPaneCalculations - Array of items
   */
  function buildFlatTree(detailsPaneCalculations) {
    return _.chain(detailsPaneCalculations)
      .sortBy('name')
      .map((item) => {
        let next;
        const flatTree = [];
        const root = _.pick(item, TREE_ITEM_PROPS) as any;
        root.depth = 0;
        const stack = [root];

        // Don't add a details pane item as a root node if it was used elsewhere as a child node
        if (usedAsChild[root.id]) {
          return;
        }

        // Do a sorted depth first traversal to build a flat tree using the relationship maps created above
        while (stack.length) {
          next = stack.pop();

          // Compute the array that is used to insert spacer elements to the left of the child node in the tree
          let node = next.parent;
          next.spacers = [];
          while (node) {
            next.spacers.unshift(_.pick(node, ['id', 'isLast']));
            node = node.parent;
          }

          // Assign a unique ID that can be used to track by in ng-repeat
          next.trackId = _.map(next.spacers, (spacer: any) => spacer.id)
            .concat([next.id])
            .join('|');

          flatTree.push(next);

          if (relationships[root.id]) {
            const children = getChildren(root, next);
            _.forEachRight(children, (child, index) => {
              child.parent = next;
              child.depth = next.depth + 1;
              child.isLast = index === children.length - 1;
              stack.push(child);
            });
          }
        }

        // Remove parent properties because they are only needed to compute the spacers array and they make
        // it much more expensive for baobab to serialize the flat tree in the investigate store.
        _.forEach(flatTree, (entry) => {
          delete entry.parent;
        });

        return flatTree;
      })
      .compact()
      .flatten()
      .value();
  }

  function pickItem(item) {
    if (items[item.id]) {
      return item;
    }

    item.iconClass = itemIconClass(item);
    items[item.id] = _.pick(item, TREE_ITEM_PROPS);
  }

  function addRelation(rootId, parentId, childId) {
    if (!relationships[rootId]) {
      relationships[rootId] = {};
    }

    if (!relationships[rootId][parentId]) {
      relationships[rootId][parentId] = [];
    }

    // Add relationship if item is not stored and not already in the list
    if (relationships[rootId][parentId].indexOf(childId) === -1 && isUserCreatedType(items[childId].type)) {
      relationships[rootId][parentId].push(childId);
    }

    usedAsChild[childId] = true;
  }

  function getChildren(root, node) {
    return _.chain(relationships[root.id][node.id])
      .map((childId) => {
        const child = _.cloneDeep(items[childId]);
        child.iconClass = itemIconClass(child);
        const detailsPaneItem = findItemIn(getTrendStores(), childId);
        if (detailsPaneItem) {
          child.color = detailsPaneItem.color;
        }
        return child;
      })
      .sortBy('name')
      .value();
  }

  return _.debounce(updateDataTree, DEBOUNCE.SHORT);
}
