import { unitOfTime } from 'moment-timezone';
import devIcon from 'resources/img/dev_favicon.ico';
import devSeeqGreenIcon from 'resources/img/dev_seeq_green.ico';
import devSeeqBlueIcon from 'resources/img/dev_seeq_blue.ico';
import devSeeqPurpleIcon from 'resources/img/dev_seeq_purple.ico';
import seeqIcon from 'resources/img/favicon.ico';
import seeqGreenIcon from 'resources/img/seeq_green.ico';
import seeqBlueIcon from 'resources/img/seeq_blue.ico';
import seeqPurpleIcon from 'resources/img/seeq_purple.ico';
import { SeeqNames } from './app.constants.seeqnames';

export interface DurationTimeUnit {
  unit: readonly string[];
  momentUnit?: unitOfTime.DurationConstructor;
  translationKey: string;
}

export interface UnitWithLabel {
  unit: readonly string[];
  shortLabel: string;
}

export const FAVICON =
  process.env.NODE_ENV === 'development'
    ? {
        GREEN: devSeeqGreenIcon,
        BLUE: devSeeqBlueIcon,
        PURPLE: devSeeqPurpleIcon,
        WORKBENCH: devIcon,
      }
    : {
        GREEN: seeqGreenIcon,
        BLUE: seeqBlueIcon,
        PURPLE: seeqPurpleIcon,
        WORKBENCH: seeqIcon,
      };

export const JOURNAL_PREFIX_PATH = '/links';

export const API_TYPES = {
  ASSET: SeeqNames.Types.Asset,
  CALCULATED_CONDITION: SeeqNames.Types.CalculatedCondition,
  CALCULATED_SIGNAL: SeeqNames.Types.CalculatedSignal,
  DATASOURCE: SeeqNames.Types.Datasource,
  DATAFILE: SeeqNames.Types.Datafile,
  CAPSULE: 'SimpleCapsule',
  CALCULATED_SCALAR: SeeqNames.Types.CalculatedScalar,
  STORED_CONDITION: SeeqNames.Types.StoredCondition,
  STORED_SIGNAL: SeeqNames.Types.StoredSignal,
  LITERAL_SCALAR: SeeqNames.Types.LiteralScalar,
  USER: SeeqNames.Types.User,
  ANALYSIS: SeeqNames.Types.Analysis,
  TOPIC: SeeqNames.Types.Topic,
  WORKSHEET: SeeqNames.Types.Worksheet,
  TABLE: SeeqNames.Types.Chart,
  JOURNAL: SeeqNames.Types.Journal,
  REPORT: SeeqNames.Types.Report,
  THRESHOLD_METRIC: SeeqNames.Types.ThresholdMetric,
  FORMULA_FUNCTION: SeeqNames.Types.AggregatingFormulaFunction,
  DISPLAY: SeeqNames.Types.Display,
  DISPLAY_TEMPLATE: SeeqNames.Types.DisplayTemplate,
  TABLE_DEFINITION: SeeqNames.Types.TableDefinition,
};
export const ASSET_PATH_SEPARATOR = ' » ';

export const ITEM_METADATA = {
  valueUnitOfMeasure: 'Value Unit Of Measure',
  interpolationMethod: 'Interpolation Method',
  keyUnitOfMeasure: 'Key Unit Of Measure',
  maxInterpolation: 'Maximum Interpolation',
  maxCapsuleDuration: 'Maximum Capsule Duration',
};
export const STRING_UOM = 'string';
export const SEARCH_TYPES = [
  SeeqNames.Types.Asset,
  SeeqNames.Types.Condition,
  SeeqNames.Types.Signal,
  'ScalarItem',
  SeeqNames.Types.Chart,
  SeeqNames.Types.ThresholdMetric,
  SeeqNames.Types.Datafile,
  SeeqNames.Types.Display,
];
export const TRENDABLE_TYPES = [
  'Annotation',
  SeeqNames.Types.CalculatedCondition,
  SeeqNames.Types.StoredCondition,
  SeeqNames.Types.CalculatedSignal,
  SeeqNames.Types.StoredSignal,
  SeeqNames.Types.CalculatedScalar,
  SeeqNames.Types.LiteralScalar,
  SeeqNames.Types.ThresholdMetric,
];
export const UNITFUL_TYPES = [
  SeeqNames.Types.CalculatedSignal,
  SeeqNames.Types.StoredSignal,
  SeeqNames.Types.CalculatedScalar,
  SeeqNames.Types.LiteralScalar,
];
export const ITEM_ICONS = {
  SERIES: 'fc fc-series',
  STRING_SERIES: 'fa fa-font',
  CAPSULE: 'fc fc-capsule',
  CONDITION: 'fc fc-capsule-set',
  ANNOTATION: 'fc fc-annotate',
  ASSET: 'fa fa-cube',
  SCALAR: 'fc fc-scalar',
  TABLE: 'fc fc-bar-chart',
  TABLE_DEFINITION: 'fc fc-table',
  METRIC: 'fc fc-metric',
  DATAFILE: 'fc fc-import',
  DISPLAY: 'fc fc-displays',
};
export const NUMBER_CONVERSIONS = {
  NANOSECONDS_PER_MILLISECOND: 1000000,
  MILLISECONDS_PER_SECOND: 1000,
  SECONDS_PER_HOUR: 3600,
};
export const DISPLAY_MODE = {
  NEW: 'NEW',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  IN_PROGRESS: 'IN_PROGRESS',
  RESULTS: 'RESULTS',
};
export const EDIT_MODE = {
  NORMAL: 'NORMAL',
  COPY: 'COPY',
  COPY_TO_FORMULA: 'COPY_TO_FORMULA',
};
export const APPSERVER_API_PREFIX = '/api';
export const APPSERVER_PATHS = {
  WEBSOCKET_EVENTS: '/events',
  JUPYTER_EVENTS: '/data-lab',
};
export const APPSERVER_API_CONTENT_TYPE = 'application/vnd.seeq.v1+json';
export const ACCESS_LEVELS = {
  FULL_CONTROL: 'FULL_CONTROL',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  NO_ACCESS: 'NO_ACCESS',
};
export const MAX_DURATION_WARNING_MSG_PATH =
  'warningLogs[0].formulaLogEntries.EXCEEDS_MAXIMUM_DURATION.logDetails[0].message';
// max duration we recommend for advanced searches, in months. Maximum Capsule Durations that exceed this limit will
// display a warning prompt that encourages users to switch to a simple condition.
export const RECOMMENDED_MAX_CAPSULE_DURATION = 3;
export const GUID_REGEX_PATTERN = '[A-Fa-f0-9]{8}-(?:[A-Fa-f0-9]{4}-){3}[A-Fa-f0-9]{12}';
// Use with lodash, _.get(timeUnit, STANDARD_TIME_UNIT_PATH) to get the standard unit out.
export const STANDARD_TIME_UNIT_PATH = 'unit[0]';
// The standard unit label must be first in the 'unit' array so that the path above works. The standard unit
// should be the only unit that is dehydrated as part of the `UIConfig` and worksteps. Other units are aliases
// that we use at runtime to handle a variety of units from the backend.
export const DURATION_TIME_UNITS: DurationTimeUnit[] = [
  {
    unit: ['s', 'second', 'seconds'],
    momentUnit: 's',
    translationKey: 'DURATION_UNITS.SECONDS',
  },
  {
    unit: ['min', 'minute', 'minutes'],
    momentUnit: 'm',
    translationKey: 'DURATION_UNITS.MINUTES',
  },
  {
    unit: ['h', 'hr', 'hrs', 'hour', 'hours'],
    momentUnit: 'h',
    translationKey: 'DURATION_UNITS.HOURS',
  },
  {
    unit: ['day', 'd', 'days'],
    momentUnit: 'd',
    translationKey: 'DURATION_UNITS.DAYS',
  },
];
export const DURATION_TIME_UNITS_ALL = DURATION_TIME_UNITS.concat([
  {
    unit: ['week', 'wk', 'weeks'],
    momentUnit: 'w',
    translationKey: 'DURATION_UNITS.WEEKS',
  },
  {
    unit: ['month', 'mo', 'months'],
    momentUnit: 'M',
    translationKey: 'DURATION_UNITS.MONTHS',
  },
  {
    unit: ['year', 'y', 'yr', 'years'],
    momentUnit: 'y',
    translationKey: 'DURATION_UNITS.YEARS',
  },
]);
// Units of measure for duration-based lengths that can be parsed by the backend (see ScalarUnits)
export const DURATION_SCALAR_UNITS = DURATION_TIME_UNITS.concat(DURATION_TIME_UNITS_ALL)
  .flatMap((d) => d.unit)
  .concat(['ns', 'ms']);
export const FREQUENCY_UNITS: DurationTimeUnit[] = [
  { unit: ['Hz', 'hertz'], translationKey: 'FREQUENCY_UNITS.HERTZ' },
  { unit: ['/min'], translationKey: 'FREQUENCY_UNITS.PER_MINUTE' },
  { unit: ['/h'], translationKey: 'FREQUENCY_UNITS.PER_HOUR' },
  { unit: ['/day'], translationKey: 'FREQUENCY_UNITS.PER_DAY' },
];
export const MIME_TYPES = {
  EXCEL: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  POWERPOINT: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  PDF: 'application/pdf',
};
export const HTTP_MAX_BODY_SIZE_BYTES = 95000;
export const NG_IF_WAIT = 100;
export const LIMIT_SIZE_1000 = 1000;
export const MAX_NAME_LENGTH = {
  WORKBOOK: 250, // allows topics, analyses and folders to have longer and more descriptive names
  WORKSHEET: 100, // shorter names for worksheets to allow for better readability on side panel
  TOOL: 100, // shorter names for tools to allow for better UI experience where tool names are seen
  CALCULATION: 100, // shorter names for calculations allows for more columns to be seen at once in the details panel
  NAME: 35, // suggested length limit for a first or last name from stack overflow
};
export const PERIOD_UNITS = DURATION_TIME_UNITS.concat({
  unit: ['Hz', 'hertz'],
  translationKey: 'FREQUENCY_UNITS.HERTZ',
});

export const DEFAULT_PERMISSIONS = {
  read: false,
  write: false,
  manage: false,
};

export type ACLPermissionName = keyof typeof DEFAULT_PERMISSIONS;

export const PERMISSIONS = {
  READ: 'read',
  WRITE: 'write',
  MANAGE: 'manage',
} as const;

export const SEEQ_DATASOURCE = {
  id: 'Seeq',
  name: 'Seeq Databases',
  datasourceClass: 'Seeq Local',
  datasourceId: 'Collected',
  type: 'dataSource',
  scopedTo: '',
};

export const SMALL_SIZE_SCREEN_LIMIT = 815;
export const SMALL_SIZE_SCREEN_LIMIT_SCATTER = 887;

export enum HOME_SCREEN_TABS {
  USERS = 'USERS',
  CORPORATE = 'CORPORATE',
  HOME = 'HOME',
  MY_FOLDER = 'MY_FOLDER',
  RECENT = 'RECENT',
  SEARCH = 'SEARCH',
  SHARED = 'SHARED',
  TRASH = 'TRASH',
  ADDONS = 'ADDONS',
}

// This determines the order of the tabs
export const HOME_SCREEN_TABS_AND_TRANSLATION_KEYS = [
  {
    value: HOME_SCREEN_TABS.HOME,
    text: 'HOME_SCREEN.TABS.HOME',
    adminOnly: false,
    id: 'specHomeTab',
  },
  {
    value: HOME_SCREEN_TABS.MY_FOLDER,
    text: 'HOME_SCREEN.TABS.MY_FOLDER',
    folderRoot: 'all',
    adminOnly: false,
    id: 'myFolder',
  },
  {
    value: HOME_SCREEN_TABS.SHARED,
    text: 'HOME_SCREEN.TABS.SHARED',
    folderRoot: 'shared',
    adminOnly: false,
    id: 'specSharedTab',
  },
  {
    value: HOME_SCREEN_TABS.CORPORATE,
    text: 'HOME_SCREEN.TABS.CORPORATE',
    folderRoot: 'corporate',
    adminOnly: false,
    id: 'specCorporateTab',
  },
  {
    value: HOME_SCREEN_TABS.USERS,
    text: 'HOME_SCREEN.TABS.USERS',
    folderRoot: 'users',
    adminOnly: true,
    id: 'specUsersTab',
  },
  {
    value: HOME_SCREEN_TABS.TRASH,
    text: 'HOME_SCREEN.TABS.TRASH',
    adminOnly: false,
    id: 'specTrashTab',
  },
  {
    value: HOME_SCREEN_TABS.ADDONS,
    text: 'HOME_SCREEN.TABS.ADDONS',
    adminOnly: false,
    id: 'specAddOnsTab',
  },
];

export const USERS_FOLDER_NAME = 'Users folder';

export const SEARCH_ITEM_LOCATIONS = {
  ALL: 'all',
  USERS: 'users',
  CORPORATE: 'corporate',
  MY_FOLDER: 'mine',
  PINNED: 'pinned',
  RECENT: 'recent', // We don't use this for homescreen advanced search  CRAB-19839
  TRASH: 'trash',
  SHARED_OR_PUBLIC: 'sharedOrPublic',
};

export const SEARCH_FIELDS = {
  ALL: 'all',
  NAME: 'name',
  CONTENT: 'document',
  DESCRIPTION: 'description',
};

export const ADVANCED_SEARCH = {
  SEARCH_TEXT: 'searchText',
  TYPE: 'type',
  LOCATION: 'location',
  SEARCH_FIELDS: 'searchFields',
};

// This determines the order of the search location options
export const SEARCH_LOCATION_OPTIONS = [
  {
    text: 'HOME_SCREEN.LOCATION.RECENT',
    value: SEARCH_ITEM_LOCATIONS.RECENT,
  },
  {
    text: 'HOME_SCREEN.LOCATION.PINNED',
    value: SEARCH_ITEM_LOCATIONS.PINNED,
  },
  {
    text: 'HOME_SCREEN.LOCATION.MY_FOLDER',
    value: SEARCH_ITEM_LOCATIONS.MY_FOLDER,
  },
  {
    text: 'HOME_SCREEN.LOCATION.SHARED',
    value: SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC,
  },
  {
    text: 'HOME_SCREEN.LOCATION.CORPORATE',
    value: SEARCH_ITEM_LOCATIONS.CORPORATE,
  },
  {
    text: 'HOME_SCREEN.LOCATION.USERS',
    value: SEARCH_ITEM_LOCATIONS.USERS,
    adminOnly: true,
  },
  {
    text: 'HOME_SCREEN.LOCATION.ALL',
    value: SEARCH_ITEM_LOCATIONS.ALL,
  },
];

export const SEARCH_LOCATION_OPTIONS_ADVANCED = [
  ...SEARCH_LOCATION_OPTIONS,
  {
    text: 'HOME_SCREEN.LOCATION.TRASH',
    value: SEARCH_ITEM_LOCATIONS.TRASH,
  },
];

export const KEY_CODES = {
  ENTER: 13,
  ESCAPE: 27,
  LEFT_ARROW: 37,
  UP_ARROW: 38,
  RIGHT_ARROW: 39,
  DOWN_ARROW: 40,
};

export const CORPORATE_DRIVE_NAME = 'Corporate';
export const CREATED_BY_SEEQ_WORKBENCH = 'Seeq Workbench';
export const SOCKET_LIVELINESS_TIMEOUT = 5 * 60 * 1000;
export const TEST_ENV = 'test';

// keep in sync with $sq-animation-time-ms variable from _custom_variables.scss
export const ANIMATION_TIME_MS = 400;

export const DELIMITERS: { text: string; value: 'Comma' | 'Semicolon' | 'Tab' }[] = [
  { text: ',', value: 'Comma' },
  { text: ';', value: 'Semicolon' },
  { text: 'Tab', value: 'Tab' },
];

export type Delimiter = typeof DELIMITERS[number]['value'];

/**
 * An extra 1 is added to indicate when the number of rows exceed the limit of `500,000`
 */
export const MAX_TABLE_ROWS = 500001;
