import _ from 'lodash';
import moment from 'moment-timezone';
import timezoneData from 'resources/timezone-filter.json';

export type Timezone = {
  name: string;
  displayName: string;
  offset: string;
  offsetMinutes: number;
};

export class TimezonesService {
  #timezones!: Timezone[];
  #defaultTimezone!: Timezone;

  constructor() {
    this.initialize();
  }

  /**
   * Gets the array of available time zones
   *
   * Note on offsets: The offset properties (.offset and .offsetMinutes) are the offsets for the current time in each
   * time zone. Since UTC offsets may change depending on the time of year (due to daylight savings time), they
   * should not be used directly to calculate times. They are intended to be used for sorting and grouping purposes.
   *
   * @returns Array of available timezones
   */
  get timezones(): Timezone[] {
    return this.#timezones;
  }

  /**
   * Gets the default time zone
   *
   * @returns The default timezone
   */
  get defaultTimezone(): Timezone {
    return this.#defaultTimezone;
  }

  /**
   * Initializes timezones and default timezone.
   *
   * @param zones - The zones to use
   */
  initialize(zones: (Pick<Timezone, 'name' | 'displayName'> | string)[] = timezoneData.zones) {
    this.#timezones = _.reduce(
      zones,
      (memo, zoneData) => {
        const name = _.isString(zoneData) ? zoneData : zoneData.name;
        const displayName = _.isString(zoneData) ? zoneData : zoneData.displayName;
        const zone = moment.tz.zone(name);
        if (zone) {
          memo.push({
            name,
            displayName,
            offset: moment.tz(name).format('Z'),
            offsetMinutes: -1 * zone.utcOffset(moment.now()),
          });
        }
        return memo;
      },
      [] as Timezone[],
    );
    this.#defaultTimezone = this.findMostCommonTimeZone();
  }

  /**
   * Gets all timezones which are in regional format (Europe\Berlin) or in a UTC offset (UTC+1). This is due to the
   * create/update datafile endpoints only taking regional and UTC offset timezone formats, so all timezones not
   * in those formats have to be removed
   *
   * @returns Array of available timezones following the above conditions.
   */
  getRegionalAndUtcOffsetTimezones(): Timezone[] {
    return _.reject(this.timezones, (timezone) => {
      // Rejects any timezone not in the form UTC+# or in regional form (Europe\Berlin), except for GMT/UTC
      // Example matches: PST8PDT, NZ-CHAT, WET
      return /^(([A-Za-z]{2}-[A-Za-z]{4})|([A-Za-z]{3}\d[A-Za-z]{3})|(?!(UTC|GMT))([A-Za-z]{3}))$/.test(
        timezone.displayName,
      );
    });
  }

  /**
   * Finds the most common timezone for the supplied offset, or guesses the best possible timezone if no offset is
   * provided.
   *
   * @param offset - a time zone offset. If not supplied, then use moment to determine the best timezone.
   * @returns The timezone to be selected.
   */
  findMostCommonTimeZone(offset?: string): Timezone {
    const preferredTimeZoneNames = [
      'US/Pacific',
      'US/Mountain',
      'US/Central',
      'US/Eastern',
      'UTC',
      'WET',
      'CET',
      'EET',
    ];

    if (!offset) {
      // If a user offset was not supplied as an argument, then use moment to determine the best offset choice
      const momentTimezoneGuess = moment.tz.guess();
      offset = moment.tz(momentTimezoneGuess).format('Z');
      // Make the moment-supplied timezone guess a preferred name
      preferredTimeZoneNames.push(momentTimezoneGuess);
    }

    // Determine the list of possible time zones based on the offset
    const possibleTimeZones = _.filter(this.timezones, ['offset', offset]);

    // Pick one from our "preferred" list if the offsets match
    let [preferredTimeZone] = _.chain(preferredTimeZoneNames)
      .map((name) => _.find(possibleTimeZones, { name }))
      .compact()
      .value();

    // If we didn't find a preferred time zone then just pick the first possible one that matches the offset. Default
    // to UTC as a last resort if nothing matches.
    if (!preferredTimeZone) {
      preferredTimeZone = _.head(possibleTimeZones) || _.find(this.timezones, ['name', 'UTC'])!;
    }

    return preferredTimeZone;
  }
}
