export const GRID_OPTION = {
  AUTOMATIC: 'automatic',
  CUSTOM: 'custom',
  ORIGINAL: 'original',
};

export const SAMPLES_TABLE_MODE = {
  CALENDAR: 'calendar',
  CAPSULE: 'capsuleTime',
  CHAIN: 'chainView',
};

export const ODATA_EXPORT_SEPARATOR = '-Export-';
export const ODATA_V4_PROTOCOL = 4;
export const ODATA_V2_PROTOCOL = 2;

export const CAPSULE_SUFFIX = 'Capsule';
