// @ts-strict-ignore
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { logInfo } from '@/utilities/logger';
import { reload } from '@/utilities/utilities';
import _ from 'lodash';
import { seeqVersion } from '@/services/systemConfiguration.utilities';

/**
 * An HTTP interceptor that will inspect the 'Server' header for the Seeq version running on the server.
 * If the version doesn't match the frontend version, the frontend will perform a force reload.
 */
export function versionInterceptor(response) {
  const serverHeader = _.get(response, `headers[${SeeqNames.API.Headers.Server}]`);
  if (serverHeader && _.includes(serverHeader, 'Seeq/')) {
    // Extract only the version part of the header (also removes any extra data added by proxies, CRAB-15461)
    const serverVersion = _.replace(serverHeader, /^(.*\s)*Seeq\/(.*?)(\s.*)*$/, '$2');
    if (serverVersion !== seeqVersion()) {
      // Server and client version don't match (e.g. server has been upgraded), so force a reload of the client
      logInfo(
        `Server and client version mismatch ('${serverVersion}' vs '${seeqVersion()}'), forcing reload of client`,
      );
      reload();
    }
  }

  return response;
}
