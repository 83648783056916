import { PluginWorkerApi } from 'plugin/pluginApiTypes';
import PluginWorker from '@/webWorkers/plugin.worker.shim.js?worker';
import { getCsrfHeaderFromCookie } from '@/webWorkers/plugin.worker.utilities';

let instance: PluginWorkerApi;

export function pluginWorkerApi(): PluginWorkerApi {
  if (instance) {
    return instance;
  }

  const worker = new PluginWorker();

  const csrf = getCsrfHeaderFromCookie(document.cookie);

  const callbacks: { [key: string]: { [key: string]: (value: unknown) => void } } = {};

  worker.onmessage = (event: MessageEvent) => {
    const { requestId, isError, payload } = event?.data ?? {};
    const onComplete = callbacks[requestId]?.resolve;
    const onError = callbacks[requestId]?.reject;
    delete callbacks[requestId];
    if (!isError && onComplete) {
      onComplete(payload);
    } else if (isError && onError) {
      onError(payload);
    }
  };

  const loaded = new Promise((resolve) => worker.addEventListener('message', resolve, { once: true }));

  const REQUEST_ID: { [key: string]: { [key: string]: number } } = {
    datalab: { callAPI: 0, getProject: 0 },
    sdk: { callAPI: 0 },
  };

  const execute = (type: string, fn: string, parameters: Record<string, any>): Promise<unknown> => {
    REQUEST_ID[type][fn] = ((REQUEST_ID[type][fn] || 0) + 1) % Number.MAX_SAFE_INTEGER;
    const requestId = type + '-' + REQUEST_ID[type][fn];
    return new Promise((resolve, reject) => {
      callbacks[requestId] = { resolve, reject };
      worker.postMessage({ type, fn, requestId, payload: { ...parameters } });
    });
  };

  const callSdkApi = (
    path: string,
    method?: string,
    query?: Record<string, unknown>,
    body?: Record<string, unknown>,
    headers: Record<string, unknown> = {},
  ) => {
    return execute('sdk', 'callApi', {
      path,
      method: method || 'GET',
      query,
      body,
      headers: { ...headers, ...csrf },
    });
  };

  const callDataLabApi = (
    projectId: string,
    notebookName: string,
    path: string,
    method?: string,
    query?: Record<string, unknown>,
    body?: Record<string, unknown>,
    headers: Record<string, unknown> = {},
  ) => {
    return execute('datalab', 'callApi', {
      projectId,
      notebookName,
      path,
      method: method || 'GET',
      query,
      body,
      headers: { ...headers, ...csrf },
    });
  };

  const getDataLabProject = (projectName: string): Promise<{ projectId: string }> => {
    return execute('datalab', 'getProject', { projectName, csrf }) as Promise<{ projectId: string }>;
  };

  instance = { loaded, callSdkApi, callDataLabApi, getDataLabProject };
  return instance;
}
