import _ from 'lodash';
import { TREND_COLORS } from '@/trendData/trendData.constants';
import { ChartSettingsInterface } from '@/tableBuilder/tableViewer/ChartSettings.molecule';
import { ColumnChartOptions } from 'other_components/highcharts';
import { TableChartDataIF } from '@/tableBuilder/tableViewer/ChartUtilities';

const getColumnBarOptions = (
  tableData: TableChartDataIF,
  settings: ChartSettingsInterface,
  chartType: string,
): ColumnChartOptions => {
  return {
    chart: {
      type: chartType,
      style: {
        fontFamily: 'inherit',
      },
      backgroundColor: 'transparent',
    },
    title: {
      text: settings.title,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: settings.legend,
      itemStyle: {
        fontFamily: 'inherit',
        textOutline: 'none',
        color: 'gray',
      },
    },
    xAxis: {
      categories: tableData.categories,
      labels: {
        enabled: settings.categoryLabels || false,
      },
      crosshair: true,
    },
    colors: [...TREND_COLORS],
    plotOptions: {
      series: {
        stacking: settings.stacked ? 'normal' : undefined,
        dataLabels: {
          enabled: settings.dataLabels,
          rotation: 0,
          formatter() {
            return this.point.options.custom!.displayValue;
          },
          style: _.assign({ fontFamily: 'inherit', textOutline: 'none' }, !settings.stacked ? { color: 'gray' } : {}),
        },
      },
    },
    tooltip: {
      formatter(tooltip) {
        this.point.y = this.point.options.custom!.displayValue;
        return tooltip.defaultFormatter.call(this, tooltip);
      },
      headerFormat: `{point.x}<br/>`,
      valueSuffix: ` {point.options.custom.uom}`,
    },
    series: _.map(tableData.values, (row, idx: number) => ({
      name: row.name ?? '',
      data:
        _.map(row.data, (entry) => ({
          value: entry.value,
          y: entry.value,
          custom: {
            displayValue: entry.displayValue === '-' ? '' : entry.displayValue,
            uom: entry.uom,
          },
        })) ?? [],
      type: 'column',
    })),
  };
};

export default getColumnBarOptions;
