import React from 'react';
import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import { getFormFieldProps } from '@/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { CompositesSelector } from '@/tools/CompositeLogicSelector.molecule';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import { CompositeLogic } from '@/tools/compositeSearch/compositeSearch.constants';

export interface CompositesSelectorIF extends ValidatingFormComponent<any> {
  component: 'CompositesSelectorFormComponent';
  logicFormulas: CompositeLogic[];
  onChange: (logic: string) => void;
  label?: string;
  testId?: string;
  insideModal?: boolean;
  appendToBody?: boolean;
  disabled?: boolean;
}

export const CompositesSelectorFormComponent: React.FunctionComponent<CompositesSelectorIF> = (props) => {
  const { name, value, validation, testId, onChange, label } = props;

  const defaultValidation = _.isEmpty;
  const formState = useForm().getState();

  return (
    <FormFieldWrapper testId={testId} label={label} wrapperClassNames="width-100percent">
      <Field name={name} validate={validation || defaultValidation}>
        {({ input, meta }) => (
          <CompositesSelector
            {...getFormFieldProps(formState, input, meta, props)}
            onChangeLogic={onChange}
            selectedLogic={value}
          />
        )}
      </Field>
    </FormFieldWrapper>
  );
};
