import { sqTreesApi } from '@/sdk/api/TreesApi';
import _ from 'lodash';
import { API_TYPES, CREATED_BY_SEEQ_WORKBENCH, SEARCH_TYPES } from '@/main/app.constants';
import {
  findChild,
  findExistingAsset,
  getColumnsHelper,
  getDependents,
  getNameWithPath,
  getPath,
  mapCalculationParamsToAssetChild,
} from '@/assetGroupEditor/assetGroup.utilities';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { getNextDefaultName, isAsset } from '@/utilities/utilities';
import { errorToast, successToast, warnToast } from '@/utilities/toast.utilities';
import { AssetGroupAssetInputV1 } from '@/sdk/model/AssetGroupAssetInputV1';
import { TreeItemOutputV1 } from '@/sdk/model/TreeItemOutputV1';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { flux } from '@/core/flux.module';
import { sqAssetGroupStore, sqWorkbenchStore } from '@/core/core.stores';
import { IGNORED_ITEM_TYPES, PATH_SEPARATOR } from '@/assetGroupEditor/assetGroup.constants';
import { doTrack } from '@/track/track.service';
import {
  AssetAncestor,
  AssetGroup,
  AssetGroupAsset,
  AssetGroupChild,
  AssetGroupInputParameter,
  isAssetGroupAsset,
} from '@/assetGroupEditor/assetGroup.types';
import { fetchAssetGroups, fetchAssetTrees } from '@/workbook/workbook.actions';
import { clear } from '@/search/search.actions';
import { FormulaEditorParam } from '@/formula/FormulaParametersTable.molecule';
import { fetchPropsForAllItems } from '@/trendData/trend.actions';
import { tabsetChangeTab } from '@/worksheet/worksheet.actions';
import { AssetGroupOutputV1 } from 'sdk/model/models';
import { AxiosPromise } from 'axios';

export function setName(name: string) {
  dispatchAndSetChanged('ASSET_GROUP_SET_NAME', { name });
  validateName(name);
}

export function setAssetGroup(payload: AssetGroup) {
  flux.dispatch('ASSET_GROUP_SET_ROOT', payload);
}

export function setDescription(description: string) {
  dispatchAndSetChanged('ASSET_GROUP_SET_DESCRIPTION', { description });
}

export function getColumns(): AssetGroupChild[] {
  return getColumnsHelper(sqAssetGroupStore.assets);
}

export function getColumn(name: string): AssetGroupChild | undefined {
  return _.find(getColumns(), ['name', name]);
}

export function reset() {
  flux.dispatch('ASSET_GROUP_RESET_STORE');
}

export function setIsLoading(isLoading: boolean) {
  flux.dispatch('ASSET_GROUP_SET_IS_LOADING', { isLoading });
}

export function setHasUnsavedChanges(hasUnsavedChanges: boolean) {
  flux.dispatch('ASSET_GROUP_SET_HAS_UNSAVED_CHANGES', { hasUnsavedChanges });
}

export function removeAttribute(name: string) {
  const dependents = _.chain(sqAssetGroupStore.assets)
    .flatMap((asset) => {
      const child = findChild(asset, name);
      if (child) {
        const assetScopedDependencies = getDependents(asset, child);
        // remove the id of the assigned parameter
        _.forEach(assetScopedDependencies, (dependent) => updateAssetChildParameter(asset, dependent, name));
        return assetScopedDependencies;
      }
      return null;
    })
    .compact()
    .uniqBy('name')
    .value();
  if (!_.isEmpty(dependents)) {
    warnToast({
      messageKey: 'ASSET_GROUP_EDITOR.DEPENDENT_REMOVED',
      messageParams: { items: _.join(_.map(dependents, 'name'), '\n') },
    });
  }

  dispatchAndSetChanged('ASSET_GROUP_REMOVE_ATTRIBUTE', { name });
}

export function removeAsset(name: string) {
  dispatchAndSetChanged('ASSET_GROUP_REMOVE_ASSET', { name });
}

export function deleteAssetGroup() {
  return sqTreesApi
    .removeNodeFromTree({ id: sqAssetGroupStore.id })
    .then(() => {
      successToast({ messageKey: 'ASSET_GROUP_EDITOR.GROUP_DELETED' });
      reloadSidePanel();
      fetchAssetTrees([getWorkbookId()]);
    })
    .catch(() => {
      errorToast({
        messageKey: 'ASSET_GROUP_EDITOR.ERROR_DELETING_ASSET_GROUP',
      });
    });
}

export function updateColumnName({ originalName, newName }: { originalName: string; newName: string }) {
  const columns = getColumns();
  const isUnique = !_.some(columns, { name: newName });
  if (isUnique) {
    dispatchAndSetChanged('ASSET_GROUP_UPDATE_COLUMN_NAME', {
      originalName,
      newName,
    });
  } else {
    errorToast({ messageKey: 'ASSET_GROUP_EDITOR.MUST_BE_UNIQUE' });
  }
}

export function updateAssetName({ original, newName }: { original: string; newName: string }) {
  const isUnique = !_.some(sqAssetGroupStore.assets, { name: newName });
  if (!isUnique) {
    errorToast({ messageKey: 'ASSET_GROUP_EDITOR.MUST_BE_UNIQUE' });
  } else {
    dispatchAndSetChanged('ASSET_GROUP_UPDATE_ASSET_NAME', {
      original,
      newName,
    });
  }
}

export function getDefaultAssetName() {
  const existingNames = _.map(sqAssetGroupStore.assets, 'name');
  return getNextDefaultName(existingNames, 'ASSET_GROUP_EDITOR.ASSET');
}

export function getDefaultAttributeName() {
  const existingNames = _.map(getColumns(), 'name');
  return getNextDefaultName(existingNames, 'ASSET_GROUP_EDITOR.COLUMN');
}

export function addNewAsset() {
  const noAsset = _.isEmpty(sqAssetGroupStore.assets);
  dispatchAndSetChanged('ASSET_GROUP_ADD_EMPTY_ASSET', {
    name: getDefaultAssetName(),
    manuallyAdded: sqWorkbenchStore.currentUser.id,
    children: [],
  });

  if (noAsset) {
    addNewColumn();
  }
}

export function addNewColumn() {
  const noAsset = _.isEmpty(sqAssetGroupStore.assets);
  if (noAsset) {
    addNewAsset();
  } else {
    dispatchAndSetChanged('ASSET_GROUP_ADD_ATTRIBUTE', {
      name: getDefaultAttributeName(),
      manuallyAdded: sqWorkbenchStore.currentUser.id,
    });
  }
}

export function addCalculationToAsset({
  asset,
  columnName,
  formula,
  parameters,
}: {
  asset: AssetGroupAsset;
  columnName: string;
  formula: string;
  parameters: FormulaEditorParam[];
}) {
  dispatchAndSetChanged('ASSET_GROUP_ADD_CALCULATION_CHILD_TO_ASSET', {
    asset,
    child: {
      formula,
      parameters,
      name: columnName,
      manuallyAdded: sqWorkbenchStore.currentUser.id,
    },
  });
}

export function ensureAssetExists() {
  const noAsset = _.isEmpty(sqAssetGroupStore.assets);
  if (noAsset) {
    dispatchAndSetChanged('ASSET_GROUP_ADD_ASSET', {
      name: getDefaultAssetName(),
      manuallyAdded: sqWorkbenchStore.currentUser.id,
      children: [],
    });
  }
}

type AddCalculationColumnParams = {
  formula: string;
  parameters: FormulaEditorParam[];
  calculationColumnName: string;
};

export function addCalculationColumn({ formula, parameters, calculationColumnName }: AddCalculationColumnParams) {
  const newColumnName = calculationColumnName ? calculationColumnName : getDefaultAttributeName();
  ensureAssetExists();
  const formulaParams = _.map(parameters, ({ name, item = { id: '', name: '' } }) => ({
    name,
    item,
    assetGroupColumnBased: item.id === item.name,
  }));

  dispatchAndSetChanged('ASSET_GROUP_ADD_ATTRIBUTE', {
    name: newColumnName,
    columnType: 'Calculation',
    formula,
    parameters: formulaParams,
    manuallyAdded: sqWorkbenchStore.currentUser.id,
  });
}

export function updateAssetChildParameter(asset: AssetGroupAsset, child: AssetGroupChild, parameterItemName: string) {
  flux.dispatch('ASSET_GROUP_UPDATE_CHILD_PARAMETER', { asset, child, parameterItemName });
}

export function removeChildFromAsset({ child, asset }: { child: AssetGroupChild; asset: AssetGroupAsset }) {
  dispatchAndSetChanged('ASSET_GROUP_REMOVE_CHILD_FROM_ASSET', {
    asset,
    child,
  });
  const dependents = getDependents(asset, child);
  if (_.isEmpty(dependents)) {
    successToast({ messageKey: 'ASSET_GROUP_EDITOR.ITEM_REMOVED' });
  } else {
    // ensure we clean up references to deleted columns in all children!
    _.forEach(dependents, (dependent) => updateAssetChildParameter(asset, dependent, child.name));
    warnToast({
      messageKey: 'ASSET_GROUP_EDITOR.DEPENDENT_REMOVED',
      messageParams: { items: _.join(_.map(dependents, 'name'), '\n') },
    });
  }
}

// assets must have a name and a path. sometimes we pre-fix the name with part of the path
export function getAssetForAssetGroup(asset: AssetAncestor): AssetGroupAsset {
  // we care about the asset name and the parents:
  let assetName = asset.name;
  const assetPath = getPath(asset);
  const ancestors = _.flatMap(asset.ancestors, (ancestor) => getAssetAncestors(ancestor));

  return {
    name: assetName,
    assetPath,
    ancestors,
  };
}

export function getAssetAncestors(asset: AssetAncestor): AssetAncestor {
  const assetPath = getPath(asset);
  const ancestors = _.flatMap(asset.ancestors, (ancestor) => getAssetAncestors(ancestor));

  return {
    name: asset.name,
    assetPath,
    id: asset.id,
    type: asset.type,
    ancestors,
  };
}

type AddAssetOrItemParams = {
  assetOrItem: any;
  addMatchingItemsOnly?: boolean;
  addItemsOfItemsChildren?: boolean;
};

/**
 * This function is called when an asset is added to the asset group via the data panel.
 */
export function addAssetOrItem({
  assetOrItem,
  addMatchingItemsOnly = false,
  addItemsOfItemsChildren = false,
}: AddAssetOrItemParams) {
  if (isAsset(assetOrItem) || addItemsOfItemsChildren) {
    return addAsset({ rowAsset: assetOrItem, addMatchingItemsOnly });
  } else {
    // if the user clicked on a signal or a condition we need to determine the parent, add the parent (if needed)
    // and that one child that the user selected.
    let parentPromise;
    const parent: any = _.last(_.filter(assetOrItem?.ancestors, { type: API_TYPES.ASSET }));
    // fetch parent so that we have the ancestors needed to determine a unique path:
    if (_.isEmpty(parent)) {
      parentPromise = Promise.resolve({
        data: { name: getDefaultAssetName() },
      });
    } else {
      parentPromise = sqItemsApi.getFormulaDependencies({ id: parent.id });
    }

    return parentPromise.then((response) => {
      const parent = response.data;
      let childName = assetOrItem.name;
      if (assetOrItem.type !== API_TYPES.ASSET) {
        // for items of items we want the name to include all the non-asset parents:
        const itemAncestors = _.filter(assetOrItem.ancestors, (ancestor) => ancestor.type !== API_TYPES.ASSET);
        const path = _.join(_.map(itemAncestors, 'name'), PATH_SEPARATOR);
        childName = getNameWithPath(childName, path);
      }

      const column = getColumn(childName);

      if (!itemTypesAreEqual(assetOrItem.type, column)) {
        const type = getColumnType(column?.type);
        errorToast({
          messageKey: 'ASSET_GROUP_EDITOR.ERROR_INCORRECT_ITEM_TYPE',
          messageParams: { type },
        });
      } else {
        const asset = getAssetForAssetGroup(parent);
        // we need to consider the Asset Path when looking for the Asset to make sure it is really the same
        const assetAlreadyAdded = findExistingAsset(sqAssetGroupStore.assets, {
          name: asset.name,
          assetPath: asset.assetPath,
        } as AssetGroupAsset);
        if (!assetAlreadyAdded) {
          dispatchAndSetChanged('ASSET_GROUP_ADD_ASSET', asset);
        }
        dispatchAndSetChanged('ASSET_GROUP_ADD_CHILD_TO_ASSET', {
          asset,
          child: getAssetGroupChildFromItem(assetOrItem, childName),
        });
      }
    });
  }
}

interface AssetOrItem {
  name: string;
  id: string;
  type: string;
}

// Helper function that returns a proper Asset Group Child for Signal or Conditions (NOT calculations) that are
// newly added to an Asset Group.
// NOTE: parameters contains only one entry that "points" to the original item, it's effectively a pass-thru signal
// the columnType tells us we are dealing with something other than a Calculation
export function getAssetGroupChildFromItem(item: AssetOrItem, name: string): AssetGroupChild {
  return {
    formula: '$signal',
    name,
    parameters: [
      {
        name: 'signal',
        assetGroupColumnBased: false,
        item: { id: item.id, name: item.name, type: item.type },
      },
    ],
    type: item.type,
    columnType: 'Item',
  };
}

export function addAsset({
  rowAsset,
  addMatchingItemsOnly,
}: {
  rowAsset: AssetGroupAsset;
  addMatchingItemsOnly: boolean;
}) {
  const getChildren = (item: TreeItemOutputV1, assetPath: string): AssetOrItem[] => {
    if (item.hasChildren) {
      return (
        _.chain(item.children)
          .flatMap((child) =>
            getChildren(child, `${assetPath}${_.isEmpty(assetPath) ? '' : PATH_SEPARATOR}${item.name}`),
          )
          // items of items are a special use case as they are an item in and itself that needs to be added in
          // addition to their children.
          .concat(
            item.type !== API_TYPES.ASSET
              ? { name: getNameWithPath(item.name, assetPath), id: item.id, type: item.type }
              : null,
          )
          .compact()
          .value()
      );
    } else {
      return [
        {
          name: getNameWithPath(item.name, assetPath),
          id: item.id,
          type: item.type,
        },
      ];
    }
  };

  if (!rowAsset.id) {
    return Promise.reject();
  }
  return sqTreesApi
    .getTree({
      id: rowAsset.id,
      offset: 0,
      // Chosen as the maximum children to be added at once before hitting performance problems
      limit: 1000,
      includeDescendants: true,
      scope: _.compact([getWorkbookId()]),
    })
    .then((response) => {
      let children: AssetOrItem[] = _.flatMap(response.data.children, (firstChild: TreeItemOutputV1) =>
        getChildren(firstChild, ''),
      );
      if (addMatchingItemsOnly) {
        const itemsInAssetGroup = _.map(getColumns(), 'name');
        children = _.filter(children, (child) => _.includes(itemsInAssetGroup, child.name));
      }
      children = _.filter(children, (child) => !_.includes(IGNORED_ITEM_TYPES, child.type));

      let itemsDidNotMatch = false;
      const assetGroupChildren: AssetGroupChild[] = _.reduce(
        children,
        (results, child) => {
          const column = getColumn(child.name);
          if (!itemTypesAreEqual(child.type as string, column)) {
            itemsDidNotMatch = true;
          } else {
            results.push(getAssetGroupChildFromItem(child, child.name));
          }
          return results;
        },
        [] as AssetGroupChild[],
      );

      if (itemsDidNotMatch) {
        errorToast({ messageKey: 'ASSET_GROUP_EDITOR.ERROR_COLUMN_TYPE' });
      }
      dispatchAndSetChanged('ASSET_GROUP_ADD_ASSET', {
        ...getAssetForAssetGroup(rowAsset),
        children: assetGroupChildren,
      });
    })
    .catch((e) => {
      errorToast({ httpResponseOrError: e });
    });
}

export function getAssetGroupSummary() {
  const assets = sqAssetGroupStore.assets;
  const columnNames = _.map(getColumns(), 'name');
  const manuallyAddedAssets = _.reduce(assets, (count, asset) => (asset.manuallyAdded ? ++count : count), 0);

  const assetAssigned = (child: AssetGroupChild) => child.id || child.formula;
  const manuallyAssigned = (child: AssetGroupChild) => assetAssigned(child) && child.manuallyAdded;

  // TODO CRAB-31667: restore manually added and manual override metrics
  const summaryStatistics = {
    unassigned: {
      isApplicable: (child: AssetGroupChild) => !assetAssigned(child),
      count: 0,
    },
    manuallyAdded: {
      isApplicable: (child: AssetGroupChild) => manuallyAssigned(child) && _.some([child.id], child.originalMapping),
      count: 0,
    },
    manualOverride: {
      isApplicable: (child: AssetGroupChild) => manuallyAssigned(child) && !_.some([child.id], child.originalMapping),
      count: 0,
    },
    assetAssigned: {
      isApplicable: assetAssigned,
      count: 0,
    },
  };

  _.forEach(assets, (asset) => {
    // count the + icons for manually added asset rows
    if (_.size(asset.children) < _.size(columnNames)) {
      summaryStatistics.unassigned.count += columnNames.length - _.size(asset.children);
    }

    _.forEach(asset.children, (child) => {
      _.forEach(summaryStatistics, (stat) => {
        stat.count = stat.isApplicable(child) ? ++stat.count : stat.count;
      });
    });
  });

  return _.chain(summaryStatistics).mapValues('count').assign({ manuallyAddedAssets }).value();
}

export function validateName(name: string): boolean {
  const invalid = _.isEmpty(_.trim(name));
  flux.dispatch('ASSET_GROUP_SET_NAME_ERROR', { error: invalid });
  return !invalid;
}

export async function saveOrUpdateAssetGroup(): Promise<void> {
  flux.dispatch('ASSET_GROUP_SET_ERRORS', []);

  if (!validateName(sqAssetGroupStore.name)) {
    errorToast({ messageKey: 'ASSET_GROUP_EDITOR.NO_NAME' });

    return Promise.reject();
  }

  const childAssets: AssetGroupAssetInputV1[] = _.chain(sqAssetGroupStore.assets)
    .map((asset) => {
      const children = _.chain(asset.children)
        .map((child: AssetGroupChild) => {
          // do not attempt to persist empty "slots"
          if (_.isEmpty(child.parameters) && child.columnType !== 'Calculation') {
            return null;
          }

          if (child.columnType === 'Item') {
            const childToSave = _.pick(child, ['id', 'name', 'formula', 'parameters']);
            const saveParams = _.map(childToSave.parameters, (param) => ({ name: param.name, id: param.item.id }));

            return {
              ...childToSave,
              additionalProperties: getAdditionalProperties(child),
              parameters: saveParams,
              scopedTo: getWorkbookId(),
              id: childToSave.id === '' ? undefined : childToSave.id,
            };
          }

          // it's a calculated item:
          const { mappings, dependencies } = mapCalculationParamsToAssetChild(asset, child.parameters);

          return {
            id: child.id === '' ? undefined : child.id,
            name: child.name,
            scopedTo: getWorkbookId(),
            formula: child.formula,
            parameters: mappings,
            dependencies: _.map(dependencies, (dependency) => ({
              variableName: dependency.variableName,
              columnName: dependency.columnName,
            })),
            additionalProperties: getAdditionalProperties(child),
          };
        })
        .compact()
        .value();

      if (_.isEmpty(children)) {
        return null;
      }

      return {
        id: asset.id === '' ? undefined : asset.id,
        name: asset.name,
        scopedTo: getWorkbookId(),
        additionalProperties: getAdditionalProperties(asset),
        children,
      };
    })
    .compact()
    .value();

  const rootAsset = {
    id: sqAssetGroupStore.id === '' ? undefined : sqAssetGroupStore.id,
    name: sqAssetGroupStore.name,
    description: sqAssetGroupStore.description,
    additionalProperties: [
      {
        name: SeeqNames.Properties.TreeType,
        value: CREATED_BY_SEEQ_WORKBENCH,
      },
      {
        name: SeeqNames.Properties.CreatedBy,
        value: sqWorkbenchStore.currentUser.id,
      },
    ],
    scopedTo: getWorkbookId(),
  };

  if (_.isEmpty(childAssets)) {
    errorToast({ messageKey: 'ASSET_GROUP_EDITOR.EMPTY_NOT_ALLOWED' });

    return Promise.reject();
  }

  const payload = {
    rootAsset,
    childAssets,
  };
  const createOrUpdatePromise = sqAssetGroupStore.id ? sqTreesApi.updateTree(payload) : sqTreesApi.createTree(payload);

  try {
    await handleCreateOrUpdateErrors(createOrUpdatePromise);
    await fetchPropsForAllItems();
    reloadSidePanel();
    fetchAssetTrees([getWorkbookId()]);
  } catch (error: any) {
    errorToast({
      messageKey:
        error?.errorCategory === 'SYNTAX'
          ? 'ASSET_GROUP_EDITOR.ERROR_SAVING_ASSET_GROUP'
          : 'ASSET_GROUP_EDITOR.DELETED_COLUMNS_ERROR',
    });
    doTrack('Asset Group', 'Error saving Asset Group');

    return Promise.reject();
  }
}

/**
 * Handles errors returned from creating or updating an AssetGroup. Some errors from that endpoint are returned
 * with the tree, so in order to not eat the error we need to harvest those and output them.
 *
 * @param createOrUpdatePromise
 */
export async function handleCreateOrUpdateErrors(createOrUpdatePromise: AxiosPromise<AssetGroupOutputV1>) {
  const { data: resultingTreeAndErrors } = await createOrUpdatePromise;
  if (_.size(resultingTreeAndErrors.errors) > 0) {
    const [deletedColumnErrors, otherErrors] = _.partition(resultingTreeAndErrors.errors, (error) =>
      _.startsWith(error.error, 'Variable is undefined'),
    );
    const isAnErrorWithoutNameOrMessage = _.some(otherErrors, (error) => _.isNil(error.name) || _.isNil(error.error));
    const errorMessagesWithFullInfo: string = _.map(
      _.filter(otherErrors, (error) => !_.isNil(error.name) && !_.isNil(error.error)),
      (error) => `${error.name} => ${error.error}`,
    ).join('\n');

    if (deletedColumnErrors.length) {
      warnToast({ messageKey: 'ASSET_GROUP_EDITOR.DELETED_COLUMNS_WARNING' });
    }

    if (errorMessagesWithFullInfo) {
      warnToast({
        messageKey: 'ASSET_GROUP_EDITOR.WARNING_SAVING_ASSET_GROUP',
        messageParams: { warning: errorMessagesWithFullInfo },
      });
    }

    if (isAnErrorWithoutNameOrMessage) {
      errorToast({ messageKey: 'ASSET_GROUP_EDITOR.UNKNOWN_ERROR' });
    }
    doTrack('Asset Group', 'Error saving Asset Group');
  }
}

export function getAdditionalProperties(item: AssetGroupChild | AssetGroupAsset) {
  const isChild = !isAssetGroupAsset(item);
  const propertiesArray = [];
  if (item.manuallyAdded) {
    propertiesArray.push({ name: 'manuallyAdded', value: item.manuallyAdded });
  }
  if (isChild && item.originalMapping) {
    propertiesArray.push({
      name: SeeqNames.Properties.OriginalMapping,
      value: item.originalMapping,
    });
  }
  if (item.assetPath) {
    propertiesArray.push({ name: SeeqNames.Properties.AssetPath, value: item.assetPath });
  }
  if (isChild && item.columnType === 'Calculation') {
    propertiesArray.push({ name: SeeqNames.Properties.ColumnType, value: 'Calculation' });

    // SeeqNames.Properties.ParameterToColumn are essential to keeping track of the "origin" of a parameter used
    // for the formula of a calculated item. If a parameter is based on an asset group column we only save the
    // column name and the parameter name as that will be enough to ensure asset group column based parameters behave
    // correctly; If an "external" parameter was assigned then we have to make sure we persist the item id so we can
    // properly populate select options.
    const parametersToColumn = _.map(item.parameters, ({ name, item, assetGroupColumnBased }) => {
      // if it's a static item make sure we preserve the item id so we can properly re-hydrate
      if (assetGroupColumnBased || item.name === item.id) {
        return { parameterName: name, columnName: item?.name };
      } else {
        return {
          parameterName: name,
          columnName: item?.name,
          id: item?.id,
          type: item?.type,
        };
      }
    });

    propertiesArray.push({
      name: SeeqNames.Properties.ParameterToColumn,
      value: JSON.stringify(parametersToColumn),
    });
  }

  propertiesArray.push({
    name: SeeqNames.Properties.TreeType,
    value: CREATED_BY_SEEQ_WORKBENCH,
  });

  return propertiesArray;
}

export function getWorkbookId(): string {
  return sqWorkbenchStore?.stateParams?.workbookId;
}

export function reloadSidePanel() {
  flux.dispatch('ASSET_GROUP_RESET_STORE');
  // reload data tab and ensure data tab root is selected
  tabsetChangeTab('sidebar', 'search');
  fetchAssetGroups([getWorkbookId()]).then(() => clear('main', SEARCH_TYPES));
}

export function restoreItem({ asset, columnName, itemId }: { asset: any; columnName: string; itemId: string }) {
  dispatchAndSetChanged('ASSET_GROUP_RESTORE_CHILD', {
    asset,
    name: columnName,
    newItemId: itemId,
  });
}

type AddItemToAssetParams = {
  asset: any;
  columnName: string;
  item: AssetOrItem;
  type: string;
};

export function addItemToAsset({ asset, columnName, item, type }: AddItemToAssetParams) {
  // add a new child with the columnName, pointing to the fillerID
  const column = getColumn(columnName);
  if (!itemTypesAreEqual(type, column)) {
    const type = getColumnType(column?.type);
    errorToast({
      messageKey: 'ASSET_GROUP_EDITOR.ERROR_INCORRECT_ITEM_TYPE',
      messageParams: { type },
    });
  } else {
    dispatchAndSetChanged('ASSET_GROUP_ADD_CHILD_TO_ASSET', {
      asset,
      child: getAssetGroupChildFromItem(item, columnName),
    });
  }
}

export function setHelpView(view: string): void {
  flux.dispatch('ASSET_GROUP_FORMULA_SET_HELP_VIEW', { view });
}

export function dispatchAndSetChanged(handler: string, payload: unknown) {
  flux.dispatch(handler, payload);
  setHasUnsavedChanges(true);
}

export function itemTypesAreEqual(itemType: string, column: AssetGroupChild | undefined) {
  if (_.isUndefined(column?.type)) {
    return true;
  }

  switch (column?.type) {
    case SeeqNames.Types.CalculatedSignal:
    case SeeqNames.Types.StoredSignal:
      return _.isEqual(itemType, SeeqNames.Types.CalculatedSignal) || _.isEqual(itemType, SeeqNames.Types.StoredSignal);
    case SeeqNames.Types.CalculatedCondition:
    case SeeqNames.Types.StoredCondition:
      return (
        _.isEqual(itemType, SeeqNames.Types.CalculatedCondition) || _.isEqual(itemType, SeeqNames.Types.StoredCondition)
      );
    default:
      return _.isEqual(column?.type, itemType);
  }
}

export function getColumnType(type: string | undefined) {
  if (_.isEqual(type, SeeqNames.Types.CalculatedSignal) || _.isEqual(type, SeeqNames.Types.StoredSignal)) {
    return 'Signal';
  } else if (_.isEqual(type, SeeqNames.Types.CalculatedCondition) || _.isEqual(type, SeeqNames.Types.StoredCondition)) {
    return 'Condition';
  } else {
    return type;
  }
}

type BatchAddAssetsParams = {
  items: string[];
  assetIds: string[];
  matchingOnly?: boolean;
  itemOfItemChildren?: boolean;
};

export function batchAddAssets({
  items,
  assetIds,
  matchingOnly = false,
  itemOfItemChildren = false,
}: BatchAddAssetsParams) {
  _.forEach(assetIds, (id) =>
    addAssetOrItem({
      assetOrItem: _.find(items, { id }),
      addMatchingItemsOnly: matchingOnly,
      addItemsOfItemsChildren: itemOfItemChildren,
    }),
  );
  flux.dispatch('SEARCH_CLEAR_SELECTED_ITEMS');
}

export function applyFormulaToAll(
  assets: AssetGroupAsset[],
  parameters: FormulaEditorParam[],
  formula: string,
  item: AssetGroupChild,
) {
  const parameterNamesForThisFormula = _.map(item.parameters, 'name');

  const invalidCalculatedItems: string[] = [];
  let count = 0;

  _.forEach(assets, (otherAsset: AssetGroupAsset) => {
    const otherChild = findChild(otherAsset, item.name);
    const allRequiredParametersPresent = _.every(parameterNamesForThisFormula, (param) =>
      _.some(otherAsset.children, (child) => _.some(child.parameters, (parameter) => parameter.name === param)),
    );

    if (otherChild && allRequiredParametersPresent) {
      addCalculationToAsset({
        asset: otherAsset,
        columnName: item.name,
        formula,
        parameters: parameters as AssetGroupInputParameter[],
      });
      count++;
    } else {
      invalidCalculatedItems.push(otherAsset.name);
    }
  });
  const items = _.join(invalidCalculatedItems, '\n');
  const messageKey = _.isEmpty(invalidCalculatedItems)
    ? 'ASSET_GROUP_EDITOR.APPLY_TO_ALL_SUCCESS'
    : 'ASSET_GROUP_EDITOR.PARAMETERS_NOT_PRESENT';
  const messageParams = _.isEmpty(invalidCalculatedItems)
    ? { count, total: count + invalidCalculatedItems.length }
    : { count, total: count + invalidCalculatedItems.length, items };

  successToast({
    messageKey,
    messageParams,
  });
}
