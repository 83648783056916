import { flux } from '@/core/flux.module';
import { BasicCursor, CursorPoint } from '@/utilities/cursor.constants';

/**
 * Adds a new cursor. If an existing cursor exists with the xValue and isCapsuleTime, it is replaced.
 *
 * @param xValue - x-value of the new cursor.
 * @param points - Contains all points for this cursor
 * @param points[key] - ID of the series with points
 * @param points[key][].yValue - y-value for the series with the specified ID at the specified x-value
 * @param isCapsuleTime - True if this is a capsule-time cursor, false for calendar-time
 */
export function addCursor(xValue: number, points: Record<string, CursorPoint[]>, isCapsuleTime: boolean) {
  flux.dispatch('TREND_CURSOR_ADD', {
    xValue,
    capsuleTime: isCapsuleTime,
    points,
  });
}

/**
 * Toggles the selected property on the cursor object.
 *
 * @param cursor - Object representing a cursor.
 * @param capsuleTime - True if this is a capsule-time cursor, false for calendar-time
 * @param multiSelect - True if multi-selection (by holding CTRL key)
 */
export function toggleCursorSelection(cursor: BasicCursor, capsuleTime: boolean, multiSelect: boolean) {
  flux.dispatch('TREND_CURSOR_TOGGLE_SELECTION', {
    ...cursor,
    selected: !cursor.selected,
    capsuleTime,
    multiSelect,
  });
}

/**
 * Deletes a cursor with the specified parameters.
 *
 * @param xValue - x-value for the cursor to delete
 * @param isCapsuleTime - True if this is a capsule-time cursor, false for calendar-time
 */
export function deleteCursor(xValue: number, isCapsuleTime: boolean) {
  flux.dispatch('TREND_CURSOR_DELETE', {
    xValue,
    capsuleTime: isCapsuleTime,
  });
}

/**
 * Deletes all cursors pf the type specified.
 *
 * @param isCapsuleTime - True to delete all capsule-time cursors, false to delete all calendar-time
 *   cursors.
 */
export function deleteAll(isCapsuleTime: boolean) {
  flux.dispatch('TREND_CURSOR_DELETE_ALL', { capsuleTime: isCapsuleTime });
}

export function deleteSelected(isCapsuleTime: boolean) {
  flux.dispatch('TREND_CURSOR_DELETE_SELECTED', { capsuleTime: isCapsuleTime });
}

/**
 * Sets the display of cursor y-values on/off.
 *
 * @param show - Whether or not to display cursor values
 */
export function setCursorValueFlags(show: boolean) {
  flux.dispatch('TREND_CURSOR_SET_VALUE_FLAGS', show);
}
