import React from 'react';
import classNames from 'classnames';
import { Icon } from '@seeqdev/qomponents';
import { getMostReadableIconType } from '@/utilities/tableBuilderHelper.utilities';
import { IconType } from '@seeqdev/qomponents/dist/Icon/Icon.types';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';

interface SortIconProps {
  active: boolean | undefined;
  columnKey: string;
  direction: string | undefined;
  headerBackgroundColor: string | undefined;
  level?: number;
  showLevel?: boolean;
  setDirection: (key: string, direction: string | undefined) => void;
  isInteractiveContent?: boolean;
}

export const TableBuilderSortIcon: React.FunctionComponent<SortIconProps> = ({
  active,
  direction,
  columnKey,
  headerBackgroundColor,
  level = undefined,
  showLevel = false,
  setDirection,
  isInteractiveContent,
}) => {
  const sortDirection = direction ?? 'asc';
  const reverseSort = sortDirection === 'asc' ? 'desc' : 'asc';
  // TODO: CRAB-40076: use sortDirection instead of reverseSort
  const sortIcon = `fa fa-sort-amount-${reverseSort}`;
  const extraClassNames = active || isInteractiveContent ? 'sq-icon-hover cursorPointer' : '';
  const onClick =
    active || isInteractiveContent
      ? () => setDirection(columnKey, isInteractiveContent && direction === 'asc' ? undefined : reverseSort)
      : () => {};

  const hasDirection = !!direction && !!setDirection;
  const iconType: IconType =
    isInteractiveContent && !hasDirection ? 'gray' : getMostReadableIconType(headerBackgroundColor);

  return (
    <>
      {(hasDirection || (isInteractiveContent && !!setDirection)) && !headlessRenderMode() && (
        <div className={classNames('flexColumnContainer', extraClassNames)} data-testid="sortIconWrapper">
          <Icon
            extraClassNames="fa-fw mt3"
            testId="sortIcon"
            icon={sortIcon}
            onClick={(e) => {
              e?.stopPropagation();
              onClick();
            }}
            type={iconType}
          />
          {level && showLevel && (
            // using a <p> to correctly align the <sup> when the table has scrollbar
            <p className="mb0">
              <sup className={classNames('sq-text-primary', `sq-icon-${iconType}`)}>{level}</sup>
            </p>
          )}
        </div>
      )}
    </>
  );
};
