export const LICENSE_STATUS = {
  NONE: 'no license',
  VALID: 'valid license',
  SHOW_ADMIN_LICENSE_WARNING: 'display admin license warning',
  SHOW_LICENSE_WARNING: 'display license warning',
  EXPIRED: 'license expired',
  UNKNOWN: 'unknown status',
};
export const WARNING_FLAGS = {
  STANDARD: {
    SHOW_ADMIN_WARNING_DAYS_COUNT: 60,
    SHOW_WARNING_DAYS_COUNT: 30,
  },
  TRIAL: {
    SHOW_ADMIN_WARNING_DAYS_COUNT: 30,
    SHOW_WARNING_DAYS_COUNT: 10,
  },
};
