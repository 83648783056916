import React from 'react';
import { useTranslation } from 'react-i18next';
import { getWorkbooksLink } from '@/main/routing.utilities';
import { Link } from 'react-router-dom';
import seeqLogoDarkBlueSm from 'resources/img/Seeq_logo_darkBlue_sm.png';

export const Unauthorized = () => {
  const { t } = useTranslation();

  return (
    <div data-testid="unauthorizedPage" className="flexColumnContainer flexCenter fullViewport splashScreen">
      <div className="container ml0 mr0">
        <div className="row flexJustifyCenter">
          <div className="span12">
            <div data-testid="mainContent" className="jumbotron text-center">
              <p>
                <img src={seeqLogoDarkBlueSm} />
              </p>
              <h2 className="mb10">{t('UNAUTHORIZED.TITLE')}</h2>
              <p>{t('UNAUTHORIZED.DESCRIPTION')}</p>
              <p>{t('UNAUTHORIZED.CONTACT_OWNER')}</p>
              <p>
                <Link to={getWorkbooksLink()}>{t('UNAUTHORIZED.WORKBOOK_OVERVIEW')}</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
