import { TableColumnFilter } from '@/core/tableUtilities/tables';
import { ColumnOrRowWithDefinitions, TableBuilderSortDirection } from '@/tableBuilder/tableBuilder.types';
import { TableBuilder } from '@/tableBuilder/tableBuilder.utilities';
import _ from 'lodash';

/**
 * Encapsulates all the methods for filtering and sorting table data in interactive content
 *
 * _NOTE: most of these methods are not pure, and will mutate the input columns array_
 */
class TableBuilderContent extends TableBuilder {
  /**
   * Returns the column with the specified key.
   * @param columns - Array of columns
   * @param key - Column key
   *
   * @returns the column with the specified key.
   */
  getColumn(columns: ColumnOrRowWithDefinitions[], key: string): ColumnOrRowWithDefinitions | undefined {
    return columns.find((column) => column.key === key);
  }

  addColumnFilter(
    columns: ColumnOrRowWithDefinitions[],
    key: string,
    filter: TableColumnFilter,
  ): ColumnOrRowWithDefinitions[] {
    return columns.map((column) => {
      if (column.key === key) {
        column.filter = filter;
      }

      return column;
    });
  }

  /**
   * Increments the sort level on all sorts
   */
  incrementSortLevels(columns: ColumnOrRowWithDefinitions[]): ColumnOrRowWithDefinitions[] {
    return columns.map((column) => {
      if (column.sort?.level) {
        column.sort.level++;
      }

      return column;
    });
  }

  /**
   * Set or update the sort criterion on the specified column. When sorting on multiple levels, the last column
   * added is the most important.
   * @param columns - Array of columns
   * @param key - column key or itemId
   * @param direction - column sort direction
   */
  addOrUpdateSort(
    columns: ColumnOrRowWithDefinitions[],
    key: string,
    direction: TableBuilderSortDirection,
  ): ColumnOrRowWithDefinitions[] {
    const columnToUpdate = this.getColumn(columns, key);
    if (!columnToUpdate) {
      return columns;
    }

    if (columnToUpdate.sort) {
      columnToUpdate.sort.direction = direction;

      return [...columns];
    }

    const updatedColumns = this.incrementSortLevels(columns);
    columnToUpdate.sort = { direction, level: 1 };
    return updatedColumns;
  }

  /**
   * Removes the sort criterion from the specified column.
   * @param columns - Array of columns
   * @param key - column key or itemId
   */
  removeSort(columns: ColumnOrRowWithDefinitions[], key: string): ColumnOrRowWithDefinitions[] {
    const column = this.getColumn(columns, key);
    if (!column) {
      return columns;
    }

    const sortLevel = column?.sort?.level ?? Infinity;
    column.sort = undefined;
    return columns.map((column) => {
      if (column.sort && column.sort?.level > sortLevel) {
        column.sort.level--;
      }

      return column;
    });
  }

  /**
   * Set (or unset) a sort criterion on a column. If direction is `undefined`, the sort criterion is removed.
   */
  sortByColumn(
    columns: ColumnOrRowWithDefinitions[],
    key: string,
    direction: TableBuilderSortDirection | undefined,
  ): ColumnOrRowWithDefinitions[] {
    if (!direction) {
      return this.removeSort(columns, key);
    }

    return this.addOrUpdateSort(columns, key, direction);
  }
}

export const tableBuilderContent = new TableBuilderContent();
