import _ from 'lodash';
import React from 'react';
import Highcharts from 'highcharts';
import { DraggableMinimapHandle } from '@/scatterPlot/minimap/DraggableMinimapHandle.molecule';
import { SCATTER_PLOT_COLORS } from '@/scatterPlot/scatterPlot.constants';

interface MinimapHandleLaneIF {
  chart: Highcharts.Chart | null;
  useSelectors: boolean;
  getXLocations: () => {
    rangeStart: number;
    rangeEnd: number;
    lowerSlider: number;
    upperSlider: number;
  };
  dragMoveLowerMiddle: (dragNumber: number) => void;
  dragMoveMiddleUpper: (dragNumber: number) => void;
}

export const MINIMAP_HANDLE_LOWER = 'lowerHandle';
export const MINIMAP_HANDLE_UPPER = 'upperHandle';

/**
 * A lane where the minimap handles live.
 */
export const MinimapHandleLane: React.FunctionComponent<MinimapHandleLaneIF> = (props) => {
  const { chart, useSelectors, getXLocations, dragMoveLowerMiddle, dragMoveMiddleUpper } = props;
  const lowerHandleAdjustment = -4;
  const upperHandleAdjustment = -14;

  const getLowSelectorX = () => {
    if (_.isEmpty(chart)) {
      return 0;
    }

    const xLocations = getXLocations();
    let lowSelectorX = _.clamp(xLocations.lowerSlider, xLocations.rangeStart, xLocations.rangeEnd);
    lowSelectorX = (chart as Highcharts.Chart).xAxis[0].toPixels(lowSelectorX, false);
    return lowSelectorX + lowerHandleAdjustment;
  };

  const getHighSelectorX = () => {
    if (_.isEmpty(chart)) {
      return 0;
    }

    const xLocations = getXLocations();
    let highSelectorX = _.clamp(xLocations.upperSlider, xLocations.rangeStart, xLocations.rangeEnd);
    highSelectorX = (chart as Highcharts.Chart).xAxis[0].toPixels(highSelectorX, false);
    return highSelectorX + upperHandleAdjustment;
  };

  if (!useSelectors) {
    return <div className="flexRowContainer minimapHandleLane" />;
  } else {
    return (
      <div className="flexRowContainer minimapHandleLane">
        <DraggableMinimapHandle
          left={getLowSelectorX()}
          color={SCATTER_PLOT_COLORS.MID}
          id={MINIMAP_HANDLE_LOWER}
          dragAction={dragMoveLowerMiddle}
        />
        <DraggableMinimapHandle
          left={getHighSelectorX()}
          color={SCATTER_PLOT_COLORS.HIGH}
          id={MINIMAP_HANDLE_UPPER}
          dragAction={dragMoveMiddleUpper}
        />
      </div>
    );
  }
};
