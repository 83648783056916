// @ts-strict-ignore
import { PersistenceLevel, Store } from '@/core/flux.service';

export class SystemWarningStore extends Store {
  static readonly storeName = 'sqSystemWarningStore';
  persistenceLevel: PersistenceLevel = 'NONE';

  initialize() {
    this.state = this.immutable({
      warningComponents: [],
      systemMessage: '',
      showSystemWarning: true,
      suppressUserLimitWarning: false,
    });
  }

  get warningComponents() {
    return this.state.get('warningComponents');
  }

  get systemMessage() {
    return this.state.get('systemMessage');
  }

  get showSystemWarning() {
    return this.state.get('showSystemWarning');
  }

  get suppressUserLimitWarning() {
    return this.state.get('suppressUserLimitWarning');
  }

  // this exists to enable mocking of the environment during testing
  get nodeEnv() {
    return process.env.NODE_ENV;
  }

  protected readonly handlers = {
    /**
     * Sets the components that are in a warning state
     *
     * @param {String[]} components - an array of component names that are in a warning state.
     * For instance, ['CPU', 'Memory', 'Disk'].
     */
    SYSTEM_WARNINGS_SET_WARNING_COMPONENTS: (components) => this.state.set('warningComponents', components),

    /**
     * Sets the system message.
     *
     * @param {Object} payload
     * @param {string} payload.message - the system admin message.
     */
    SYSTEM_WARNINGS_SET_SYSTEM_MESSAGE: (payload) => this.state.set('systemMessage', payload.message),

    /**
     * Stops the system warning from being displayed
     */
    SYSTEM_WARNINGS_DISABLE_SYSTEM_WARNING: () => this.state.set('showSystemWarning', false),

    /**
     * Hides the user limit warning
     */

    SYSTEM_WARNINGS_SUPPRESS_USER_LIMIT_WARNING: () => this.state.set('suppressUserLimitWarning', true),
  };
}
