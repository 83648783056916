import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Accordion, ButtonWithPopover, Icon } from '@seeqdev/qomponents';
import { Ace, Acl } from '@/accessControl/itemAclModal.utilities';
import { IdentityOption, SelectIdentity } from '@/core/SelectIdentity.molecule';
import { IdentityPreviewV1 } from '@/sdk/model/IdentityPreviewV1';
import { sqUsersApi } from '@/sdk/api/UsersApi';
import { Table, TableColumn } from '@/core/Table.atom';
import { prettyPermissions } from '@/utilities/utilities';
import { PermissionsV1 } from '@/sdk/model/PermissionsV1';
import { AcePopover } from '@/accessControl/AcePopover.molecule';
import { IdentityPopover } from '@/accessControl/IdentityPopover.molecule';
import {
  deDupeAceList,
  filterToGroupAces,
  getAcesGrantingUserAccess,
  getAggregatedPermissions,
  getOtherGroupAssignments,
} from '@/accessControl/aclModalCheckAccessTab.utilities';

type AclModalCheckAccessTabProps = {
  itemAcl: Acl;
};

export const AclModalCheckAccessTab: React.FunctionComponent<AclModalCheckAccessTabProps> = ({ itemAcl }) => {
  const { t } = useTranslation();

  const [selectedUser, setSelectedUser] = useState({} as IdentityOption);
  const [acesGrantingUserAccess, setAcesGrantingUserAccess] = useState([] as Ace[]);
  const [matchingAces, setMatchingAces] = useState(false);
  const [otherGroupsOfUser, setOtherGroupsOfUser] = useState([] as IdentityPreviewV1[]);
  const [aggregatedPermissions, setAggregatedPermissions] = useState({} as PermissionsV1);
  const [anyGroupGrantedAccess, setAnyGroupGrantedAccess] = useState(false);

  const renderAceCell = (ace: Ace) => {
    return (
      <>
        {(ace.identity?.isRedacted || !ace.identity?.isEnabled) && (
          <Icon icon="fa-exclamation-triangle" type="danger" testId="aceNotAccessible" />
        )}
        <ButtonWithPopover
          placement="right"
          isHoverEnabled
          key={ace.id}
          trigger={<span className="sq-text-primary">{ace.identity?.name}</span>}
          data-testid="aclPopover">
          <div id={`${ace.id}_ace_popover`} className="ml5">
            <AcePopover ace={ace} />
          </div>
        </ButtonWithPopover>
      </>
    );
  };

  const renderPermissionsCell = (ace: Ace) => <span>{prettyPermissions(ace.permissions || {})}</span>;
  const aceTableColumns: TableColumn[] = [
    {
      accessor: 'name',
      sortable: false,
      filterable: false,
      cellStyle: { width: 100, padding: '0 7px' },
      header: 'ACCESS_CONTROL.IDENTITY',
      cellRenderFunction: renderAceCell,
    } as TableColumn,
    {
      accessor: 'permissions',
      sortable: false,
      filterable: false,
      cellStyle: { width: 100, padding: '0 7px' },
      header: 'ACCESS_CONTROL.PERMISSIONS',
      cellRenderFunction: renderPermissionsCell,
    } as TableColumn,
  ];

  const renderIdentityCell = (identity: IdentityPreviewV1) => {
    return (
      <>
        {(identity.isRedacted || !identity.isEnabled) && (
          <Icon icon="fa-exclamation-triangle" type="danger" testId="aceNotAccessible" />
        )}
        <ButtonWithPopover
          placement="right"
          key={identity.id}
          isHoverEnabled
          hasArrow
          extraTriggerClassNames=""
          trigger={<span className="sq-text-primary">{identity.name}</span>}
          data-testid="identityPopover">
          <div id={`${identity.id}_identity_popover`} className="ml5">
            <IdentityPopover identity={identity} />
          </div>
        </ButtonWithPopover>
      </>
    );
  };
  const groupsOfUserColumns: TableColumn[] = [
    {
      accessor: 'name',
      sortable: false,
      filterable: false,
      cellStyle: { width: 100, padding: '0 7px' },
      header: 'ACCESS_CONTROL.TYPES.USERGROUP',
      cellRenderFunction: renderIdentityCell,
    } as TableColumn,
  ];

  useEffect(() => {
    if (!_.isEmpty(selectedUser)) {
      sqUsersApi.getUser({ id: selectedUser.id }).then(({ data }) => {
        const acesGrantingUserAccess = getAcesGrantingUserAccess(itemAcl.entries, data);
        setAnyGroupGrantedAccess(acesGrantingUserAccess.length > 0);
        setAggregatedPermissions(getAggregatedPermissions(acesGrantingUserAccess));
        if (acesGrantingUserAccess.length > 0) {
          setMatchingAces(true);
          setAcesGrantingUserAccess(deDupeAceList(acesGrantingUserAccess));
        } else {
          setMatchingAces(false);
          setAcesGrantingUserAccess(deDupeAceList(filterToGroupAces(itemAcl.entries)));
        }
        setOtherGroupsOfUser(getOtherGroupAssignments(acesGrantingUserAccess, data.groups || []));
      });
    }
  }, [selectedUser]);

  const [activeAccordion, setActiveAccordion] = useState('');

  return (
    <div className="flexRowContainer flexFill mt15 overflowVisible" id="aclGrid">
      {t('ACCESS_CONTROL.CHECK_ACCESS_TAB.LABEL')}

      <div className="form-group mt15">
        <label htmlFor="user">{t('ACCESS_CONTROL.CHECK_ACCESS_TAB.USER_LABEL')}</label>
        <SelectIdentity
          idForLabel="user"
          identity={selectedUser}
          setIdentity={setSelectedUser}
          allowGroups={false}
          startEditable={true}
          placeholder="ACCESS_CONTROL.CHECK_ACCESS_TAB.USER_PLACEHOLDER"
        />
      </div>

      {!_.isEmpty(selectedUser) ? (
        <>
          {matchingAces ? (
            <div className="sq-text-primary">
              <Icon icon="fa-check sq-text-success ml5 mr5" />
              <span>
                {t('ACCESS_CONTROL.CHECK_ACCESS_TAB.MESSAGE_ACCESS_GRANTED', {
                  permissions: prettyPermissions(aggregatedPermissions),
                })}
              </span>
            </div>
          ) : (
            <div className="sq-text-danger">
              <Icon icon="fa-ban sq-text-danger ml5 mr5" />
              <span>{t('ACCESS_CONTROL.CHECK_ACCESS_TAB.MESSAGE_NO_ACCESS')}</span>
            </div>
          )}

          <div className="flexFill aclGrid pt10 pl10 pr5">
            <Table columns={aceTableColumns} items={acesGrantingUserAccess} testId="acesGrantingAccess" />
            {!acesGrantingUserAccess.length && (
              <div className="flexNoGrowNoShrink borderGray sq-bg-light-gray mt0 ml0 mr0 mb0 pt4 pb3 pl3 pr3 text-center">
                {t('ACCESS_CONTROL.CHECK_ACCESS_TAB.GROUP_PERMISSIONS_NO_RESULTS')}
              </div>
            )}
          </div>
          <Accordion
            extraClassNames="pt10 pl10 pr5"
            value={activeAccordion}
            onItemSelect={(value: string) => setActiveAccordion(value)}
            testId="aclModalCheckAccessTabAccordion"
            accordionItems={[
              {
                value: '0',
                trigger: (
                  <div
                    data-testid="detailsToggle"
                    className="sq-text-primary cursorPointer flexColumnContainer flexAlignCenter">
                    <span className="pr5">
                      {t(
                        anyGroupGrantedAccess
                          ? 'ACCESS_CONTROL.CHECK_ACCESS_TAB.OTHER_GROUP_ASSIGNMENTS'
                          : 'ACCESS_CONTROL.CHECK_ACCESS_TAB.ALL_GROUP_ASSIGNMENTS',
                      )}
                    </span>
                    <Icon icon={activeAccordion === '0' ? 'fa-angle-down' : 'fa-chevron-right'} />
                  </div>
                ),
                content: (
                  <>
                    <Table columns={groupsOfUserColumns} items={otherGroupsOfUser} testId="otherGroupsOfUser" />
                    {!otherGroupsOfUser.length && (
                      <div className="flexNoGrowNoShrink borderGray sq-bg-light-gray mt0 ml0 mr0 mb0 pt4 pb3 pl3 pr3 text-center">
                        {t('ACCESS_CONTROL.CHECK_ACCESS_TAB.OTHER_GROUP_OF_USER_NO_RESULTS')}
                      </div>
                    )}
                  </>
                ),
                itemTestId: 'groupsOfUser',
                id: 'groupsOfUser',
              },
            ]}
          />
        </>
      ) : null}
    </div>
  );
};
