import _ from 'lodash';
import { ChartSettingsInterface } from '@/tableBuilder/tableViewer/ChartSettings.molecule';
import { TableChartDataIF } from '@/tableBuilder/tableViewer/ChartUtilities';
import { PieChartOptions } from 'other_components/highcharts';

const getPieOptions = (tableData: TableChartDataIF, settings: ChartSettingsInterface): PieChartOptions => {
  return {
    chart: {
      type: 'pie',
      animation: false,
      style: {
        fontFamily: 'inherit',
      },
      backgroundColor: 'transparent',
    },
    title: {
      text: settings.title,
    },
    tooltip: {
      backgroundColor: '#FFFFFF',
      headerFormat: '<b>{point.key}</b><br />',
      pointFormat: '{series.name}: <b>{point.options.custom.displayValue} ({point.percentage:.1f}%)</b>',
      footerFormat: '',
      shared: false,
      useHTML: true,
      valueSuffix: '',
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: settings.legend,
      itemStyle: {
        fontFamily: 'inherit',
        textOutline: 'none',
        color: 'gray',
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: settings.dataLabels,
          rotation: 0,
          style: {
            fontFamily: 'inherit',
            textOutline: 'none',
            color: 'gray', // ensure labels are visible in both dark, and light screenshots
          },
        },
        events: {
          mouseOver() {},
          mouseOut() {},
        },
      },
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    series: [
      {
        name: tableData.categories[0],
        // for a pie chart we take only the first category of data and ignore the rest
        data: _.map(tableData.values, (value) => ({
          name: value.name,
          y: _.isEmpty(value?.data) ? 0 : value.data[0].value,
          custom: {
            displayValue: value.data[0].displayValue,
          },
        })),
        type: 'pie',
        tooltip: {
          valueSuffix: '',
        },
      },
    ],
  };
};

export default getPieOptions;
