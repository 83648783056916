import React from 'react';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { getAllItems } from '@/trend/trendDataHelper.utilities';
import _ from 'lodash';
import { sqItemsApi } from '@/sdk/api/ItemsApi';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { TooltipPosition } from '@seeqdev/qomponents/dist/Tooltip/Tooltip.types';
import classNames from 'classnames';
import {
  sqTrendConditionStore,
  sqTrendMetricStore,
  sqTrendScalarStore,
  sqTrendSeriesStore,
  sqTrendTableStore,
} from '@/core/core.stores';
import { addTrendItem } from '@/trendData/trend.actions';

interface AddToDisplayPaneIF {
  itemId: string;
  tooltip?: string;
  extraClassNames?: string;
  tooltipPlacement?: TooltipPosition;
  afterAdd?: (itemId: string) => Promise<void> | void;
}

export const AddToDisplayPane: React.FunctionComponent<AddToDisplayPaneIF> = (props) => {
  const { itemId, tooltip, extraClassNames, tooltipPlacement, afterAdd } = props;

  // getAllItems uses these stores to source items, so we must respond to changes in those stores
  useFlux(sqTrendSeriesStore);
  useFlux(sqTrendScalarStore);
  useFlux(sqTrendConditionStore);
  useFlux(sqTrendTableStore);
  useFlux(sqTrendMetricStore);

  const trendItems = getAllItems({});

  if (_.some(trendItems, { id: itemId })) {
    return null;
  }

  const addToDisplay = () => {
    sqItemsApi
      .getItemAndAllProperties({ id: itemId })
      .then(({ data: item }) => addTrendItem(item))
      .then(() => (afterAdd ? afterAdd(itemId) : undefined));
  };

  return (
    <HoverTooltip placement={tooltipPlacement || 'right'} text={tooltip || 'ADD_TO_DISPLAY'}>
      <a
        data-testid="addToDisplay"
        className={classNames(
          'displayIcon a fa-sharp fa-regular fa-chart-area link-no-underline animated animate-if-opacity pt2 pl5' +
            ' force-link-color',
          extraClassNames,
        )}
        onClick={addToDisplay}
      />
    </HoverTooltip>
  );
};
