import { InvestigateToolType } from '@/toolSelection/investigate.constants';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { sqInvestigateStore, sqWorkbenchStore } from '@/core/core.stores';
import { getAxiosInstance } from '@/requests/axios.utilities';
import { DEFAULT_WINDOW_DETAILS, focusOrOpenNamedWindow, prepareTargetUrl } from '@/toolSelection/investigate.actions';
import { DISPLAY_MODE } from '@/main/app.constants';
import { PUSH_IGNORE, PushOption } from '@/core/flux.service';
import { flux } from '@/core/flux.module';
import _ from 'lodash';

// File created to assist in mocking the functions in investigate.actions.test

/**
 * Execute the action specified by the add-on tool linkType property
 */
export function executeAddOnTool(tool: InvestigateToolType) {
  const targetUrl = prepareTargetUrl(tool.targetUrl as string);

  switch (tool.linkType) {
    case SeeqNames.API.AddOnToolLinkType.Window: {
      const details = !_.isEmpty(tool.windowDetails) ? tool.windowDetails : DEFAULT_WINDOW_DETAILS;
      if (tool.reuseWindow) {
        const windowName = `${sqWorkbenchStore.interactiveSessionId}_${_.snakeCase(tool.name)}`;
        focusOrOpenNamedWindow(targetUrl, windowName, details);
      } else {
        window.open(targetUrl, '_blank', details);
      }
      break;
    }
    case SeeqNames.API.AddOnToolLinkType.Tab:
      window.open(targetUrl);
      break;
    case SeeqNames.API.AddOnToolLinkType.None:
      getAxiosInstance().get(targetUrl);
      break;
    default:
      throw new TypeError(`${tool.linkType} is not a valid linkType`);
  }
}

/**
 * Sets the display mode.
 *
 * @param {String} mode - One of DISPLAY_MODE
 * @param {String} [option] - Option for dispatch, such as PUSH_IGNORE
 */
export function setDisplayMode(mode: string, option?: string) {
  option = _.isUndefined(option) && mode === DISPLAY_MODE.IN_PROGRESS ? PUSH_IGNORE : undefined;
  if (!_.includes(_.values(DISPLAY_MODE), mode)) {
    throw new TypeError(`${mode} is not a valid mode`);
  }

  flux.dispatch('INVESTIGATE_SET_DISPLAY_MODE', { mode, type: sqInvestigateStore.activeTool }, option as PushOption);
}
