import { useEffect, useState } from 'react';

/**
 * This hook returns true if the key provided is pressed or depressed.
 *
 * Example: Check if the "enter" key was pressed.
 * const enterKey = useKey(13);
 * useEffect(()=> {
 *   // do stuff when enter was clicked
 * }, [enterKey]);
 *
 * @param keyCode - the code of the key we are testing for.
 */
export function useKey(keyCode: number) {
  const [pressed, setPressed] = useState(false);
  const isKey = (keyEvent: KeyboardEvent) => keyCode === keyEvent.which;
  const onDown = (e: KeyboardEvent) => setPressed(isKey(e));
  const onUp = (e: KeyboardEvent) => isKey(e) && setPressed(false);

  useEffect(() => {
    window.addEventListener('keydown', onDown);
    window.addEventListener('keyup', onUp);
    return () => {
      window.removeEventListener('keydown', onDown);
      window.removeEventListener('keyup', onUp);
    };
  }, [keyCode]);

  return pressed;
}
