import { t } from 'i18next';

type RoleOption = { value: string | null; label: string };

export const getUserRoleOptions = (): RoleOption[] => [
  {
    value: null,
    label: t('USER.ROLE_PROMPT'),
  },
  {
    value: 'Engineer',
    label: t('USER.ROLES.ENGINEER'),
  },
  {
    value: 'Operator',
    label: t('USER.ROLES.OPERATOR'),
  },
  {
    value: 'Data_Scientist',
    label: t('USER.ROLES.DATA_SCIENTIST'),
  },
  {
    value: 'Quality_Assurance',
    label: t('USER.ROLES.QA_SPECIALIST'),
  },

  {
    value: 'Manager',
    label: t('USER.ROLES.MANAGER'),
  },
  {
    value: 'Director',
    label: t('USER.ROLES.DIRECTOR'),
  },
  {
    value: 'IT',
    label: t('USER.ROLES.IT_ADMIN'),
  },
  {
    value: 'OTHER',
    label: t('USER.ROLES.OTHER'),
  },
];
