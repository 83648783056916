import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { isSystemTest } from '@/core/utilities';

export const NUMBERS_ONLY_REGEX = /^((?!\s*$)[-+]?[0-9]*\.?[0-9]*([eE][-+]?[0-9]+)?)$/;
export const DEBOUNCE = {
  /** Suitable for deduping multiple calls that are expected to happen in quick succession,
   * but too short for use debouncing user input */
  SHORT: headlessRenderMode() ? 1 : 100,
  /** Suitable for user input/dragging/scrolling/resizing events. When in doubt use this one */
  MEDIUM: headlessRenderMode() ? 1 : 300,
  /** For cases where a consistent medium debounce behavior is required (when resizing ag-grid columns) */
  MEDIUM_WITH_HEADLESS_RENDER_SUPPORT: 300,
  /** Much the same as MEDIUM but for especially expensive api calls (pdf preview, investigate range capsules) */
  LONG: headlessRenderMode() ? 1 : 1000,
  /** The amount of debounce to use for workstep creation */
  WORKSTEP: headlessRenderMode() || isSystemTest() ? 1 : 300,
  /** The amount of debounce to use for live tool previews */
  PREVIEW: headlessRenderMode() || isSystemTest() ? 1 : 500,
  /** Saving a large report is expensive, so wait until longer. Number based on what Google Docs uses
   * System tests need a small debounce to avoid a race condition (CRAB-38841) */
  SAVE_REPORT: headlessRenderMode() || isSystemTest() ? 100 : 3000,
} as const;
export type DebounceValue = typeof DEBOUNCE[keyof typeof DEBOUNCE];
export const THROTTLE = {
  // The timebar stutters without this minimal throttle
  DRAG_TIMEBAR: 1,
  SHORT: 50,
};
