import { sqDurationStore } from '@/core/core.stores';
import { getCapsuleFormula } from '@/datetime/dateTime.utilities';
import { getChartWidth } from '@/trendData/trend.actions';
import { getMaxSeriesPixels } from '@/core/utilities';

/**
 * Returns the viewCapsule object that represents the "unbound" parameter required to execute a table.
 * The backend expects an unbound parameter to be defined by the following JSON:
 * {unbound: true, name: '<name>', formula: 'capsule(<start>, <end>)'}
 *
 * @returns {Object} defining and unbound parameter called viewCapsule.
 */
export function getViewCapsuleParameter() {
  return {
    unbound: true,
    name: 'viewCapsule',
    formula: getCapsuleFormula(sqDurationStore.displayRange),
  };
}

/**
 * Returns the numPixels object that represents the "unbound" parameter required to execute a fft table.
 * The backend expects an unbound parameter to be defined by the following JSON:
 * {unbound: true, name: 'numPixels', formula: chartWidth}
 *
 * @returns {Object} defining and unbound parameter called numPixels.
 */
export function getNumPixelParameter() {
  return {
    unbound: true,
    name: 'numPixels',
    formula: Math.min(getChartWidth(), getMaxSeriesPixels()),
  };
}
