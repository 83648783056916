import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Icon } from '@seeqdev/qomponents';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { doTrack } from '@/track/track.service';
import { setAdvancedParametersCollapsedState } from '@/toolSelection/investigate.actions';

export type ToolStore = {
  advancedParametersCollapsed: boolean;
};

interface AdvancedParametersProps {
  toolId: string;
  toolName: string;
  alternateName?: string;
  toolStore: ToolStore;
  children?: ReactNode;
}

export const AdvancedParameters: React.FunctionComponent<AdvancedParametersProps> = (props) => {
  const { toolName, toolId, toolStore, alternateName = undefined, children } = props;
  const { t } = useTranslation();

  const collapsed = useFluxPath(toolStore, () => toolStore.advancedParametersCollapsed);

  const trackAndClick = () => {
    doTrack(toolName, 'Advanced Toggle', `Advanced ${collapsed ? 'expanded' : 'collapsed'}`);
    setAdvancedParametersCollapsedState(toolId, !collapsed);
  };

  return (
    <AdvancedSection collapsed={collapsed} alternateName={alternateName} trackAndClick={trackAndClick} t={t}>
      {children}
    </AdvancedSection>
  );
};

interface AdvancedSectionProps {
  collapsed: boolean;
  alternateName?: string;
  trackAndClick?: React.MouseEventHandler<HTMLDivElement>;
  children?: React.ReactNode;
  t: (key: string) => string;
}

export const AdvancedSection: React.FunctionComponent<AdvancedSectionProps> = ({
  collapsed,
  alternateName,
  trackAndClick,
  children,
  t,
}) => {
  const [activeAccordion, setActiveAccordion] = React.useState<string>(collapsed ? '' : '0');
  return (
    <div id="advancedParameters">
      <Accordion
        value={activeAccordion}
        onItemSelect={(key) => setActiveAccordion(key)}
        accordionItems={[
          {
            value: '0',
            id: '0',
            trigger: (
              <div
                data-testid="panelControl"
                className="cursorPointer pt5 pb5 sq-text-primary textAlignLeft"
                onClick={trackAndClick}>
                <Icon icon={collapsed ? 'fa-plus' : 'fa-minus'} testId="advancedParametersIcon" />
                <a className="ml10 link-no-underline">{t(alternateName ? alternateName : 'ADVANCED')}</a>
              </div>
            ),
            content: (
              <>
                <div className="divider" />
                {children}
              </>
            ),
          },
        ]}
      />
    </div>
  );
};
