import _ from 'lodash';
import { BaseToolStore } from '@/toolSelection/baseTool.store';
import { BASE_TOOL_COMMON_PROPS } from '@/toolSelection/baseTool.constants';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { ItemPreviewWithAssetsV1 } from '@/sdk';

export class DisplayToolStore extends BaseToolStore {
  static readonly storeName = 'sqDisplayToolStore';
  type = TREND_TOOLS.DISPLAY;

  initialize() {
    this.state = this.immutable({
      ...BASE_TOOL_COMMON_PROPS,
      asset: undefined,
      isScaled: true,
    });
  }

  get asset(): ItemPreviewWithAssetsV1 | undefined {
    return this.state.get('asset');
  }

  get isScaled(): boolean {
    return this.state.get('isScaled');
  }

  dehydrate() {
    return this.state.serialize();
  }

  rehydrate(dehydratedState: any) {
    this.state.merge(dehydratedState);
  }

  setAsset(payload: { asset: ItemPreviewWithAssetsV1 | undefined }) {
    this.state.set('asset', payload.asset);
  }

  setIsScaled(payload: { isScaled: boolean }) {
    this.state.set('isScaled', payload.isScaled);
  }

  localHandlers = {
    DISPLAY_TOOL_ASSET: this.setAsset,
    DISPLAY_TOOL_IS_SCALED: this.setIsScaled,
  };

  handlers = _.assign({}, super.baseHandlers, this.localHandlers);
}
