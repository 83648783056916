import {
  getPasswordDigitRequired,
  getPasswordLowercaseRequired,
  getPasswordMinLength,
  getPasswordSpecialCharacterRequired,
  getPasswordUppercaseRequired,
} from '@/services/systemConfiguration.utilities';
import _ from 'lodash';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import i18next from 'i18next';

export function validPassword(password: string): boolean {
  return _.isEmpty(getPasswordErrors(password));
}

export function getPasswordErrorText(password: string): string {
  const errors = getPasswordErrors(password);
  return !_.isEmpty(errors) ? i18next.t('USER.PASSWORD_ERROR_PREFIX') + errors.join('; ') : '';
}

function getPasswordErrors(password: string): string[] {
  const errors: string[] = [];

  const passwordSpecialCharacters: string = SeeqNames.LocalDatasources.Authentication.PasswordSpecialCharacters;
  const passwordMinLength: number = getPasswordMinLength();
  const requireUppercase: boolean = getPasswordUppercaseRequired();
  const requireLowercase: boolean = getPasswordLowercaseRequired();
  const requireDigit: boolean = getPasswordDigitRequired();
  const requireSpecialCharacter: boolean = getPasswordSpecialCharacterRequired();

  // Check password length
  if (_.isNil(password) || password.length < passwordMinLength) {
    errors.push(i18next.t('USER.PASSWORD_ERROR_MIN_LENGTH', { min: passwordMinLength }));
  }

  // Check for required character types
  const containsUppercase = !_.isNil(password) && /[A-Z]/.test(password);
  const containsLowercase = !_.isNil(password) && /[a-z]/.test(password);
  const containsDigit = !_.isNil(password) && /[0-9]/.test(password);
  const containsSpecialCharacter = _.some(password, (char) => _.includes(passwordSpecialCharacters, char));

  if (requireUppercase && !containsUppercase) {
    errors.push(i18next.t('USER.PASSWORD_ERROR_UPPERCASE'));
  }

  if (requireLowercase && !containsLowercase) {
    errors.push(i18next.t('USER.PASSWORD_ERROR_LOWERCASE'));
  }

  if (requireDigit && !containsDigit) {
    errors.push(i18next.t('USER.PASSWORD_ERROR_DIGIT'));
  }

  if (requireSpecialCharacter && !containsSpecialCharacter) {
    errors.push(i18next.t('USER.PASSWORD_ERROR_SPECIAL_CHAR', { example: passwordSpecialCharacters }));
  }

  return errors;
}
