import _ from 'lodash';
import { setWorkbench } from '@/workbench/workbench.utilities';
import { setWorkBook } from '@/workbook/workbook.utilities';
import { SEARCH_TYPES } from '@/main/app.constants';
import { logError, logWarn } from '@/utilities/logger';
import { debounceAsync, isPresentationWorkbookMode, isViewOnlyWorkbookMode } from '@/utilities/utilities';
import { infoToast } from '@/utilities/toast.utilities';
import {
  FluxService,
  InitializeMode,
  PersistenceLevel,
  PUSH_IGNORE,
  PUSH_WORKBENCH,
  PUSH_WORKBOOK,
  PUSH_WORKSTEP_IMMEDIATE,
  PushOption,
  Store,
} from '@/core/flux.service';
import { generate, getViewFromWorkstep } from '@/utilities/screenshot.utilities';
import { sqWorkbenchStore, sqWorkbookStore, sqWorkstepsStore } from '@/core/core.stores';
import { getWorkstepAction, pushWorkstepAction } from '@/worksteps/worksteps.actions';
import { initializeSearchActions } from '@/search/search.actions';
import { resetRedactionService } from '@/utilities/redaction.utilities';
import { fetchAllItems } from '@/trendData/trend.actions';
import { WorkstepOutput } from '@/worksteps/worksteps.utilities';
import { AnyProperty, RequiredOmit } from '@/utilities.types';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { autoUpdate } from '@/trendData/duration.actions';
import { DEBOUNCE } from '@/core/core.constants';
import { sqItemsApi } from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { apply as upgradeWorkstep } from '@/worksteps/workstepUpgrader.utilities';
import { setView } from '@/worksheet/worksheet.actions';

/** Options interface to be passed to sqStateSynchronizer.rehydrate() */
export interface RehydrateOptions {
  /**
   * Specifies on what level (workbench, workbook, worksheet, or none) stores should be rehydrated
   */
  persistenceLevel?: PersistenceLevel;

  /**
   * Specifies whether to initialize all stores (FORCE) or just those that have been modified (SOFT)
   */
  initializeMode?: InitializeMode;

  /**
   * Optional predicate that return true for stores that should be rehydrated. If not given, all stores of the
   * specified initialize mode / persistence level will be rehydrated
   */
  storeFilter?: (storeInstance: Store, storeName: string) => boolean;

  /**
   * ID of the workbook from which this state originates If persistenceLevel is WORKBOOK or WORKSHEET this value is
   * required and setLoadingWorksheet() must be called first.
   */
  workbookId?: string;

  /**
   * ID of the worksheet from which this state originates If persistenceLevel is WORKBOOK or WORKSHEET this value is
   * required and setLoadingWorksheet() must be called first.
   */
  worksheetId?: string;

  /**
   * Optional hook to change the state before making requests.
   * For example changing the display range requires refreshing most data, so if the display range has been changed
   * via a url parameter it should be changed after the synchronous data is present in the store but before all the
   * data is requested from the backend.
   */
  beforeFetch?: () => Promise<any>;

  /**
   * Optional hook specifying how to fetch data after rehydrating. By default, all trend items will be fetched and the
   * main search pane will be initialized.
   */
  fetchData?: () => Promise<any>;
}

export type DehydratedState = {
  stores: AnyProperty;
};

export type WorkbookAndWorksheet = { workbookId: string; worksheetId: string };

/**
 * Syncs state from stores to a persistent state on the backend. For the most part this is workstep state that
 * results in a new workstep each time a flux.dispatch call changes state in any of the stores. This class also has
 * the corresponding responsibility of rehydrating that persisted state into the stores.
 *
 * The majority of the complexity in this class is because of trying to ensure that state does not leak between
 * worksheets in the form of a workstep being written to the wrong worksheet. Because the stores and this class are
 * singletons this is a real possibility if care is not taken. Two safeguards are in place to prevent this:
 * - WorkbenchWrapper.page.tsx will reload the page if any network request is outstanding or a rehydrate is in
 * progress. This is because async network calls could finish after the new worksheet is loaded and their data would
 * leak into the new worksheet.
 * - Once a worksheet is loaded, via rehydrate, all subsequent worksteps must be pushed to the same worksheet. If a
 * push is called with a different worksheet id, it is ignored. This guarantees that worksteps can only be written
 * to the worksheet that was initially loaded.
 *
 * Any changes to this class should entail running the workstep leak scenario in the Worksteps.feature test.
 */
export class StateSynchronizer {
  readonly #emptyState = { stores: {} };
  #flux!: FluxService;
  #currentPush?: Promise<void | WorkstepOutput>;
  #deferredPush?: () => Promise<void | WorkstepOutput>;
  #currentWorkbenchState: DehydratedState = this.#emptyState;
  #currentWorkbookState: DehydratedState = this.#emptyState;
  #currentWorksheetState: DehydratedState = this.#emptyState;
  #currentWorksheetOrigin?: WorkbookAndWorksheet;
  #isRehydrating = false;
  #isPushDisabled = false;
  #debouncedWorksheetPush = _.debounce(this.pushWorksheetState, DEBOUNCE.WORKSTEP);
  #debouncedLoadWorkstep = debounceAsync(
    (
      workbookId: string,
      worksheetId: string,
      workstepId: string,
      previous: string,
      next: string,
      last: string,
      workstepData: { state: DehydratedState },
    ): Promise<void> => {
      // Fire and forgot to load the current workstep
      this.#flux.dispatch('WORKSTEPS_SET', { previous, current: { id: workstepId, ...workstepData }, next, last });

      // Rehydrate stores with the workstep data
      return this.rehydrate(workstepData.state, {
        persistenceLevel: 'WORKSHEET',
        initializeMode: 'SOFT',
        workbookId,
        worksheetId,
      });
    },
  );

  constructor(flux: FluxService) {
    this.#flux = flux;
  }

  get isRehydrating(): boolean {
    return this.#isRehydrating;
  }

  async withPushDisabled<T>(callback: () => Promise<T>): Promise<T> {
    try {
      this.#isPushDisabled = true;
      return await callback();
    } finally {
      this.#isPushDisabled = false;
    }
  }

  private isWorkstepPushDisabled(workbookId: string, worksheetId: string): boolean {
    return (
      this.#isPushDisabled ||
      this.isRehydrating ||
      _.isNil(this.#currentWorksheetOrigin) ||
      this.#currentWorksheetOrigin.workbookId !== workbookId ||
      this.#currentWorksheetOrigin.worksheetId !== worksheetId
    );
  }

  public async loadWorkstepStores(
    workstepId: string,
    storesToHydrate: string[],
    workbookId: string,
    worksheetId: string,
    errorMessage: string,
    fetchDataCallback: () => Promise<any> = () => Promise.resolve(),
    swap: () => Promise<any> = () => Promise.resolve(),
  ) {
    const workstepData = await sqItemsApi.getProperty({
      id: workstepId,
      propertyName: SeeqNames.Properties.Data,
    });
    const parsedData = _.attempt(JSON.parse, workstepData.data.value);
    if (_.isError(parsedData)) {
      logError(`${errorMessage} ${parsedData}`);
      throw new Error(errorMessage);
    }
    const state = await upgradeWorkstep(parsedData.state, parsedData.version);

    return this.rehydrate(state, {
      persistenceLevel: 'WORKSHEET',
      initializeMode: 'FORCE',
      storeFilter: (storeInstance, storeName) => _.includes(storesToHydrate, storeName),
      workbookId,
      worksheetId,
      beforeFetch: () => {
        setView(state.stores.sqWorksheetStore.viewKey, false);
        return swap();
      },
      fetchData: () => fetchDataCallback(),
    });
  }

  /**
   * Invokes the correct push method based on the specified pushMode.
   *
   * @param [pushMode] - One of the PUSH constants. If not specified it defaults to debounced worksheet push
   * @param [pushOptions] - Additional push options
   * @returns If push immediate then resolves when the push is complete otherwise resolves immediately
   */
  async push(pushMode?: PushOption, pushOptions?: Partial<WorkbookAndWorksheet>): Promise<any> {
    const options: Required<WorkbookAndWorksheet> = _.defaults(pushOptions || {}, {
      workbookId: sqWorkbenchStore.stateParams.workbookId,
      worksheetId: sqWorkbenchStore.stateParams.worksheetId,
    });

    if (pushMode === PUSH_IGNORE || headlessRenderMode() || isViewOnlyWorkbookMode() || isPresentationWorkbookMode()) {
      return;
    }

    if (pushMode === PUSH_WORKBENCH) {
      return this.saveWorkbenchState();
    }
    if (options.workbookId && sqWorkbookStore.workbookId === options.workbookId && pushMode === PUSH_WORKBOOK) {
      return this.saveWorkbookState(options.workbookId); // Only save workbook state if we're in the same workbook
    }
    if (
      options.workbookId &&
      options.worksheetId &&
      !this.isWorkstepPushDisabled(options.workbookId, options.worksheetId)
    ) {
      if (pushMode === PUSH_WORKSTEP_IMMEDIATE) {
        if (_.isFunction(this.#debouncedWorksheetPush.cancel)) {
          this.#debouncedWorksheetPush.cancel();
        }
        return this.pushWorksheetState(options.workbookId, options.worksheetId);
      } else {
        this.#debouncedWorksheetPush(options.workbookId, options.worksheetId);
      }
    }
  }

  /**
   * Persists workbench state
   */
  private saveWorkbenchState() {
    let newWorkbenchState;
    const newState = this.#flux.dispatcher.dehydrate();

    newWorkbenchState = this.filterStoresWithPersistenceLevel(newState, 'WORKBENCH');
    if (_.isEqual(JSON.stringify(this.#currentWorkbenchState), JSON.stringify(newWorkbenchState))) {
      return Promise.resolve();
    }
    this.#currentWorkbenchState = newWorkbenchState;
    return setWorkbench(newWorkbenchState);
  }

  /**
   * Persists workbook state
   */
  private saveWorkbookState(id: string) {
    let newWorkbookState;
    const newState = this.#flux.dispatcher.dehydrate();

    newWorkbookState = this.filterStoresWithPersistenceLevel(newState, 'WORKBOOK');
    if (!_.isEqual(JSON.stringify(this.#currentWorkbookState), JSON.stringify(newWorkbookState))) {
      this.#currentWorkbookState = newWorkbookState;
      setWorkBook(id, newWorkbookState);
    }
  }

  /**
   * Persists the current worksheet state by pushing as a workstep
   *
   * @param workbookId - The workbook id to push.
   * @param worksheetId - The worksheet id to push.
   * @return Promise if it pushes, undefined if not
   */
  private pushWorksheetState(workbookId: string, worksheetId: string): Promise<any> | undefined {
    if (this.isWorkstepPushDisabled(workbookId, worksheetId)) {
      return;
    }

    if (this.#currentPush) {
      this.#deferredPush = () =>
        this.push(PUSH_WORKSTEP_IMMEDIATE, {
          workbookId,
          worksheetId,
        });
      return this.#currentPush;
    }

    const newState = this.#flux.dispatcher.dehydrate();
    const newWorksheetState = this.filterStoresWithPersistenceLevel(newState, 'WORKSHEET');

    if (!_.isEqual(JSON.stringify(this.#currentWorksheetState), JSON.stringify(newWorksheetState))) {
      this.#currentPush = Promise.resolve();

      // If we have a next workstep, that means we're currently on a previous workstep, so we need to push
      // the state of that workstep before pushing the new state. This provides a nice transition when going
      // backwards through the workstep history. (e.g. If the history is 1 2 3 and users goes back to 2 and
      // then new step 4 is added, the history will now be 1 2 3 2 4)
      if (sqWorkstepsStore.next) {
        this.#currentPush = this.#currentPush.then(() =>
          pushWorkstepAction(workbookId, worksheetId, this.#currentWorksheetState)
            // Even if it fails the current workstep should be pushed
            .catch(_.noop),
        );
      }

      // Ensure the current workstep is pushed after the previous one (if there is a previous workstep)
      this.#currentPush = this.#currentPush
        .then(() => pushWorkstepAction(workbookId, worksheetId, newWorksheetState))
        .then(() => {
          this.#currentWorksheetState = newWorksheetState;
          const viewKey = sqWorkbookStore?.isReportBinder
            ? 'TOPIC'
            : getViewFromWorkstep({ current: { state: newWorksheetState } });
          generate({ workbookId, worksheetId, defer: true, viewKey, workstepId: sqWorkstepsStore.current.id });
        })
        // Do not want to fail if the push fails for some reason (usually cancellation)
        .catch(_.noop)
        .finally(() => {
          this.#currentPush = undefined;
          if (this.#deferredPush) {
            const response = this.#deferredPush();
            this.#deferredPush = undefined;
            return response;
          }
        });
    }

    return this.#currentPush;
  }

  private filterStoresWithPersistenceLevel(newState: DehydratedState, persistenceLevel: PersistenceLevel) {
    // Get an array containing all the store names that apply at the requested persistence level
    const storeNames = _.chain(this.#flux.dispatcher.storeInstances)
      .pickBy((store, storeName) => {
        if (!store.persistenceLevel) {
          throw new Error(`${storeName} has no PersistenceLevel`);
        }

        return store.persistenceLevel === persistenceLevel;
      })
      .keys()
      .value();

    // Return an object containing only the applicable stores for the requested persistence level
    return {
      stores: _.pickBy(newState.stores, (value, key) => {
        return _.includes(storeNames, key);
      }),
    };
  }

  /**
   * Handle workstep messages received over websocket for the current worksheet which is what enables fast-follow
   * (where updates from another user are reflected for the current user). Several guards are in place to ensure that
   * worksteps never get applied to the wrong worksheet or at the wrong time:
   * - The worksheet is presentation-mode in Analysis, so fast follow does not apply.
   * - The current worksheet does not match the workstep's worksheet, which could indicate a workstep channel was
   * not properly closed or a race condition (CRAB-18940).
   *
   * @param data An object describing a workstep
   */
  onWorkstep(data: {
    workbookId: string;
    worksheetId: string;
    workstepId: string;
    previousWorkstepId: string;
    nextWorkstepId: string;
    lastWorkstepId: string;
    workstepData: string;
  }) {
    const workstepData = _.attempt(JSON.parse, data.workstepData);
    if (
      (!sqWorkbookStore.isReportBinder && isPresentationWorkbookMode()) ||
      data.workbookId !== sqWorkbenchStore.stateParams.workbookId ||
      data.worksheetId !== sqWorkbenchStore.stateParams.worksheetId
    ) {
      return;
    }

    if (isViewOnlyWorkbookMode()) {
      infoToast({
        messageKey: 'RELOAD_MESSAGE',
        buttonLabelKey: 'RELOAD',
        buttonAction: () => window.location.reload(),
      });
    } else {
      this.#debouncedLoadWorkstep(
        data.workbookId,
        data.worksheetId,
        data.workstepId,
        data.previousWorkstepId,
        data.nextWorkstepId,
        data.lastWorkstepId,
        workstepData,
      );
    }
  }

  /**
   * Initializes all stores and rehydrates their previous state if present.
   *
   * Stores can specify an array of other stores which must first rehydrate by adding the `rehydrateWaitFor`
   * property. Note that rehydrateWaitFor can not be used to wait for a store that is not part of its
   * persistenceLevel. Also note that while it supports a chain of dependencies (storeA -> storeB -> storeC), there is
   * no circular dependency checking, but you'll figure that out soon enough if you create one :)
   *
   * @param [dehydratedState] - An object with a `stores` property. Usually the result of the
   *   `dispatcher.dehydrate` method.
   * @param [rehydrateOptions] - Additional options for rehydrate
   * @returns A promise that is resolved when all the rehydrate stores finish rehydrating.
   */
  rehydrate(dehydratedState: DehydratedState, rehydrateOptions?: RehydrateOptions): Promise<any> {
    const options: RequiredOmit<RehydrateOptions, 'workbookId' | 'worksheetId'> = _.defaults(rehydrateOptions, {
      persistenceLevel: 'WORKSHEET',
      initializeMode: 'FORCE',
      beforeFetch: _.noop,
      storeFilter: _.constant(true),
      fetchData: this.fetchRehydrateData,
    });

    const areParametersGuarded = _.includes(['WORKBOOK', 'WORKSHEET'], options.persistenceLevel);

    if (areParametersGuarded && !(options.workbookId && options.worksheetId)) {
      return Promise.reject('workbookId and worksheetId are required when rehydrating workbooks or worksheets');
    }

    // If state is mismatched or it is still rehydrating, likely because of race conditions that occur when
    // transitioning between worksheets while another rehydrate is still going, then it is not safe to proceed because
    // the worksheet data will be overwritten. Since the existing promise can't be interrupted the safest thing is
    // to reload the page with the specified worksheet. There is similar logic in WorkbenchWrapper.page.tsx.
    if (areParametersGuarded && this.isRehydrating) {
      logWarn(`Preventing rehydrate and reloading worksheet because rehydrate already in progress`);
      window.location.assign(`/workbook/${options.workbookId}/worksheet/${options.worksheetId}`);
      return Promise.resolve();
    }

    this.rehydrateSynchronous(dehydratedState, options);

    if (options.persistenceLevel !== 'WORKSHEET') {
      return Promise.resolve();
    }

    // While this dynamic data is coming in we don't want extra worksteps created which is why isRehydrating is not
    // set to false until after it finishes.
    this.#isRehydrating = true;
    return Promise.resolve()
      .then(options.beforeFetch)
      .then(options.fetchData)
      .finally(() => {
        this.#isRehydrating = false;
      });
  }

  /**
   * Internal method for the rehydrate method
   *
   * @see rehydrate
   */
  private rehydrateSynchronous(dehydratedState: DehydratedState | undefined, options: RehydrateOptions) {
    const rehydrateCalled = {} as Record<string, boolean>;

    // Reset redaction monitor before we start rehydration so we can recognise if any items on the worksheet failed to
    // load during rehydration because of insufficient permissions.
    if (options.persistenceLevel === 'WORKSHEET') {
      resetRedactionService();
    }

    const storeInstances = this.getStoresToRehydrate(dehydratedState, options);

    _.chain(storeInstances).values().filter('initialize').invokeMap('initialize', options.initializeMode).value();

    const setState = (state: DehydratedState) => {
      if (options.persistenceLevel === 'WORKBENCH') {
        this.#currentWorkbenchState = state;
      } else if (options.persistenceLevel === 'WORKBOOK') {
        this.#currentWorkbookState = state;
      } else if (options.persistenceLevel === 'WORKSHEET') {
        this.#currentWorksheetState = state;
        // This is the only place where the current worksheet origin is set. At this point all relevant state
        // is known and accurate: the worksheet the workstep came from, the state in the stores, and the
        // #currentWorksheetState. New worksteps can only be pushed to this worksheet.
        if (options.workbookId && options.worksheetId) {
          this.#currentWorksheetOrigin = { workbookId: options.workbookId!, worksheetId: options.worksheetId! };
        } else {
          this.#currentWorksheetOrigin = undefined;
        }
      }
    };

    // If there is no dehydratedState then it is the special case where internal state is being reset
    if (_.isUndefined(dehydratedState)) {
      setState(this.#emptyState);
    } else {
      _.forEach(storeInstances, function callRehydrate(store: Store, name: string) {
        if (rehydrateCalled[name]) {
          return;
        }

        if (store.rehydrateWaitFor) {
          _.forEach(store.rehydrateWaitFor, (dependencyName) => {
            if (storeInstances[dependencyName]) {
              callRehydrate(storeInstances[dependencyName], dependencyName);
            }
          });
        }

        if (dehydratedState.stores && dehydratedState.stores[name]) {
          store.rehydrate(dehydratedState.stores[name]);
        }

        rehydrateCalled[name] = true;
      });

      setState(this.filterStoresWithPersistenceLevel(this.#flux.dispatcher.dehydrate(), options.persistenceLevel!));
    }
  }

  /**
   * Gets stores that need to be rehydrated given a dehydratedState. Stores that have differing state from the
   * dehydrated state are included in the output while stores that have matching state are not included. If
   * options.initializeMode is FORCE, then all stores of the appropriate persistence level are included.
   *
   * @param [dehydratedState] the dehydrated state used to determine what stores to rehydrate
   * @param options - The rehydrate options
   * @return An array of the stores that need to be rehydrated
   */
  private getStoresToRehydrate(
    dehydratedState: DehydratedState | undefined,
    options: RehydrateOptions,
  ): Record<string, Store> {
    const changedStores: Record<string, boolean> = {};

    const isStoreAllowedForRehydrate = _.defaultTo(options.storeFilter, _.constant(true));

    // Filter by persistence level and storeFilter
    let storeInstances = _.pickBy(
      this.#flux.dispatcher.storeInstances,
      (instance, storeName) =>
        instance.persistenceLevel === options.persistenceLevel && isStoreAllowedForRehydrate(instance, storeName),
    ) as unknown as Record<string, Store>;

    /*
     * If we have dehydrated state, then filter so we only rehydrate those stores that have actually changed. However,
     * if we are forcing initialization then we want all the stores to be reinitialized
     */
    if (dehydratedState && options.initializeMode !== 'FORCE') {
      // Determine which stores have changed
      const currentState = this.#flux.dispatcher.dehydrate();
      _.forEach(storeInstances, (store, key) => {
        changedStores[key] =
          dehydratedState.stores &&
          dehydratedState.stores[key] &&
          JSON.stringify(dehydratedState.stores[key]) !== JSON.stringify(currentState.stores[key]);
      });

      // Filter so only changed stores and stores that depend on changed stores are rehydrated
      storeInstances = _.pickBy(
        storeInstances,
        _.rearg(function hasStoreChanged(storeName): boolean {
          return changedStores[storeName] || _.some(storeInstances[storeName]!.rehydrateWaitFor, hasStoreChanged);
        }, 1),
      ) as unknown as Record<string, Store>;
    }

    return storeInstances;
  }

  /**
   * Gets the workstep, rehydrates it and generates a thumbnail for it.
   *
   * @param getWorkstep - a promise that resolves to a WorkstepOutput
   * @return a promise that resolves when the workstep has been rehydrated
   * */
  async getWorkstepAndRehydrate(getWorkstep: () => Promise<WorkstepOutput | void>) {
    const workbookId = sqWorkbenchStore.stateParams.workbookId;
    const worksheetId = sqWorkbenchStore.stateParams.worksheetId;
    const workstep = await getWorkstep();
    await this.rehydrate(_.get(workstep, 'current.state'), { workbookId, worksheetId });
    const viewKey = getViewFromWorkstep(workstep);
    generate({
      workbookId,
      worksheetId,
      workstepId: sqWorkstepsStore.current.id,
      defer: true,
      viewKey,
    });
  }

  /**
   * Initialize all the states at the provided persistenceLevel using the initializeMode.
   *
   * @param {PersistenceLevel} persistenceLevel - the group of stores to initialize
   * @param {WorkbookAndWorksheet} workbookAndWorksheet - the workbook and worksheet id if state is being
   * initialized on a new worksheet
   */
  initialize(persistenceLevel: PersistenceLevel, workbookAndWorksheet?: WorkbookAndWorksheet) {
    return this.rehydrateSynchronous(undefined, {
      ...(workbookAndWorksheet ? workbookAndWorksheet : {}),
      persistenceLevel,
      initializeMode: 'FORCE',
    });
  }

  /**
   * Fetch all items for the details pane and the search pane
   *
   * @return {Promise} resolves when all of the items have been fetched.
   */
  private fetchRehydrateData(): Promise<[any[], any]> {
    autoUpdate.initialize(); // Done before rehydrate to prevent double-fetch (CRAB-36878)
    return Promise.all([
      fetchAllItems(),
      initializeSearchActions('main', SEARCH_TYPES, false, [sqWorkbenchStore.stateParams.workbookId]),
    ]);
  }
}
