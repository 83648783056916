import React from 'react';
import { Ace } from '@/accessControl/itemAclModal.utilities';
import { IdentityPopover } from '@/accessControl/IdentityPopover.molecule';

interface AcePopoverProps {
  ace: Ace;
}

export const AcePopover: React.FunctionComponent<AcePopoverProps> = ({ ace }) => {
  return ace.identity ? <IdentityPopover identity={ace.identity} /> : null;
};
