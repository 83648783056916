import { TableColumnFilter } from '@/core/tableUtilities/tables';
import { TABLE_FILTERS } from '@/toolSelection/investigate.constants';
import { ValueWithUnitsItem } from '@/trend/ValueWithUnits.atom';
import { logError } from '@/utilities/logger';
import { formatMessage } from '@/utilities/logger.utilities';
import { convertInputStringToRegex } from '@/utilities/stringHelper.utilities';
import _ from 'lodash';
import {
  ColumnOrRowWithDefinitions,
  ColumnWithIndex,
  ConditionTableCapsule,
  ItemColumnsMap,
  SimpleTableRow,
  TableBuilderSort,
  TableCell,
} from '@/tableBuilder/tableBuilder.types';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { AnyProperty } from '@/utilities.types';
import {
  CONDITION_METRIC_COLUMNS,
  MetricPropertyColumn,
  TableBuilderColumnType,
  TableBuilderMode,
} from '@/tableBuilder/tableBuilder.constants';
import { DEFAULT_CONDITION_TABLE_SORT, StatColumn, TableSortParams } from '@/utilities/formula.constants';
import { COLUMNS_AND_STATS, ITEM_TYPES, PropertyColumn, StatisticColumn } from '@/trendData/trendData.constants';
import { isPropertyColumnType } from '@/utilities/tableBuilderHelper.utilities';

const DURATION_TO_MILLISECONDS = { s: 1, min: 60, h: 3600, day: 86400 } as const;
const durationToMilliSeconds = (duration: ValueWithUnitsItem) =>
  duration.value * DURATION_TO_MILLISECONDS[duration.units as keyof typeof DURATION_TO_MILLISECONDS];

type TableRow<Mode extends TableBuilderMode> = Mode extends TableBuilderMode.Simple
  ? SimpleTableRow
  : ConditionTableCapsule;

/**
 * Utility class for processing table data based on its columns.
 */
export class TableBuilder {
  /**
   * Finds the index of a column
   * @param columns
   * @param key - Column key
   * @param isNotFoundAllowed - If true, does not throw if the column does not exist
   *
   * @returns the index of the column. If the column is not found, it returns -1
   */
  getColumnIndex(columns: ColumnOrRowWithDefinitions[], key: string, isNotFoundAllowed = false): number {
    const index = columns.findIndex((column) => column.key === key);
    if (!isNotFoundAllowed && index === -1) {
      throw new TypeError(`Column key '${key}' does not exist`);
    }

    return index;
  }

  /**
   * Retrieves the columns that contain a filter and/or sort with their corresponding indexes.
   *
   * @param columns - The array of columns to retrieve indexes for.
   *
   * @returns An array of columns with their corresponding indexes.
   */
  getColumnsWithIndex(columns: ColumnOrRowWithDefinitions[]): ColumnWithIndex[] {
    return columns
      .filter((column) => !!column.filter || !!column.sort)
      .map((column) => ({ column, columnIndex: this.getColumnIndex(columns, column.key) }));
  }

  /**
   * Determines whether a given cell value passes the specified table column filter criteria.
   *
   * @param filterParams - The filter parameters, including operator, values, and selection method.
   * @param rawValue - The raw value to be filtered.
   * @param formattedValue - The formatted value used for matching in certain cases.
   * @param isDurationColumn - Indicates whether the column represents duration values.
   *
   * @returns `true` if the value passes the filter; otherwise, `false`.
   */
  filterPasses(
    filterParams: TableColumnFilter,
    rawValue: number | string | undefined | null,
    formattedValue: string | undefined,
    isDurationColumn = false,
  ): boolean {
    const { operator, values, usingSelectedValues } = filterParams;
    const firstFilterValue = isDurationColumn ? durationToMilliSeconds(values[0] as ValueWithUnitsItem) : values[0];
    const secondFilterValue =
      isDurationColumn && !_.isNil(values[1]) ? durationToMilliSeconds(values[1] as ValueWithUnitsItem) : values[1];

    try {
      if (operator === TABLE_FILTERS.IS_MATCH) {
        return usingSelectedValues
          ? values.includes(formattedValue)
          : !!formattedValue?.match(convertInputStringToRegex(String(firstFilterValue)));
      }

      if (operator === TABLE_FILTERS.IS_NOT_MATCH) {
        return usingSelectedValues
          ? !values.includes(formattedValue)
          : !formattedValue?.match(convertInputStringToRegex(String(firstFilterValue)));
      }
    } catch (e) {
      logError(formatMessage`Error while filtering table: ${e}`);

      return false;
    }

    if (operator === TABLE_FILTERS.IS_EQUAL_TO) {
      return rawValue === firstFilterValue;
    }

    if (operator === TABLE_FILTERS.IS_NOT_EQUAL_TO) {
      return rawValue !== firstFilterValue;
    }

    if (_.isNil(rawValue) || _.isNil(firstFilterValue)) {
      return false;
    }

    if (operator === TABLE_FILTERS.IS_LESS_THAN) {
      return rawValue < firstFilterValue;
    }

    if (operator === TABLE_FILTERS.IS_LESS_THAN_OR_EQUAL_TO) {
      return rawValue <= firstFilterValue;
    }

    if (operator === TABLE_FILTERS.IS_GREATER_THAN) {
      return rawValue > firstFilterValue;
    }

    if (operator === TABLE_FILTERS.IS_GREATER_THAN_OR_EQUAL_TO) {
      return rawValue >= firstFilterValue;
    }

    if (_.isNil(secondFilterValue)) {
      return false;
    }

    if (operator === TABLE_FILTERS.IS_BETWEEN) {
      return firstFilterValue < rawValue && rawValue < secondFilterValue;
    }

    if (operator === TABLE_FILTERS.IS_NOT_BETWEEN) {
      return !(firstFilterValue < rawValue && rawValue < secondFilterValue);
    }

    return false;
  }

  /**
   * Filters the table data based on the specified mode, column filters, and raw table data.
   *
   * @param mode - The table builder mode.
   * @param columnsWithIndex - The columns with filter/sort and their corresponding indices.
   * @param rawTableData - The raw table data.
   *
   * @returns - The filtered table data.
   */
  filterTableData<Mode extends TableBuilderMode>(
    mode: Mode,
    columnsWithIndex: ColumnWithIndex[],
    rawTableData: TableRow<Mode>[],
  ): TableRow<Mode>[] {
    const columnsWithFilter = columnsWithIndex.filter(({ column }) => column.filter);
    if (columnsWithFilter.length === 0) {
      return rawTableData;
    }

    const cellKey = mode === TableBuilderMode.Simple ? 'cells' : 'values';

    return rawTableData.filter((row) => {
      return !columnsWithFilter.some(({ column, columnIndex }) => {
        const filter = column.filter!;
        const isDurationColumn = column?.key === SeeqNames.Properties.Duration;

        const cell: TableCell = (row as AnyProperty)[cellKey][columnIndex];
        if (!cell) {
          return true;
        }

        const { value, rawValue } = cell;

        return !this.filterPasses(filter, rawValue, value?.toString(), isDurationColumn);
      });
    });
  }

  /**
   * Retrieves the sort parameters for simple table based on the provided columns.
   *
   * @param columns - An array of columns definitions.
   *
   * @returns The table sort parameters or undefined if no sort columns are found.
   */
  getSimpleTableSortParams(columns: ColumnOrRowWithDefinitions[]): TableSortParams | undefined {
    return _.chain(columns)
      .reject({ type: TableBuilderColumnType.Text })
      .filter('sort')
      .sortBy('sort.level')
      .thru((sortColumns) => {
        if (sortColumns.length === 0) {
          return;
        }

        const isAsc = ({ direction }: TableBuilderSort) => direction === 'asc';
        const primarySort = sortColumns.shift()!;

        return {
          sortBy: primarySort.key,
          sortAsc: isAsc(primarySort.sort!),
          orderedAdditionalSortPairs: sortColumns.map((sortColumn) => ({
            sortBy: sortColumn.key,
            sortAsc: isAsc(sortColumn.sort!),
          })),
        };
      })
      .value();
  }

  /**
   * Processes the simple table data by filtering and sorting it based on the provided columns. Note: this
   * only sorts the data if the table is transposed. Ag-Grid handles sorting when it is not transposed.
   *
   * @param data - The array of simple table rows.
   * @param columns - The array of columns definitions.
   * @param columnsWithIndex - Optional. The array of columns with index.
   *
   * @returns The filtered/sorted simple table data.
   */
  processSimpleTableData(
    data: SimpleTableRow[],
    columns: ColumnOrRowWithDefinitions[],
    columnsWithIndex?: ColumnWithIndex[],
  ): SimpleTableRow[] {
    const filteredData = this.filterTableData(
      TableBuilderMode.Simple,
      columnsWithIndex ?? this.getColumnsWithIndex(columns),
      data,
    );

    return this.sortSimpleTableData(filteredData, columns);
  }

  /**
   * Sorts the table data based on the provided columns.
   *
   * @param tableData The array of table rows to be sorted.
   * @param columns The array of columns with their definitions.
   *
   * @returns The sorted array of table rows.
   */
  sortSimpleTableData(tableData: SimpleTableRow[], columns: ColumnOrRowWithDefinitions[]): SimpleTableRow[] {
    const sortParams = this.getSimpleTableSortParams(columns);
    if (!sortParams) {
      return tableData;
    }

    const valueGetter =
      (sortBy = sortParams.sortBy) =>
      (data: SimpleTableRow) => {
        const columnIndex = this.getColumnIndex(columns, sortBy);

        return data.cells[columnIndex]?.rawValue ?? null;
      };

    const sortFields = [
      {
        valueGetter: valueGetter(),
        order: sortParams.sortAsc ? 'asc' : 'desc',
      },
    ].concat(
      sortParams.orderedAdditionalSortPairs?.map((sortParam) => ({
        valueGetter: valueGetter(sortParam.sortBy),
        order: sortParam.sortAsc ? 'asc' : 'desc',
      })) ?? [],
    );

    return _.orderBy(
      tableData,
      sortFields.map((sortFields) => sortFields.valueGetter),
      sortFields.map((sortFields) => sortFields.order),
    );
  }

  /**
   * Processes the condition table data by filtering and sorting it based on the provided parameters. Note: this
   * only sorts the data if the table is not transposed. Ag-Grid handles sorting when it is transposed.
   *
   * @returns The processed condition table data.
   */
  processConditionTableData({
    data,
    columnsWithIndex,
    isTransposed,
    conditionTableRows,
    tableItems,
  }: {
    /** The original condition table data. */
    data: ConditionTableCapsule[];
    /** whether the table is transposed */
    isTransposed: boolean;
    /** The columns of the condition table with their corresponding indices */
    conditionTableRows: ColumnOrRowWithDefinitions[];
    /** The definitions of the condition table rows */
    columnsWithIndex: ColumnWithIndex[];
    /** The table items derived from all stores */
    tableItems: any[];
  }): ConditionTableCapsule[] {
    const filteredData = this.filterTableData(TableBuilderMode.Condition, columnsWithIndex, data);

    return isTransposed
      ? filteredData
      : this.sortConditionTableData({ data: filteredData, conditionTableRows, tableItems });
  }

  /**
   * Sorts the condition table data based on columns and table items.
   *
   * @returns The sorted array of ConditionTableCapsule objects.
   */
  sortConditionTableData({
    data,
    conditionTableRows,
    tableItems,
  }: {
    /** The array of ConditionTableCapsule objects to be sorted. */
    data: ConditionTableCapsule[];
    /** The array of ColumnOrRowWithDefinitions representing the condition table rows. */
    conditionTableRows: ColumnOrRowWithDefinitions[];
    /** The array of any type representing the table items. */
    tableItems: any[];
  }): ConditionTableCapsule[] {
    const sortParams = this.getConditionTableSortParams({ conditionTableRows, tableItems });

    const valueGetter =
      (sortBy = sortParams.sortBy) =>
      (data: ConditionTableCapsule) => {
        if (sortBy === 'startTime') {
          return data.startTime ?? -1;
        }

        const columnIndex = this.getColumnIndex(conditionTableRows, sortBy, true);
        const value = data.values[columnIndex]?.rawValue ?? null;

        return value;
      };

    const sortFields = [{ valueGetter: valueGetter(), order: sortParams.sortAsc ? 'asc' : 'desc' }].concat(
      sortParams.orderedAdditionalSortPairs?.map((sortParam) => ({
        valueGetter: valueGetter(sortParam.sortBy),
        order: sortParam.sortAsc ? 'asc' : 'desc',
      })) ?? [],
    );

    return _.orderBy(
      data,
      sortFields.map((sortFields) => sortFields.valueGetter),
      sortFields.map((sortFields) => sortFields.order),
    );
  }

  /**
   * Condition-mode columns that generate a statistic for a signal over a capsule
   */
  getStatColumns(
    columns: ColumnOrRowWithDefinitions[],
    itemFinder?: (id: string) => any,
  ): (StatColumn & StatisticColumn)[] {
    return _.chain(columns)
      .filter('signalId')
      .reject((column) => _.isUndefined(itemFinder ? itemFinder(column.signalId ?? '') : false))
      .value() as (StatColumn & StatisticColumn)[];
  }

  /**
   * Custom item/capsule properties used in the table.
   */
  getPropertyColumns(columns: ColumnOrRowWithDefinitions[]): PropertyColumn[] {
    return _.chain(columns)
      .filter((column) => isPropertyColumnType(column))
      .map((column) => ({
        key: column.key,
        propertyName: column.key,
        shortTitle: column.key,
        style: 'string',
        filter: column.filter,
        sort: column.sort,
      }))
      .value();
  }

  /**
   * Given a list of items, find the metrics, and return a map of metric id to an object of column definitions.
   * @param metrics The metrics used to build the condition table
   */
  metricsToItemColumnsMap(metrics: any[]): ItemColumnsMap {
    return _.transform(
      metrics,
      (memo, { id }) => {
        memo[id] = _.transform(
          CONDITION_METRIC_COLUMNS,
          (columnMap, column) => {
            columnMap[column.key] = {
              ...column,
              propertyName: `${id}_${column.key}`,
              key: `${id}_${column.key}`,
            };
          },
          {} as Record<string, MetricPropertyColumn>,
        );
      },
      {} as ItemColumnsMap,
    );
  }

  /**
   * @returns An object containing the sort criteria used for sorting the condition table data
   */
  getConditionTableSortParams({
    conditionTableRows: columns,
    tableItems: items,
  }: {
    conditionTableRows: ColumnOrRowWithDefinitions[];
    tableItems: any[];
  }): TableSortParams {
    const statColumns = _.sortBy(this.getStatColumns(columns), 'signalId');
    const metrics: any[] = _.filter(items, { itemType: ITEM_TYPES.METRIC });
    const itemColumnsMap = this.metricsToItemColumnsMap(metrics);
    const itemColumns: MetricPropertyColumn[] = _.chain(itemColumnsMap).values().flatMap(_.values).value();
    const allColumns = columns;
    const metricColumns = columns.filter((row) => row.metricId);
    const propertyColumns = (itemColumns as any[])
      .concat(_.find(allColumns, { key: COLUMNS_AND_STATS.startTime.key }) || COLUMNS_AND_STATS.startTime)
      .concat(_.find(allColumns, { key: COLUMNS_AND_STATS.endTime.key }) || COLUMNS_AND_STATS.endTime)
      .concat(this.getPropertyColumns(columns))
      .concat(_.filter(allColumns, 'propertyExpression'));

    const isAsc = ({ direction }: TableBuilderSort) => direction === 'asc';

    return _.chain(statColumns as any[])
      .concat(propertyColumns)
      .concat(
        metricColumns.map((column) => ({
          sort: column.sort,
          key: column.metricId,
        })),
      )
      .filter('sort')
      .sortBy('sort.level')
      .thru((sortColumns) => {
        if (_.isEmpty(sortColumns)) {
          return DEFAULT_CONDITION_TABLE_SORT();
        }

        const primarySort = sortColumns.shift();

        return {
          sortBy: primarySort.key,
          sortAsc: isAsc(primarySort.sort),
          isCustomColumn: primarySort.key === COLUMNS_AND_STATS.asset.key,
          orderedAdditionalSortPairs: _.map(sortColumns, (sortColumn) => ({
            sortBy: sortColumn.key,
            sortAsc: isAsc(sortColumn.sort),
          })),
        };
      })
      .value();
  }

  /**
   * Returns the maximum sort level for the table. Zero if no sort criteria was set by the user.
   *
   * _It can be used only in simple mode._
   */
  getMaxSortLevel(columns: ColumnOrRowWithDefinitions[]): number {
    return _.chain(columns).maxBy('sort.level').value()?.sort?.level ?? 0;
  }
}

export const tableBuilder = new TableBuilder();
