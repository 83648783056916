import { useIntervalWhen } from 'rooks';
import { MutableRefObject, useEffect, useState } from 'react';

/**
 * When enabled, this hook monitors the location of an element and calls back when it changes.
 *
 * @param ref - a React element reference
 * @param callback - called when enable is true and the element moves
 * @param enable - true to enable location monitoring
 */
export function useElementHasMoved(
  ref: MutableRefObject<HTMLElement | null>,
  callback: () => void,
  enable: boolean,
): void {
  const [top, setTop] = useState<number>();
  const [left, setLeft] = useState<number>();
  const getRect = () => {
    const rect = ref?.current?.getBoundingClientRect();
    return { top: rect?.top, left: rect?.left };
  };

  useEffect(() => {
    const rect = getRect();
    setTop(rect.top);
    setLeft(rect.left);
  }, [enable]);

  useIntervalWhen(
    () => {
      const rect = getRect();
      if (rect.top !== top || rect.left !== left) {
        if (enable) {
          callback();
        }
        setTop(rect.top);
        setLeft(rect.left);
      }
    },
    100,
    enable,
  );
}
