import React, { useEffect } from 'react';
import { sqWorkbookStore } from '@/core/core.stores';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { useRoutingData } from '@/main/useRoutingData.hook';
import { FAVICON } from '@/main/app.constants';
import { useLocation, useParams } from 'react-router-dom';
import { getDocumentTitle } from '@/utilities/utilities';
import { getFavIconElement } from '@/utilities/dom.utilities';

/**
 * This component manages the tab title display as well as the favicon
 */
export const TabTitle: React.FunctionComponent = () => {
  const { isWorksheetDisplayed } = useRoutingData();
  const location = useLocation();
  const isReport = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.isReportBinder);
  const isVantage = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.isVantage);
  const workbookName = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.name);
  const worksheets = useFluxPath(sqWorkbookStore, () => sqWorkbookStore.worksheets);

  const { worksheetId } = useParams();
  useEffect(() => {
    let favicon = FAVICON.WORKBENCH;
    if (isWorksheetDisplayed) {
      if (isReport) {
        favicon = FAVICON.BLUE;
      } else if (isVantage) {
        favicon = FAVICON.PURPLE;
      } else {
        favicon = FAVICON.GREEN;
      }
    }
    getFavIconElement().href = favicon;
    document.title = getDocumentTitle(location.pathname, worksheetId);
  }, [isWorksheetDisplayed, isReport, isVantage, location.pathname, workbookName, worksheets]);

  return null;
};
