import React from 'react';
import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import { getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import classNames from 'classnames';
import { StatisticSelector, StoredStatistic } from '@/tools/StatisticSelector.molecule';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';

export interface StatisticSelectorIF extends ValidatingFormComponent<StoredStatistic> {
  component: 'StatisticSelectorFormComponent';
  item: any;
  outputType: string[];
  value: StoredStatistic;
  onChange: (statistic: StoredStatistic) => void;
  onValidate?: (isValid: boolean) => void;
  onBlur?: any;
  formattedOptions?: boolean;
  placeholder?: string;
  testId?: string;
  appendToBody?: boolean;
  disabled?: boolean;
  fixedWidth?: boolean;
  wrapperClasses?: string;
  isRequired: boolean;
}

export const StatisticSelectorFormComponent: React.FunctionComponent<StatisticSelectorIF> = (props) => {
  const {
    name,
    validation,
    value,
    onChange,
    testId = 'statisticSelector',
    fixedWidth = true,
    label,
    extendValidation,
  } = props;

  const defaultValidation = _.isEmpty;
  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);
  const formState = useForm().getState();

  return (
    <FormFieldWrapper testId={testId} label={label} wrapperClassNames={classNames({ 'width-100percent': fixedWidth })}>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          const formFieldProps = getFormFieldProps(formState, input, meta, props);

          return (
            <StatisticSelector
              {...formFieldProps}
              extraClassNames={formFieldProps.className}
              onSelectStatistic={onChange}
              statistic={value}
              showError={formFieldProps.showError}
              testId={`${testId}Field`}
            />
          );
        }}
      </Field>
    </FormFieldWrapper>
  );
};
