import React from 'react';
import classNames from 'classnames';
import { Icon } from '@seeqdev/qomponents';
import { Sort } from '@/trendData/trendData.constants';

interface SortIconProps extends Sort {
  sortProperty: string;
}

/** Icon to indicate sorting, e.g. table column headers */
export const SortIcon: React.FunctionComponent<SortIconProps> = ({ sortProperty, sortBy, sortAsc }) => (
  <Icon
    testId={`sortProperty_${sortProperty}`}
    icon={classNames({
      'fa-sort-asc': sortBy === sortProperty && sortAsc,
      'fa-sort-desc': sortBy === sortProperty && !sortAsc,
      'fa-sort': sortBy !== sortProperty,
    })}
    type={sortBy === sortProperty ? 'info' : 'dark-gray'}
    extraClassNames="ml5 cursorPointer"
  />
);
