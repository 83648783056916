import _ from 'lodash';
import { ASSET_PATH_SEPARATOR } from '@/main/app.constants';
import { ItemPreviewWithAssetsV1 } from '@/sdk';

/**
 * Extracts the ancestors information from the provided response and adds a property to each item with the
 * assets, including the formatted name that includes the ancestors.
 *
 * @param data - Response from an endpoint that includes the ancestors property
 * @returns Transformed data with items containing a new assets property that is the list of assets the
 * item belongs to.
 */
export function addAssetsProperty(data: { items?: { ancestors?: { id: string; name: string }[] }[] }): {
  items: (ItemPreviewWithAssetsV1 & { assets: any[] })[];
} {
  _.forEach(_.get(data, 'items'), (item, k) => {
    (data.items?.[k] as any).assets = [getAssetFromAncestors(item.ancestors ?? [])];
  });
  return data as any;
}

/**
 * Returns an asset object with id, name, and formattedName based on its ancestors.
 *
 * @param {Object[]} ancestors - The array of ancestors
 * @returns {Object} Transformed object ancestors new properties.
 *                   {String} .id - ID of asset dependency
 *                   {String} .name - Name of asset dependency
 *                   {String} .formattedName - Name that includes the full asset path
 */
export function getAssetFromAncestors(ancestors: { name: string; id: string }[]) {
  return _.assign(
    {},
    _.pick(_.last(ancestors), ['id', 'name']),
    { formattedName: _.map(ancestors, 'name').join(ASSET_PATH_SEPARATOR) },
    { pathComponentIds: _.map(ancestors, 'id') },
  );
}

/**
 * Helper function to take an object and format all of its key/value pairs into a single query parameter string
 *
 * @param params - Object containing the key/value pairs to use as query parameters
 * @returns a string containing all value-ful properties
 */
export function formatAsQueryString(params: Record<string, any>) {
  const searchParams = new URLSearchParams();
  _.forEach(params, (value, key) => {
    if (value !== undefined) searchParams.append(key, value);
  });
  return searchParams.toString();
}
