import React, { ReactNode } from 'react';
import _ from 'lodash';
import { getLoginLink, getUnauthorizedLink } from '@/main/routing.utilities';
import { Navigate } from 'react-router-dom';
import { isCsrfSet } from '@/utilities/auth.utilities';
import { isAdmin } from '@/services/authorization.service';

interface ProtectedComponentProps {
  component: React.ReactNode;
  permissions?: () => boolean;
}

export const ProtectedComponent: React.FunctionComponent<ProtectedComponentProps> = ({ component, permissions }) => {
  const allowed = isCsrfSet() && (_.isFunction(permissions) ? permissions() : true);
  if (allowed) {
    // logged-in and user has sufficient permissions
    return <React.Suspense>{component}</React.Suspense>;
  } else if (isCsrfSet()) {
    // logged-in user, insufficient permissions
    return <Navigate to={getUnauthorizedLink()} replace />;
  } else {
    // not logged in
    return <Navigate to={getLoginLink()} replace />;
  }
};

export const AdminOnlyComponent = ({ component }: { component: ReactNode }) => {
  if (isCsrfSet()) {
    if (isAdmin()) {
      return <React.Suspense>{component}</React.Suspense>;
    }
    // not an admin
    return <Navigate to={getUnauthorizedLink()} replace />;
  }
  // not logged in
  return <Navigate to={getLoginLink()} replace />;
};
