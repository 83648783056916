import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { WORKBOOK_DISPLAY } from '@/workbook/workbook.constants';
import { getWorkbookDisplayMode, isWorksheetDisplayed as worksheetDisplayed } from '@/main/routing.utilities';

export const useRoutingData = () => {
  const [isWorksheetDisplayed, setIsWorksheetDisplayed] = useState(false);
  const [workbookDisplayMode, setWorkbookDisplayMode] = useState<WORKBOOK_DISPLAY>();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    setIsWorksheetDisplayed(worksheetDisplayed(path));
    setWorkbookDisplayMode(getWorkbookDisplayMode(path));
  }, [location]);

  return { workbookDisplayMode, isWorksheetDisplayed };
};
