import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setTemplateWorkstepId, setUserPreferencesDisplay } from '@/workbench/workbench.actions';
import { Button } from '@seeqdev/qomponents';
import { sqWorkbenchStore, sqWorkbookStore, sqWorkstepsStore } from '@/core/core.stores';
import { errorToast, successToast } from '@/utilities/toast.utilities';
import { isWorksheet } from '@/main/routing.utilities';
import { FakeLink } from '@/core/FakeLink';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { isViewOnlyWorkbookMode } from '@/utilities/utilities';
import { setWorkbookACL } from '@/workbook/workbook.actions';
import _ from 'lodash';
import { SeeqNames } from '@/main/app.constants.seeqnames';

/**
 * Tab to save current worksheet configuration as the default for newly created worksheets.
 */
export const SaveWorksheetPreferencesTab: React.FunctionComponent = () => {
  const { isReportBinder, acl } = useFlux(sqWorkbookStore);
  const isWorksheetOrViewOnly = isWorksheet() || isViewOnlyWorkbookMode();
  const { currentUser, stateParams } = useFlux(sqWorkbenchStore);
  useEffect(() => {
    if (stateParams.workbookId) {
      setWorkbookACL();
    }
  }, [stateParams.workbookId]);
  const isOwner = _.find(acl, (ace) => ace.identity.id === currentUser.id)?.isOwner;
  const canSavePreferences = isWorksheetOrViewOnly && isOwner && !isReportBinder;
  const { t } = useTranslation();
  return (
    <div className="flexRowContainer overflowHidden" data-testid="worksheetPreferencesTab">
      <div className="pb10 flexColumnContainer flexSpaceBetween">
        <b className="mb5">{t('USER.SAVE_PREFERENCES_TITLE')}</b>
        {canSavePreferences && (
          <FakeLink
            testId="resetWorksheetDefaults"
            onClick={() => {
              if (canSavePreferences) {
                setTemplateWorkstepId(SeeqNames.Database.Utils.EmptyGuid)
                  .then(() => {
                    successToast({ messageKey: 'USER.WORKSHEET_PREFERENCES_RESTORED_TOAST' });
                    setUserPreferencesDisplay(false);
                  })
                  .catch((e) => errorToast({ httpResponseOrError: e }));
              }
            }}
            extraClassNames="mb5 link sq-force-text-gray">
            <u>{t('USER.SAVE_PREFERENCES_RESET')}</u>
          </FakeLink>
        )}
      </div>
      <>
        {t('USER.SAVE_PREFERENCES_DETAILS')}
        <ul className="mb0">
          <li>{t('USER.SAVE_PREFERENCES_BULLET_ONE')}</li>
          <li>{t('USER.SAVE_PREFERENCES_BULLET_TWO')}</li>
          <li>{t('USER.SAVE_PREFERENCES_BULLET_THREE')}</li>
        </ul>
        <a
          target="_blank"
          href="https://telemetry.seeq.com/support-link/kb/latest/cloud/worksheet-preferences"
          rel="noopener">
          {t('USER.SAVE_PREFERENCES_KB_LINK')}
        </a>
      </>
      <div className="pt20 flexRowContainer flexAlignCenter">
        <Button
          testId="saveWorksheetDefaults"
          type="button"
          extraClassNames="buttonDisabledTooltip"
          size="sm"
          variant="theme"
          disabled={!canSavePreferences}
          onClick={() => {
            setTemplateWorkstepId(sqWorkstepsStore.current.id)
              .then(() => {
                successToast({ messageKey: 'USER.WORKSHEET_PREFERENCES_SAVED_TOAST' });
                setUserPreferencesDisplay(false);
              })
              .catch((e) => errorToast({ httpResponseOrError: e }));
          }}
          tooltip={t('USER.SAVE_PREFERENCES_BUTTON_TOOLTIP')}
          label={t('USER.SAVE_PREFERENCES_BUTTON')}
        />
      </div>
      {!canSavePreferences && (
        <div className="mt10 textAlignCenter xsmall">
          (
          {t(
            isWorksheetOrViewOnly && !isReportBinder
              ? 'USER.SAVE_PREFERENCES_PERMISSIONS_ERROR'
              : 'USER.SAVE_PREFERENCES_DISABLED',
          )}
          )
        </div>
      )}
    </div>
  );
};
