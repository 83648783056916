import _ from 'lodash';
import HttpCodes from 'http-status-codes';
import { onPermissions } from '@/services/notifier.service';
import { ITEM_DATA_STATUS } from '@/trendData/trendData.constants';
import { findItemIn, getTrendStores } from '@/trend/trendDataHelper.utilities';
import { infoToast } from '@/utilities/toast.utilities';
import { isViewOnlyWorkbookMode } from '@/utilities/utilities';
import { sqWorkbenchStore } from '@/core/core.stores';
import { DEBOUNCE } from '@/core/core.constants';
import { AnyProperty } from '@/utilities.types';

let redacted = false;

/**
 * Registers for and handles websocket permissions change notifications
 */
export function initializeRedactionService() {
  /**
   * Displays a worksheet reload notification. Used when a worksheet displayed in view mode has a workstep or
   * permissions changed. Debounced to prevent duplicate notifications if near-simultaneous display notification
   * calls are made.
   */
  const displayReloadNotification = _.debounce(
    () =>
      infoToast({
        messageKey: 'RELOAD_MESSAGE',
        buttonLabelKey: 'RELOAD',
        buttonAction: () => window.location.reload(),
      }),
    DEBOUNCE.MEDIUM,
    { leading: true },
  );

  onPermissions((workbookId: string, worksheetId: string) => {
    if (
      workbookId === sqWorkbenchStore.stateParams.workbookId &&
      worksheetId === sqWorkbenchStore.stateParams.worksheetId
    ) {
      if (isViewOnlyWorkbookMode()) {
        displayReloadNotification();
      } else {
        window.location.reload();
      }
    }
  });
}

/**
 * Returns true if the response represents a forbidden (403) respones
 *
 * @param {object} response - the response object
 * @param {number} response.status - the response status code
 * @returns {boolean}
 */
export function isForbidden(response: { status: number; response?: { status: number } }) {
  return response.status === HttpCodes.FORBIDDEN || response.response?.status === HttpCodes.FORBIDDEN;
}

/**
 * Handles forbidden responses by setting redacted to true. The worksheet watches the redacted state and displays
 * a banner if redacted is true.  Returns a caller-supplied deniedResponse in place of the forbidden response so
 * promise chains can continue and subsequent processing can take place.
 *
 * @param {object} response - the response object
 * @param {number} response.status - the response status code
 * @param {string} response.data.statusMessage - the response message
 * @param {any} deniedResponse - the value to be returned in place of the error for forbidden responses. If the
 * status code was not 403, then the original error response is returned.
 */
export function handleForbidden(response: { status: number; response?: { status: number } }, deniedResponse = {}): any {
  if (isForbidden(response)) {
    redacted = true;
    return deniedResponse;
  } else {
    return Promise.reject(response);
  }
}

/**
 * Whether something has been redacted or not
 *
 * @returns {boolean} true if something has been redacted since the last reset() call
 */
export function isRedacted() {
  return redacted;
}

/**
 * Resets the redaction state to false.
 */
export function resetRedactionService() {
  redacted = false;
}

/**
 * Returns true if the item from the details pane or an existing item parameter is redacted
 */
export function isItemRedacted(item: AnyProperty): boolean {
  return (
    isDataStatusRedacted(item) || // Use dataStatus field if available
    !_.get(item, 'effectivePermissions.read', true) || // Used for items w/o dataStatus
    _.get(item, 'redacted', false)
  ); // An 'originalParameter' from a tool pane item
}

function isDataStatusRedacted(item: AnyProperty): boolean {
  if (_.has(item, 'dataStatus') && item['dataStatus']) {
    return item['dataStatus'] === ITEM_DATA_STATUS.REDACTED;
  }
  // Sometimes properties from the item get picked off, so look up the item in the store to be certain
  // Note this is an expensive operation because it has to iterate over all the TREND_STORES and all items in each
  // store trying to find an item there that matches the id of our item.  This is particularly slow when the item
  // is NOT redacted and thus all store items have to be scanned.
  const maybeStoreItem = findItemIn(getTrendStores(), _.get(item, 'id'));
  return _.get(maybeStoreItem, 'dataStatus') === ITEM_DATA_STATUS.REDACTED;
}
