import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup } from 'react-bootstrap';
import _ from 'lodash';
import { Checkbox } from '@seeqdev/qomponents';
import { Day, WeeklyScheduleData } from '@/schedule/schedule.types';

const DAYS_TRANSLATIONS = {
  monday: 'SCHEDULE_TYPE.WEEKLY.MONDAY',
  tuesday: 'SCHEDULE_TYPE.WEEKLY.TUESDAY',
  wednesday: 'SCHEDULE_TYPE.WEEKLY.WEDNESDAY',
  thursday: 'SCHEDULE_TYPE.WEEKLY.THURSDAY',
  friday: 'SCHEDULE_TYPE.WEEKLY.FRIDAY',
  saturday: 'SCHEDULE_TYPE.WEEKLY.SATURDAY',
  sunday: 'SCHEDULE_TYPE.WEEKLY.SUNDAY',
};

interface WeeklyScheduleProps {
  days: WeeklyScheduleData;
  setDays: (days: WeeklyScheduleData) => void;
}

export const WeeklySchedule: React.FunctionComponent<WeeklyScheduleProps> = ({ days, setDays }) => {
  const { t } = useTranslation();

  const toggleDay = (day: Day) => setDays({ ...days, [day]: !days[day] });

  return (
    <FormGroup id="weeklyScheduleConfig" data-testid="weekly-schedule-config" className="ml8">
      {_.map(Object.keys(DAYS_TRANSLATIONS), (day: Day) => {
        const id = `select-${day}`;

        return (
          <Checkbox
            id={id}
            key={day}
            testId={id}
            type="checkbox"
            label={t(DAYS_TRANSLATIONS[day])}
            checked={days[day]}
            onChange={() => toggleDay(day)}
          />
        );
      })}
    </FormGroup>
  );
};
