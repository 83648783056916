import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { UnitOfMeasure as UOM } from '@/utilities/unit.utilities';

export interface UnitOfMeasureProps {
  unitOfMeasure: UOM;
  isStandalone?: boolean;
}

/**
 * Renders the unit of measure if it is available. If the unit is rendered "standalone" no wrapping parenthesis
 * are included. If an unknown unit of measure is found it is displayed in italics and slightly transparent,
 * opaque on hover.
 */
export const UnitOfMeasure: React.FunctionComponent<UnitOfMeasureProps> = (props) => {
  const { unitOfMeasure, isStandalone } = props;
  const { t } = useTranslation();

  if (_.isEmpty(unitOfMeasure) || !unitOfMeasure.value) {
    return null;
  }

  const style = classNames('inline', {
    'text-italic': unitOfMeasure.fromSourceUom || unitOfMeasure.isDisplay,
    'text-opaque-onhover': unitOfMeasure.fromSourceUom || unitOfMeasure.isDisplay,
  });

  let tooltip;
  if (unitOfMeasure.isDisplay) {
    tooltip = 'DISPLAY_UNIT';
  } else if (unitOfMeasure.fromSourceUom) {
    tooltip = 'UNRECOGNIZED_UNIT';
  }

  return (
    <span
      className={style}
      data-testid="unitOfMeasure"
      data-qtip-text={tooltip ? t(tooltip) : undefined}
      data-qtip-placement="top">
      {!isStandalone && '('}
      {unitOfMeasure.value}
      {!isStandalone && ')'}
    </span>
  );
};
