// @ts-strict-ignore
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import _ from 'lodash';
import { Icon } from '@seeqdev/qomponents';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';

export interface PatternIF extends ValidatingFormComponent<string> {
  component: 'PatternFormComponent';
  min: number;
  max: number;
  label?: string;
}

export const PatternFormComponent: React.FunctionComponent<PatternIF> = (props) => {
  const {
    name,
    testId = 'pattern',
    label,
    tooltip,
    min,
    max,
    validation,
    extendValidation,
    extraClassNames,
    customErrorText,
  } = props;

  const defaultValidation = () => min === max;

  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);

  const { t } = useTranslation();
  const formState = useForm().getState();
  const showError = _.has(formState.errors, name) && formState.hasValidationErrors;

  return (
    <FormFieldWrapper
      testId={testId}
      extraClassNames={extraClassNames}
      showError={showError}
      customErrorText={customErrorText}>
      <Field name={name} validate={appliedValidation}>
        {() => {
          return (
            <div className="flexColumnContainer">
              <div>{t(label)}</div>
              {tooltip && (
                <Icon
                  icon="fa-question-circle"
                  large={true}
                  tooltip={t(tooltip)}
                  extraClassNames="text-interactive pl5"
                />
              )}
            </div>
          );
        }}
      </Field>
    </FormFieldWrapper>
  );
};
