import { sqAuthApi } from '@/sdk/api/AuthApi';
import { AUTH_CHANGE_BROADCAST_CHANNEL, emitBroadcastChannelMessage } from '@/services/broadcastChannel.utilities';
import { DatasourceOutputV1 } from '@/sdk/model/DatasourceOutputV1';
import { AuthInputV1 } from '@/sdk/model/AuthInputV1';
import { logError } from '@/utilities/logger';
import { close as closeSocket } from '@/utilities/socket.utilities';
import { base64guid } from '@/utilities/utilities';
import { removeCsrfToken } from '@/utilities/auth.utilities';
import { notifyError } from '@/utilities/screenshot.utilities';
import { authAutoLogin } from '@/services/systemConfiguration.utilities';
import { generateNewSessionId, setCurrentUser } from '@/workbench/workbench.actions';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { getLoginLink, goTo } from '@/main/routing.utilities';

/**
 * Logs in to the Seeq application.
 *
 * @param  {AuthInputV1} authCredentials - The authentication credentials
 * @param  {String} authProviderClass - The authProviderClass property of the AuthProvider
 * @param  {String} authProviderId - The authProviderId property of the AuthProvider
 * @return {Promise} A promise that resolves if login was successful
 */
export function login(authCredentials: AuthInputV1, authProviderClass: string, authProviderId: string) {
  if (headlessRenderMode()) {
    notifyError('Authentication Issue: Prevented login request');
    logError('Screenshot mode should not make requests to login');
    return Promise.resolve();
  }

  generateNewSessionId();

  // See the note in AuthQueriesV1 for why it needs to be in the URL as well as the body
  const params =
    authProviderClass && authProviderId ? { authProviderClass, authProviderId, sequenceId: base64guid() } : {};

  return sqAuthApi.login({ ...authCredentials, authProviderClass, authProviderId }, { params }).then((response) => {
    emitBroadcastChannelMessage(AUTH_CHANGE_BROADCAST_CHANNEL);
    return setCurrentUser();
  });
}

/**
 * Fetches a list of all available Authentication Providers.
 *
 * @returns {Promise} that resolves with a list of available Authentication Providers
 */
export function fetchAuthenticationProviders(): Promise<DatasourceOutputV1[]> {
  const urlParams = Object.fromEntries(new URLSearchParams(window.location.search));
  const queryOptions: Record<string, any> = {};
  const directoryId = urlParams.directoryId?.trim();
  if (directoryId) {
    queryOptions.includeAuthProviderIds = directoryId;
  }
  return sqAuthApi.getAuthProviders(queryOptions).then(({ data }) => data.authProviders);
}

/**
 * Logs out and redirect to the login page. It is important that a full redirect be done to ensure that all
 * in-memory state in services and stores is removed.
 *
 * @param - if true prevent immediate auto-login
 */
export function logout(returnToLink?: string, userInitiated = true) {
  if (headlessRenderMode()) {
    notifyError('Authentication Issue: Prevented invalidation of provided auth token');
    logError('Screenshot mode should not make requests to logout');
    return Promise.resolve();
  }

  return sqAuthApi.logout().finally(() => {
    removeCsrfToken();
    closeSocket();
    emitBroadcastChannelMessage(AUTH_CHANGE_BROADCAST_CHANNEL);

    const extraSearchParams = new URLSearchParams();
    // Prevent immediate auto re-login if the user logs out on a system that is configured to automatically login
    if (authAutoLogin() && userInitiated) {
      extraSearchParams.append('autoLogin', 'false');
    }
    goTo(getLoginLink(returnToLink, extraSearchParams));
  });
}
