import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@seeqdev/qomponents';
import { RemovableTextButton } from '@/core/RemovableTextButton.molecule';
import { Datasource } from '@/search/search.types';

interface ActiveFiltersProps {
  selectedFilters: { label: string; filter: string | string[] | Datasource[]; onClear: () => void }[];
  actionAfterFilterCleared?: () => void;
}

export const ActiveFilters: React.FunctionComponent<ActiveFiltersProps> = ({
  selectedFilters,
  actionAfterFilterCleared = _.noop,
}) => {
  const { t } = useTranslation();

  const activeFilters = _.reject(selectedFilters, ({ filter }) => _.isEmpty(filter));

  if (activeFilters.length === 0) {
    return <></>;
  }

  return (
    <div data-testid="activeFilters" className="flexColumnContainerWrap flexAlignCenter mb10 gap5">
      {t('SEARCH_DATA.FILTERS')}
      {_.map(activeFilters, ({ label, onClear }) => (
        <Button
          key={label}
          testId={label}
          label={
            <RemovableTextButton
              label={label}
              showFormat={true}
              onClick={() => {
                onClear();
                actionAfterFilterCleared();
              }}
            />
          }
        />
      ))}
    </div>
  );
};
