// @ts-strict-ignore
import React, { useState } from 'react';
import tinycolor from 'tinycolor2';
import { CustomPicker } from 'react-color';
import { Hue, Saturation } from 'react-color/lib/components/common';
import { ButtonWithPopover } from '@seeqdev/qomponents';
import { t } from 'i18next';

interface HueSaturationProps {
  color: string;
  onChange: (color: object) => void;
}

/**
 * Component of ColorPicker to pick color based on Hue + Saturation
 */
const HueSaturation: React.FunctionComponent<HueSaturationProps> = (props) => {
  const color = tinycolor(props.color);
  const [showPopover, setShowPopover] = useState(false);

  const closeHueSaturationPicker = (open: boolean) => {
    setShowPopover(open);
  };

  return (
    <ButtonWithPopover
      trigger={
        <div
          className="colorPickerSwatch hsSwatch"
          data-testid="hsPickerButton"
          style={{ backgroundColor: color.toHexString() }}
          id="specHsPickerButton"
        />
      }
      tooltip={t('COLOR_PICKER.PICKER')}
      isOpen={showPopover}
      onOpenChange={closeHueSaturationPicker}
      placement="bottom">
      <div id="specHSPickerPopover" data-testid="hueSaturationPickerPopover">
        <div className="backgroundColorWhite p10">
          <div className="width-150 height-150 mb5 positionRelative" data-testid="specSaturationPicker">
            <Saturation hsv={color.toHsv()} hsl={color.toHsl()} onChange={(e) => props.onChange(e)} />
          </div>
          <div className="width-150 height-10 positionRelative" data-testid="specHuePicker">
            <Hue hsl={color.toHsl()} onChange={(e) => props.onChange(e)} />
          </div>
        </div>
      </div>
    </ButtonWithPopover>
  );
};

export const HueSaturationPicker = CustomPicker(HueSaturation);
