// @ts-strict-ignore
import _ from 'lodash';

import { flux } from '@/core/flux.module';

/**
 * Sets the system warnings and errors from the array of serverSpecs objects.
 *
 * @param {Object[]} serverSpecsArray
 * @param {String} serverSpecsArray[].componentName - the name of the hardware component
 * @param {Boolean} serverSpecsArray[].error - true if component is an error
 * @param {Boolean} serverSpecsArray[].warning - true if component is a warning
 */
export function setSystemWarnings(serverSpecsArray) {
  flux.dispatch(
    'SYSTEM_WARNINGS_SET_WARNING_COMPONENTS',
    _.chain(serverSpecsArray)
      .filter((spec: any) => spec.error || spec.warning)
      .map('componentName')
      .value(),
  );
}

export function setSystemMessage(message) {
  flux.dispatch('SYSTEM_WARNINGS_SET_SYSTEM_MESSAGE', { message });
}

export function disableSystemWarning() {
  flux.dispatch('SYSTEM_WARNINGS_DISABLE_SYSTEM_WARNING');
}

export function disableUserLimitWarning() {
  flux.dispatch('SYSTEM_WARNINGS_SUPPRESS_USER_LIMIT_WARNING');
}
