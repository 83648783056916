import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Icon } from '@seeqdev/qomponents';
import { sqLicenseManagementStore, sqWorkbenchStore } from '@/core/core.stores';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { setLicenseExpirationSnooze } from '@/workbench/workbench.actions';
import { ContainerWithHTML } from '@/core/ContainerWithHTML.atom';
import { buildEmailLink } from '@/utilities/utilities';
import { LICENSE_STATUS } from '@/licenseManagement/licenseManagement.constants.ts';
import { adminContactEmail, adminContactName, isLicenseWarningHidden } from '@/services/systemConfiguration.utilities';
import { getLicenseLink } from '@/main/routing.utilities';
import { FakeLink } from '@/core/FakeLink';

/**
 * Issues warning about license expiration.
 * Based on the user type different banners are displayed:
 * - users get a prompt to remind their admin to renew
 * - admins get a link to request a license.
 *
 * The warning that the license will expire soon can be snoozed or dismissed.
 */
export const LicenseExpirationWarning: React.FunctionComponent = () => {
  const currentUser = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.currentUser);
  const licenseExpirationSnoozeUntil = useFluxPath(
    sqWorkbenchStore,
    () => sqWorkbenchStore.licenseExpirationSnoozeUntil,
  );
  const { isTrial, license = {}, licenseStatus } = useFlux(sqLicenseManagementStore);

  const { t } = useTranslation();

  const [showAdminExpirationWarning, setShowAdminExpirationWarning] = useState<boolean>();
  const [showUserExpirationWarning, setShowUserExpirationWarning] = useState<boolean>();

  const { daysToExpiration } = license;
  const contactEmail = buildEmailLink(
    adminContactEmail(),
    adminContactName(),
    'LICENSE.EMAIL_LICENSE_REQUEST.SUBJECT',
    'LICENSE.EMAIL_LICENSE_REQUEST.RENEW',
  );
  const snoozeDays = !_.isNil(daysToExpiration) ? Math.ceil(daysToExpiration / 2) : undefined;

  /**
   * This effect sets the warning flags used by the html component to display the appropriate banner.
   * Based on the user type and the status of the license different banners will be displayed.
   *
   * This effect also manages the display of the notification that informs the user that new workbook and worksheet
   * creation is not supported with an expired license.
   *
   * This effect also supports the "snoozing" of the warning message by checking the snooze data against the
   * current date.
   */
  useEffect(() => {
    const isSnoozed = sqWorkbenchStore.isLicenseExpirationSnoozed();
    if (!isSnoozed && !_.isEmpty(currentUser) && licenseStatus) {
      if (
        currentUser.isAdmin &&
        (licenseStatus === LICENSE_STATUS.SHOW_LICENSE_WARNING ||
          licenseStatus === LICENSE_STATUS.SHOW_ADMIN_LICENSE_WARNING)
      ) {
        setShowAdminExpirationWarning(true);
      } else if (licenseStatus === LICENSE_STATUS.SHOW_LICENSE_WARNING && !isLicenseWarningHidden()) {
        setShowUserExpirationWarning(true);
      }
    }

    if (isSnoozed && (showAdminExpirationWarning || showUserExpirationWarning)) {
      setShowAdminExpirationWarning(false);
      setShowUserExpirationWarning(false);
    }
  }, [isTrial, license, currentUser, licenseExpirationSnoozeUntil]);

  /**
   * Dismisses the warning banner only until then page is reloaded.
   */
  const closeBanner = () => {
    setShowAdminExpirationWarning(false);
    setShowUserExpirationWarning(false);
  };

  /**
   * Snoozes the license warning.
   * Adds a Snooze Until entry to local storage that can be used to determine if warning messages are snoozed or not.
   *
   * The "snooze" duration is always half of the remaining days to license expiration.
   */
  const snoozeLicense = () => {
    const newWarningDisplayDate = moment().add(snoozeDays, 'days').toISOString();
    setLicenseExpirationSnooze(newWarningDisplayDate);
    closeBanner();
  };

  // Warning banner that informs on upcoming license expiration
  if (showAdminExpirationWarning || showUserExpirationWarning) {
    return (
      <div className="pt20 pb20 flexColumnContainer flexCenter warning warningBannerHeight" id="systemWarningBanner">
        {showAdminExpirationWarning && (
          <div className="flexFill ml20">
            {isTrial && (
              <span data-testid="adminExpirationWarningTrial">
                {t('LICENSE.ADMIN_EXPIRATION_WARNING_TRIAL', {
                  DAYS: daysToExpiration,
                })}
              </span>
            )}
            {!isTrial && (
              <span data-testid="adminExpirationWarning">
                {t('LICENSE.ADMIN_EXPIRATION_WARNING', {
                  DAYS: daysToExpiration,
                })}
              </span>
            )}
            &nbsp;
            <Link to={getLicenseLink()}>{t('LICENSE.ADMIN_EXPIRATION_WARNING_CALL_TO_ACTION')}</Link>
          </div>
        )}

        {showUserExpirationWarning && (
          <div className="flexFill ml20">
            <span data-testid="userThanks">{t('LICENSE.THANKS_FOR_INTEREST')}</span>
            <br />
            {isTrial && (
              <span data-testid="userExpirationWarningTrial">
                {t('LICENSE.USER_EXPIRATION_WARNING_TRIAL', {
                  DAYS: daysToExpiration,
                })}
              </span>
            )}
            {!isTrial && (
              <span data-testid="userExpirationWarning">
                {t('LICENSE.USER_EXPIRATION_WARNING', {
                  DAYS: daysToExpiration,
                })}
              </span>
            )}
            &nbsp;
            <ContainerWithHTML
              content={t('LICENSE.USER_EXPIRATION_CALL_TO_ACTION', {
                CONTACT: contactEmail,
              })}
            />
          </div>
        )}

        <div>
          <FakeLink
            extraClassNames="mr15"
            onClick={(e) => {
              e.preventDefault();
              snoozeLicense();
            }}
            testId="snoozeAction">
            {t('LICENSE.SNOOZE', { DAYS: snoozeDays })}
          </FakeLink>
          <Icon icon="fa-times" large={true} extraClassNames="closeIcon mr20" onClick={closeBanner} />
        </div>
      </div>
    );
  }

  return <></>;
};
