// @ts-strict-ignore
import React from 'react';
import { Icon } from '@seeqdev/qomponents';
import { IconType } from '@seeqdev/qomponents/dist/Icon/Icon.types';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { canUseMemo } from '@/formbuilder/formbuilder.utilities';
import { useTranslation } from 'react-i18next';

export interface IconIF extends ValidatingFormComponent<string> {
  component: 'IconFormComponent';
  onIconClick?: (event?) => any;
  type?: IconType;
  color?: string;
  id?: string;
  large?: boolean;
  iconClasses?: string;
}

const IconFormComponentUnwrapped: React.FunctionComponent<IconIF> = (props) => {
  const { value, tooltip, onIconClick, testId, extraClassNames, type, color, id, large, iconClasses } = props;
  const { t } = useTranslation();

  return (
    <span className={extraClassNames}>
      <Icon
        type={type}
        color={color}
        id={id}
        tooltip={t(tooltip)}
        large={large}
        icon={value}
        onClick={onIconClick}
        testId={testId}
        extraClassNames={iconClasses}
      />
    </span>
  );
};

export const IconFormComponent = React.memo(IconFormComponentUnwrapped, canUseMemo);
