import _ from 'lodash';

/**
 * Identifies a plugin-supplied icon as being an SVG path instead of an icon class
 */
export const SVG_PATH = 'svgpath:';
export const WORKSHEET_TABSETS = {
  sidebar: ['search', 'investigate', 'annotate', 'reportComments', 'reportConfig'],
};
export type WorksheetTabset = keyof typeof WORKSHEET_TABSETS;
// Define valid targets for a view
export const WORKSHEET_TARGET = {
  WORKSHEET: 'WORKSHEET',
  DATA_PANEL: 'DATA_PANEL',
};

/**
 * Define available worksheet views here.
 *
 * Optional properties:
 *
 *   useSizeFromRender - an optional boolean property that flags a view as having non-scaling content
 *   (i.e. content that does not expand or collapse to fill the display pane). A view that sets this property
 *   to true should also add a ".useSizeFromRender" CSS class to the container of content in the HTML
 *   template. Doing these two things enables the screenshot service to properly screenshot the content and
 *   the report editor to know when content has a fixed size.
 */
export interface WorksheetView {
  key: string;
  target: string;
  name: string;
  icon: string;
  selectedItemsRealm: string;
  default?: boolean;
  useSizeFromRender?: boolean;
  hideFromViewSelector?: boolean;
  trackAction?: string;
}

export const WORKSHEET_VIEWS: WorksheetView[] = [
  {
    key: 'TREND',
    target: 'DATA_PANEL',
    name: 'TREND',
    icon: 'fc fc-trend spec-trend-view',
    selectedItemsRealm: 'TREND',
    trackAction: 'Trend',
    default: true,
  },
  {
    key: 'SCATTER_PLOT',
    target: 'DATA_PANEL',
    name: 'SCATTER.XY_PLOT',
    icon: 'fc fc-scatterplot',
    selectedItemsRealm: 'TREND',
    trackAction: 'Scatter Plot',
  },
  {
    key: 'TREEMAP',
    target: 'DATA_PANEL',
    name: 'TREEMAP.TITLE',
    icon: 'fc fc-treemap',
    selectedItemsRealm: 'TREND',
    trackAction: 'Treemap',
  },
  {
    key: 'TABLE',
    target: 'DATA_PANEL',
    name: 'TABLE_BUILDER.TITLE',
    icon: 'fc fc-table',
    useSizeFromRender: true,
    selectedItemsRealm: 'TABLE',
    trackAction: 'Table',
  },
];
export const ASSET_GROUP_VIEW: WorksheetView = {
  key: 'ASSET_GROUP_EDITOR',
  target: 'DATA_PANEL',
  name: 'ASSET_GROUP_EDITOR.TITLE',
  icon: 'fa fa-fw fa-cubes',
  selectedItemsRealm: 'TREND',
  hideFromViewSelector: true,
};
export const TABLE_DEFINITION_VIEW: WorksheetView = {
  key: 'TABLE_DEFINITION_EDITOR',
  target: 'DATA_PANEL',
  name: 'TABLE_DEFINITION_EDITOR.TITLE',
  icon: 'fa fa-fw fa-cubes',
  selectedItemsRealm: 'TREND',
  hideFromViewSelector: true,
};
// Manually constructed from WORKSHEET_VIEWS to give type safety
export const WORKSHEET_VIEW = {
  TREND: 'TREND',
  SCATTER_PLOT: 'SCATTER_PLOT',
  TREEMAP: 'TREEMAP',
  TABLE: 'TABLE',
  ASSET_GROUP_EDITOR: 'ASSET_GROUP_EDITOR',
  TABLE_DEFINITION_EDITOR: 'TABLE_DEFINITION_EDITOR',
};
// Create a constant for each available sidebar tab
export const WORKSHEET_SIDEBAR_TAB = _.transform(
  WORKSHEET_TABSETS.sidebar,
  (result, tabname: string) => {
    result[_.replace(_.upperCase(tabname), ' ', '_')] = tabname;
  },
  {},
);
