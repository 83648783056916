// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { FormControlFormComponent } from '@/formbuilder/FormControlFormComponent.atom';
import { PasswordCheckFormComponent } from '@/formbuilder/PasswordCheckFormComponent.atom';
import { LabelFormComponent } from '@/formbuilder/LabelFormComponent.atom';
import { getPasswordErrorText, validPassword } from '@/utilities/password.utilities';
import { useForm } from 'react-final-form';

export interface PasswordGroupIF extends ValidatingFormComponent<string | number> {
  component: 'PasswordGroupFormComponent';
  name: string;
  includeIf?: boolean;
}

// PasswordGroupFormComponent can only be used if “skipStore” is true. This setting will not call the onChange handler.
export const PasswordGroupFormComponent: React.FunctionComponent<PasswordGroupIF> = ({ testId = 'passwordGroup' }) => {
  const validatePassword = (value: string) => !validPassword(value);
  const formState = useForm().getState();
  const formValues = formState.values;
  const newPasswordErrorText = getPasswordErrorText(formValues.newPassword);

  return (
    <div className="flexRowContainer" data-testid={testId}>
      <div className="mb8">
        <FormControlFormComponent
          component="FormControlFormComponent"
          name="newPassword"
          label="USER.PASSWORD"
          value=""
          onChange={_.noop}
          placeholder="USER.PASSWORD_PROMPT"
          validation={validatePassword}
          customErrorText={newPasswordErrorText}
          size="md"
          type="password"
          testId="newPassword"
        />
        <LabelFormComponent
          component="LabelFormComponent"
          name="confirmPasswordLabel"
          value="USER.PASSWORD_CHECK"
          extraClassNames="mt8 text-bolder"
        />
        <PasswordCheckFormComponent
          component="PasswordCheckFormComponent"
          name="confirmPassword"
          value=""
          onChange={_.noop}
          placeholder="USER.PASSWORD_CHECK_PROMPT"
          validation={validatePassword}
          extraClassNames="mb10"
          size="md"
          type="password"
          testId="confirmPassword"
        />
      </div>
    </div>
  );
};
