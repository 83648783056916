// @ts-strict-ignore
import _ from 'lodash';
import { ItemSelectValue } from '@/formbuilder/ItemSelectFormComponent.atom';
import { TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { BaseToolStore } from '@/toolSelection/baseTool.store';
import { BASE_TOOL_COMMON_PROPS } from '@/toolSelection/baseTool.constants';

export type ConditionProperty = {
  stringSignal: ItemSelectValue;
  propertyName: string;
};

const DEFAULT_PROPERTY_CONFIG: ConditionProperty = {
  stringSignal: '',
  propertyName: '',
};

export const INITIAL_PROPERTIES: ConditionProperty[] = [
  _.cloneDeep(DEFAULT_PROPERTY_CONFIG),
  _.cloneDeep(DEFAULT_PROPERTY_CONFIG),
];

export class ConditionWithPropertiesStore extends BaseToolStore {
  type = TREND_TOOLS.CONDITION_WITH_PROPERTIES;
  static readonly storeName = 'sqConditionWithPropertiesStore';

  initialize() {
    this.state = this.immutable(
      _.assign({}, BASE_TOOL_COMMON_PROPS, {
        properties: INITIAL_PROPERTIES,
      }),
    );
  }

  get properties(): ConditionProperty[] {
    return this.state.get('properties');
  }

  /**
   * Exports state so it can be used to re-create the state later using `rehydrate`.
   *
   * @return {Object} The dehydrated state
   */
  dehydrate(): object {
    return this.state.serialize();
  }

  /**
   * Rehydrates the store.
   *
   * @param {Object} dehydratedState - Previous state usually obtained from `dehydrate` method.
   */
  rehydrate(dehydratedState: object) {
    this.state.merge(dehydratedState);
  }

  protected readonly handlers = {
    ...this.baseHandlers,
    CONDITION_WITH_PROPERTIES_ADD_PROPERTY: () => {
      this.state.push('properties', { ...DEFAULT_PROPERTY_CONFIG });
    },
    CONDITION_WITH_PROPERTIES_REMOVE_PROPERTY: (index: number) => {
      if (index > -1) {
        this.state.splice('properties', [index, 1]);
      }
    },
    CONDITION_WITH_PROPERTIES_UPDATE_PROPERTY: ({
      value,
      index,
    }: {
      value: { stringSignal: { name: string }; propertyName: string };
      index: number;
    }) => {
      if (index > -1) {
        // Set propertyName to string-signal
        if (value.stringSignal) {
          value.propertyName = value.stringSignal.name;
        }
        this.state.splice('properties', [index, 1, { ...this.state.get('properties', index), ...value }]);
      }
    },
  };
}
