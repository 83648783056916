import { IdentityPreviewV1 } from '@/sdk/model/IdentityPreviewV1';
import { UserOutputV1 } from '@/sdk/model/UserOutputV1';
import { Ace } from '@/accessControl/itemAclModal.utilities';
import _ from 'lodash';
import { PermissionsV1 } from '@/sdk/model/PermissionsV1';
import { SeeqNames } from '@/main/app.constants.seeqnames';

const hasAnyPermission = (ace: Ace): boolean =>
  ace.permissions?.read || ace.permissions?.write || ace.permissions?.manage || false;

/**
 * De-dupes the ACE entries and ensures that we only show one line with consolidated permissions for an identity.
 *
 * @param entries - The aces to dedup
 * @return a deduped list of aces
 */
export function deDupeAceList(entries: Ace[]): Ace[] {
  let deduped: Ace[] = [];

  _.reduce(
    entries,
    (dedupedAces, value: any) => {
      let valueClone = _.clone(value);

      const existingEntry = _.find(dedupedAces, ['identity.id', _.get(valueClone, 'identity.id')]);
      if (!existingEntry) {
        dedupedAces.push(valueClone);
      } else {
        existingEntry.permissions = {
          read: valueClone.permissions?.read || existingEntry.permissions?.read,
          write: valueClone.permissions?.write || existingEntry.permissions?.write,
          manage: valueClone.permissions?.manage || existingEntry.permissions?.manage,
        };
      }

      return dedupedAces;
    },
    deduped,
  );

  return deduped;
}

/**
 * Filters the ace entries to the ones relevant for the provided user (the ones that gives permissions to that user)
 *
 * @param entries - The aces to filter
 * @param user - The target user to do the filtering for
 * @returns a filtered list of aces giving permissions to the user. The list is sorted by identity name but the user
 * ace remains the last in the list
 */
export function getAcesGrantingUserAccess(entries: Ace[], user: UserOutputV1): Ace[] {
  const groupIds = _.map(user.groups, (group) => group.id);
  const identitiesForUser = _.concat(groupIds, [user.id]);

  return _.chain(entries)
    .filter((ace) => hasAnyPermission(ace))
    .filter((ace) => _.includes(identitiesForUser, ace.identity?.id))
    .sortBy([(ace) => `${ace.identity?.type}_${ace.identity?.name}`])
    .value();
}

/**
 * Compute aggregated permissions for a set of aces granting user access.
 *
 * @param acesGrantingUserAccess - list of aces granting user access
 * @returns a PermissionsV1 object with the permissions aggregated
 */
export function getAggregatedPermissions(acesGrantingUserAccess: Ace[]): PermissionsV1 {
  return _.reduce(
    acesGrantingUserAccess,
    (perm, ace) => {
      return {
        read: perm.read || ace.permissions?.read || false,
        write: perm.write || ace.permissions?.write || false,
        manage: perm.manage || ace.permissions?.manage || false,
      } as PermissionsV1;
    },
    { read: false, write: false, manage: false } as PermissionsV1,
  );
}

/**
 * From the list of groups assigned to a user returns the ones that didn't grant any access.
 * Returned groups are sorted by name.
 *
 * @param acesGrantingUserAccess - list of aces granting user access
 * @param assignedGroups - list of groups assigned to the user
 * @returns the groups not granting any access to the user sorted by name
 */
export function getOtherGroupAssignments(
  acesGrantingUserAccess: Ace[],
  assignedGroups: IdentityPreviewV1[],
): IdentityPreviewV1[] {
  const groupAlreadyAllowing = _.chain(acesGrantingUserAccess)
    .filter((ace) => hasAnyPermission(ace))
    .map((ace) => ace.identity?.id)
    .value();
  return _.chain(assignedGroups)
    .filter((group) => !_.includes(groupAlreadyAllowing, group.id))
    .sortBy(['name'])
    .value();
}

/**
 * Filters the ace entries to the ones relevant for groups.
 *
 * @param aces - The aces to filter
 * @returns the group ace entries
 */
export function filterToGroupAces(aces: Ace[]): Ace[] {
  return _.chain(aces)
    .filter((ace) => ace.identity?.type === SeeqNames.Types.UserGroup)
    .sortBy([(ace) => ace.identity?.name])
    .value();
}
