import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isSeeqPreviewEnabled } from '@/services/systemConfiguration.utilities';
import { getWorkbooksLink } from '@/main/routing.utilities';
import seeqLogo from 'resources/img/Seeq_logo_white@2x.png';
import seeqLogoPreview from 'resources/img/Seeq_preview_logo_white@2x.png';
import { WrapperWithLink } from '@/core/WrapperWithLink.atom';

interface HeaderLogoProps {
  ignoreConfigError?: boolean;
}

const seeqPreviewEnabled = (ignoreConfigError: boolean) => {
  try {
    return isSeeqPreviewEnabled();
  } catch (e) {
    if (ignoreConfigError) {
      return false;
    }

    throw e;
  }
};

export const HeaderLogo: React.FunctionComponent<HeaderLogoProps> = ({ ignoreConfigError = false }) => {
  const navigate = useNavigate();

  const gotoHomeScreen = () => {
    navigate(getWorkbooksLink());
  };

  const isSeeqPreviewEnabled = () => seeqPreviewEnabled(ignoreConfigError);

  return (
    <div className="headerLogo" data-testid="headerLogo">
      <WrapperWithLink link={getWorkbooksLink()}>
        <div className="navbar-nav pl0 pr0 cursorPointer">
          <img
            className="mt4 mr15 mb5 ml5"
            src={isSeeqPreviewEnabled() ? seeqLogoPreview : seeqLogo}
            height={28}
            width={isSeeqPreviewEnabled() ? 130 : 80}
            onClick={gotoHomeScreen}
            data-testid="headerImage"
          />
        </div>
      </WrapperWithLink>
    </div>
  );
};
