import React, { useCallback, useEffect, useState } from 'react';
import { PluginHost } from '@/plugin/PluginHost.atom';
import { useWillUnmount } from 'rooks';
import { generatePluginApi } from '@/plugin/generatedPluginApi';
import { pluginWorkerApi } from '@/webWorkers/plugin.worker-api';
import { setContentPluginRenderComplete, updateContentPluginData } from '@/plugin/plugin.actions';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { sqPluginStore } from '@/core/core.stores';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { sqPluginsApi } from '@/sdk';

interface PluginContentProps {
  contentId: string;
  pluginIdentifier: string;
  data: any;
  loading: boolean;
  onPluginReady: () => void;
  onClick: () => void;
}

export const PluginContent: React.FunctionComponent<PluginContentProps> = ({
  contentId,
  data,
  pluginIdentifier,
  loading,
  onPluginReady,
  onClick,
}) => {
  const [entryPoint, setEntryPoint] = useState<string | undefined>(undefined);
  const [queryParam, setQueryParam] = useState<number | undefined>(undefined);

  useEffect(() => {
    sqPluginsApi.getPlugins({ identifierSearch: pluginIdentifier }).then((plugins) => {
      setEntryPoint(plugins.data.plugins?.find((plugin) => plugin.identifier === pluginIdentifier)?.entryPoint);
    });
  }, [pluginIdentifier]);

  const onIframeClick = useCallback(() => {
    if (
      document.activeElement ===
      document.querySelector(`[${SeeqNames.TopicDocumentAttributes.DataSeeqContent}="${contentId}"] iframe`)
    ) {
      onClick();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('blur', onIframeClick);
    return () => {
      window.removeEventListener('blur', onIframeClick);
    };
  }, [onIframeClick]);

  useEffect(() => {
    updateContentPluginData(contentId, data);
    setQueryParam(Math.random());
  }, [contentId, data]);

  useWillUnmount(() => {
    updateContentPluginData(contentId, undefined);
  });

  const { contentPluginRenderComplete } = useFlux(sqPluginStore);

  useEffect(() => {
    if (loading) {
      setContentPluginRenderComplete(contentId, false);
    }
  }, [loading, contentId]);

  const pluginComplete = contentPluginRenderComplete[contentId];
  useEffect(() => {
    if (pluginComplete) {
      onPluginReady();
    }
  }, [pluginComplete, onPluginReady]);

  const createApi = (port: MessagePort) => generatePluginApi(contentId, pluginIdentifier, pluginWorkerApi(), port);

  return entryPoint ? (
    <PluginHost entryPoint={`${entryPoint}?forceContentReload=${queryParam}`} createApi={createApi} />
  ) : null;
};
