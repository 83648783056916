import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useDragLayer } from 'react-dnd';
import { MinimapHandle } from '@/scatterPlot/minimap/MinimapHandle.atom';
import { MINIMAP_HANDLE_LOWER, MINIMAP_HANDLE_UPPER } from '@/scatterPlot/minimap/MinimapHandleLane.molecule';
import { SCATTER_PLOT_COLORS } from '@/scatterPlot/scatterPlot.constants';

/**
 * Drag Layer for the minimap. This allows us to know where the handle is and perform actions to update the chart as
 * we're dragging a handle. Renders the handle symbol when we're dragging.
 */
export const MinimapDragLayer: React.FunctionComponent = () => {
  const { isDragging, item, delta } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    isDragging: monitor.isDragging(),
    delta: monitor.getDifferenceFromInitialOffset(),
  }));
  // Adjust handles to show at the same spot as the edge between the plotbands
  // The +25 is from the 25 px margin on the main minimap component
  const lowerHandleAdjustment = -4 + 25;
  const upperHandleAdjustment = -5 + 25;

  useEffect(() => {
    if (isDragging && item.dragAction && delta?.x) {
      item.dragAction(item.left + delta.x);
    }
  }, [item, isDragging, delta]);

  const renderItem = useCallback(() => {
    if (item?.type === 'scatterPlotMinimapHandle' && delta?.x) {
      let left: number = Math.round(item.left + delta.x);
      if (item.id === MINIMAP_HANDLE_LOWER) {
        left = _.max([left + lowerHandleAdjustment, 0])!;
        return (
          <a className="minimapHandleDragging" style={{ left }} data-testid={`${MINIMAP_HANDLE_LOWER}Dragging`}>
            <MinimapHandle color={SCATTER_PLOT_COLORS.MID} id={item.id} />
          </a>
        );
      } else if (item.id === MINIMAP_HANDLE_UPPER) {
        left += upperHandleAdjustment;
        return (
          <a className="minimapHandleDragging" style={{ left }} data-testid={`${MINIMAP_HANDLE_UPPER}Dragging`}>
            <MinimapHandle color={SCATTER_PLOT_COLORS.HIGH} id={item.id} />
          </a>
        );
      }
    }
    return null;
  }, [item, delta]);

  return renderItem();
};
