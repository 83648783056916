import React from 'react';
import { ErrorBoundaryWithLogging } from '@/core/ErrorBoundary.atom';
import { ErrorFallbackIcon } from '@/core/ErrorFallback.organism';
import { FluxContext } from './flux.context';
import { flux } from '@/core/flux.module';
import { LoadingFallback } from '@/main/LoadingFallback.atom';

/**
 * This component is the highest component of a branch of React components and is an ideal location to provide
 * contexts that are in use throughout the whole application.
 */
export const Root: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    // Wrap in Suspense to ensure translations are loaded before displaying the component
    <React.Suspense fallback={<LoadingFallback />}>
      <FluxContext.Provider value={flux}>
        <ErrorBoundaryWithLogging fallback={(error, reset) => <ErrorFallbackIcon error={error} retry={reset} />}>
          {children}
        </ErrorBoundaryWithLogging>
      </FluxContext.Provider>
    </React.Suspense>
  );
};

/**
 * This version is used in unit tests where the ErrorBoundary could undesirably suppress errors
 */
export const RootForTests: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <React.Suspense fallback={<LoadingFallback />}>
      <FluxContext.Provider value={flux}>{children}</FluxContext.Provider>
    </React.Suspense>
  );
};
