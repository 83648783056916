import { SeeqNames } from '@/main/app.constants.seeqnames';
import { getCsrfToken } from '@/utilities/auth.utilities';
import { generateRequestId, getOriginLabel, getOriginUrl } from '@/utilities/http.utilities';
import { Agent, agents, AssistantOrigin, ChatMessage, getDataLabAiAgents } from './aiAssistant.types';
import { isDataLabAiAssistant } from '@/main/routing.utilities';
import { aiAssistantEnabled, genAIAgents, genAIEnabled } from '@/services/systemConfiguration.utilities';
import { isSystemTest } from '@/core/utilities';
import { isMobileDevice } from '@/utilities/utilities';

function headers() {
  return {
    [SeeqNames.API.Headers.Csrf]: getCsrfToken(),
    [SeeqNames.API.Headers.RequestOrigin]: 'Analysis',
    [SeeqNames.API.Headers.RequestOriginURL]: getOriginUrl(),
    [SeeqNames.API.Headers.RequestOriginLabel]: getOriginLabel(),
    [SeeqNames.API.Headers.RequestId]: generateRequestId(),
  };
}

/**
 * Gets the Chats for the current user.
 *
 * @returns  A chronologically ordered dictionary of chat conversations
 */
export async function getChats(): Promise<
  Record<
    string,
    { messages: ChatMessage[]; origin?: AssistantOrigin; name?: string; sharedBy?: string; shared?: string }
  >
> {
  const response = await fetch('/genai/chats', { method: 'GET', headers: headers() });
  return await response.json();
}

export async function deleteChat(chatId: string): Promise<void> {
  await fetch(`/genai/chats?chat_id=${chatId}`, { method: 'DELETE', headers: headers() });
}

export function getFriendlyMarkup(id: string) {
  const element = document.getElementById(id);
  if (!element) return '';

  const range = document.createRange();
  range.selectNodeContents(element);
  const selection = window.getSelection();
  if (!selection) return '';

  selection.removeAllRanges();
  selection.addRange(range);
  const text = selection.toString();
  selection.removeAllRanges();
  return text;
}

export function copyToClipboard(id: string, onCopyFinish = () => {}, { clearSelection } = { clearSelection: true }) {
  const element = document.getElementById(id);
  if (!element) return;

  const range = document.createRange();
  range.selectNodeContents(element);
  const selection = window.getSelection();
  if (!selection) return;

  selection.removeAllRanges();
  selection.addRange(range);
  const success = document.execCommand('copy');

  if (clearSelection) selection.removeAllRanges();
  if (success) onCopyFinish();
}

export function getAgents(): Agent[] {
  const availableAgents = isDataLabAiAssistant() ? getDataLabAiAgents() : genAIAgents()?.toLowerCase();
  return agents.filter((agent) => availableAgents?.includes(agent.key));
}

export function getDefaultDisplayed(): boolean {
  return !isSystemTest() && !isMobileDevice() && aiAssistantEnabled() && genAIEnabled();
}
