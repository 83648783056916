import { debounce } from 'ts-debounce';

type DebouncedAsyncFunctionReturnType = ReturnType<typeof debounce>;
let outstandingDebouncedFunctions: DebouncedAsyncFunctionReturnType[] = [];

/**
 * Use this to debounce async functions. Functions debounced with this tool will be added to
 * {@link outstandingDebouncedFunctions}. To forcefully clear all of these outstanding functions, use
 * {@link clearOutstandingDebouncedAsyncFunctions}
 *
 * @param toDebounce - async function to debounce
 * @param delay - number of milliseconds to delay
 */
export function asyncDebounce<T>(
  toDebounce: (...initialArgs: any) => Promise<T>,
  delay: number,
): DebouncedAsyncFunctionReturnType {
  const debounced = debounce(toDebounce, delay, { maxWait: delay });
  addOutstandingDebouncedAsyncFunction(debounced);
  return debounced;
}

/**
 * Add debounced function to {@link outstandingDebouncedFunctions}. These can be all cleared by using
 * {@link clearOutstandingDebouncedAsyncFunctions}
 *
 * @param debouncedFunction - to add to {@link outstandingDebouncedFunctions}
 */
export function addOutstandingDebouncedAsyncFunction(debouncedFunction: DebouncedAsyncFunctionReturnType): void {
  outstandingDebouncedFunctions.push(debouncedFunction);
}

/**
 * This function will cancel and remove all outstanding functions still contained in
 * {@link outstandingDebouncedFunctions}.
 */
export function clearOutstandingDebouncedAsyncFunctions(): void {
  for (const fn of outstandingDebouncedFunctions) {
    fn.cancel();
  }
  outstandingDebouncedFunctions = [];
}
