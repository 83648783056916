// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { ColorPicker } from '@/workbook/ColorPicker.organism';
import { getFormFieldProps, getValidationFunction, canUseMemo } from '@/formbuilder/formbuilder.utilities';
import { Field, useForm } from 'react-final-form';

export interface ColorPickerIF extends ValidatingFormComponent<any> {
  component: 'ColorPickerFormComponent';
  placement: string;
  color: string;
  colors: string[];
  itemId: number;
  notifyOnSelect?: (itemId: number, color: string) => void;
  dataTestId?: string;
}

const ColorPickerFormComponentUnwrapped: React.FunctionComponent<ColorPickerIF> = (props) => {
  const { name, validation, extendValidation, extraClassNames } = props;

  const defaultValidation = (value) => _.isEmpty(value);
  const formState = useForm().getState();

  return (
    <Field name={name} validate={getValidationFunction(defaultValidation, extendValidation, validation)}>
      {({ input, meta }) => (
        <div className={extraClassNames}>
          <ColorPicker {...getFormFieldProps(formState, input, meta, props)} />
        </div>
      )}
    </Field>
  );
};

export const ColorPickerFormComponent = React.memo(ColorPickerFormComponentUnwrapped, canUseMemo);
