import mixpanel from 'mixpanel-browser';
import { TrackerService } from '@/track/tracker.service';
import _ from 'lodash';
import { TrackInformation } from '@/track/track.types';
import { seeqReleaseIdentifier, seeqVersion } from '@/services/systemConfiguration.utilities';

interface MixpanelTrackingProps {
  companyName: string;
  serverEmail: string;
  userEmail: string;
  userName: string;
  contractNumber: string;
  language: string;
  darkMode: boolean;
  role?: string;
  id: string;
}

export default class MixpanelTrackingService extends TrackerService {
  private static serviceInstance?: MixpanelTrackingService;

  dataToIncludeWithEveryEvent?: {
    companyName: string;
    serverEmail: string;
    userName: string;
    userEmail: string;
    contractNumber: string;
    seeqVersion: string | undefined;
    compositionDate: number | undefined;
    language: string;
    darkMode: boolean;
    role: string | undefined;
    userGuid: string | undefined;
  };

  constructor({
    companyName,
    serverEmail,
    userName,
    userEmail,
    contractNumber,
    language,
    darkMode,
    role,
    id,
  }: MixpanelTrackingProps) {
    super();

    if (MixpanelTrackingService.serviceInstance) {
      return MixpanelTrackingService.serviceInstance;
    }

    this.dataToIncludeWithEveryEvent = {
      serverEmail,
      userName,
      userEmail,
      contractNumber,
      companyName,
      seeqVersion: seeqVersion(),
      compositionDate: seeqReleaseIdentifier(),
      language,
      darkMode,
      role,
      userGuid: id,
    };

    mixpanel.init('40ec369f3886f9d8b7bfee0b048d2494', {
      api_host: 'https://telemetry.seeq.com/mixpanel',
    });

    mixpanel.people.set(this.dataToIncludeWithEveryEvent);

    MixpanelTrackingService.serviceInstance = this;
  }

  // we must ensure we have an email before calling identify or we will end up with lots of broken events
  identifyUser(userEmail: string) {
    mixpanel.identify(userEmail);
  }

  trackEvent(category: string, action: string, information: TrackInformation, uniqueEventId: number): void {
    const trackInfo = { action, uniqueEventId };
    let parsedInformation = information;
    try {
      parsedInformation = JSON.parse(information as string);
    } catch (e) {
      // ignore, strings are perfectly valid;
    }
    if (_.isObject(parsedInformation)) {
      _.assign(trackInfo, parsedInformation, this.dataToIncludeWithEveryEvent);
    } else {
      _.assign(trackInfo, { information }, this.dataToIncludeWithEveryEvent);
    }

    mixpanel.track(category, trackInfo);
  }

  reset() {
    delete MixpanelTrackingService.serviceInstance;
  }

  // this resets the mixpanel internals so that users can be properly identified
  resetTrackingData() {
    mixpanel.reset();
  }
}
