import { ToggleSwitch } from '@/core/ToggleSwitch.atom';
import React from 'react';
import { Icon } from '@seeqdev/qomponents';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { sqSearchStore, sqWorkbenchStore } from '@/core/core.stores';

export interface DatasourcePreferencesToggleProps {
  on: boolean;
  onChange: () => void;
  formattedLabel: string;
  onClick: () => void;
  extraClassNames?: string;
}

export const DatasourcePreferencesToggle: React.FC<DatasourcePreferencesToggleProps> = ({
  on,
  onChange,
  formattedLabel,
  onClick,
  extraClassNames,
}) => {
  const { datasources } = useFlux(sqSearchStore);
  const { includeNewDatasources, userDatasources } = useFlux(sqWorkbenchStore);

  const userHasCustomizedDatasourcePreferences =
    (userDatasources.length > 1 && includeNewDatasources) ||
    (userDatasources.length < datasources.length - 1 && !includeNewDatasources);
  return (
    <div
      data-testid="datasourcePreferencesToggleAndGear"
      className={`flexColumnContainer flexAlignCenter flexJustifyEnd ${extraClassNames}`}>
      {userHasCustomizedDatasourcePreferences && (
        <ToggleSwitch
          testId="datasourcePreferencesToggle"
          isOn={on}
          onChange={onChange}
          formattedLabel={<span className="force-link-color">{formattedLabel}</span>}
        />
      )}
      <div onClick={onClick} className="cursorPointer ml7">
        <Icon icon="fc-gear" large={true} />
      </div>
    </div>
  );
};
