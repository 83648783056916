// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FormControl } from 'react-bootstrap';
import {
  getFormFieldProps,
  getFormFieldWrapperProps,
  getValidationFunction,
} from '@/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';

export interface FormControlIF extends ValidatingFormComponent<string | number> {
  component: 'FormControlFormComponent';
  id?: string;
  size: string;
  placeholder?: string;
  autoComplete?: string;
  type?: string;
  step?: number;
  maxLength?: number;
  minLength?: number;
  max?: number;
  min?: number;
  rows?: number;
  as?: string;
  onBlur?: (value: string) => void;
  onFocus?: (value: string) => void;
  onKeyDown?: (key: string, value: string) => void;
  disabled?: boolean;
  skipMemo?: boolean;
  autoFocus?: boolean;
  displayError?: boolean;
  customErrorText?: string;
  labelIcon?: string;
  labelIconAction?: () => void;
  labelIconTooltip?: string;
}

export const FormControlFormComponent: React.FunctionComponent<FormControlIF> = (props) => {
  const {
    name,
    extendValidation,
    validation,
    placeholder,
    onChange,
    onBlur,
    onKeyDown,
    type = 'text',
    step,
    testId = 'formControl',
    required,
    minLength,
    maxLength,
    customErrorText,
    labelIcon,
    labelIconAction,
    labelIconTooltip,
  } = props;

  const { t } = useTranslation();

  const defaultValidation = (value: string) =>
    (required && _.isEmpty(_.trim(value))) ||
    (minLength && value?.length < minLength) ||
    (maxLength && value?.length > maxLength);

  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);
  const formState = useForm().getState();
  const showError =
    _.has(formState.errors, name) &&
    formState.hasValidationErrors &&
    (formState.modified[name] || formState.submitFailed);

  return (
    <FormFieldWrapper
      {...getFormFieldWrapperProps(props, ['id'])}
      testId={`${testId}-wrap`}
      labelIcon={labelIcon}
      labelIconAction={labelIconAction}
      labelIconTooltip={labelIconTooltip}
      showError={showError}
      customErrorText={customErrorText}>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          const properProps = _.assign({}, getFormFieldProps(formState, input, meta, props), {
            placeholder: t(placeholder),
            value: input.value,
            onChange: (e) => {
              input.onChange(e.target.value);
              onChange?.(e.target.value);
            },
            onBlur: (e) => _.isFunction(onBlur) && onBlur(e.target.value),
            onKeyDown: (e) => onKeyDown?.(e.key, e.target.value),
            onFocus: (e) => e.target.select(),
          });

          return (
            <FormControlMemo
              {..._.omit(properProps, [
                'fromFormBuilder',
                'onTooltipClick',
                'testId',
                'component',
                'elementType',
                'extraClassNames',
                'includeIf',
                'customErrorParams',
                'customErrorText',
                'extendValidation',
                'validation',
                'showError',
                'fixedWidth',
                'fieldNames',
                'displayNumber',
                'label',
                'number',
                'required',
                'maxLength',
                'minLength',
                'skipMemo',
                'onIconClick',
                'wrapperClassNames',
              ])}
              data-testid={testId}
              type={type}
              step={step}
              required={properProps.showError && properProps.required}
            />
          );
        }}
      </Field>
    </FormFieldWrapper>
  );
};

export const FormControlMemo = React.memo(
  FormControl,
  (prev, next) =>
    !(
      prev.onChange !== next.onChange ||
      !_.isEqual(prev.value, next.value) ||
      prev.disabled !== next.disabled ||
      prev.placeholder !== next.placeholder ||
      prev.className !== next.className ||
      prev.skipMemo
    ),
);
