import { SeeqError } from '../../../plugin/sdk/seeq';

/**
 * Shapes an error response into a SeeqError that is expected by the plugin.
 *
 * @param {Object} response - the response to be formatted
 */
export function formatAsSeeqError(response: any): SeeqError {
  const seeqError: SeeqError = { data: { statusMessage: '' } };

  // Simple string error message
  if (seeqError.data.statusMessage === '' && typeof response === 'string') {
    seeqError.data.statusMessage = response;
  }

  // Standard Seeq API error Message
  if (seeqError.data.statusMessage === '' && typeof response?.statusMessage === 'string') {
    seeqError.data.statusMessage = response.statusMessage;
  }

  // Exception message
  if (seeqError.data.statusMessage === '' && typeof response?.message === 'string') {
    seeqError.data.statusMessage = response.message;
  }

  // Standard fetch error response
  if (seeqError.data.statusMessage === '' && typeof response?.error?.message === 'string') {
    seeqError.data.statusMessage = response.error.message;
  }
  if (seeqError.status === undefined && typeof response?.status === 'number') {
    seeqError.status = response.status;
  }
  if (seeqError.data.errorType === undefined && typeof response?.error?.name === 'string') {
    seeqError.data.errorType = response.error.name;
  }

  return seeqError;
}

/**
 * Gets a properly formatted x-sq-csrf header from a cookie
 */
export function getCsrfHeaderFromCookie(cookie = ''): { 'x-sq-csrf': string } | Record<string, never> {
  return (
    cookie
      .split('; ')
      .map((row) => row.split('='))
      .filter((nameValue) => nameValue[0].includes('sq-csrf'))
      .map((nameValue) => ({ 'x-sq-csrf': nameValue[1] }))
      .shift() || {}
  );
}

/**
 * Returns a path string where leading and trailing slashes/whitespace has been removed and each part of the path
 * has been URI encoded
 *
 * @param path - the path to processed
 */
export function trimAndEncodePath(path: string) {
  return (
    path
      ?.trim()
      ?.split('/')
      ?.filter((s: string) => s !== '')
      ?.map(encodeURIComponent)
      ?.join('/') || ''
  );
}

/**
 * Common response handler for web worker fetch requests. Posts a success or failure message, unless the
 * optional returnSuccessResponse parameter is true, in which case it will just return the success response.
 * Supports standard and Seeq json formats, as well as plain text and html.
 *
 * @param requestId - the request ID
 * @param worker - the web worker
 * @param fetch - the fetch function
 * @param [returnSuccessResponse = false] - true to return the success response instead of posting it; false otherwise
 */
export function handleResponse(
  requestId: string,
  worker: Worker,
  fetch: () => Promise<Response>,
  returnSuccessResponse = false,
) {
  return fetch()
    .then((response) => {
      const contentType = response.headers.get('Content-Type') || '';

      if (contentType.includes('application/json') || contentType.includes('application/vnd.seeq.v1+json')) {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((httpError) => {
            const error = { status: response.status, ...httpError };
            worker.postMessage({ requestId, isError: true, payload: formatAsSeeqError(error) });
          });
        }
      } else if (contentType.includes('text/plain') || contentType.includes('text/html')) {
        if (response.ok) {
          return response.text();
        } else {
          return response.text().then((httpError) => {
            const error = { status: response.status, statusMessage: httpError };
            worker.postMessage({ requestId, isError: true, payload: formatAsSeeqError(error) });
          });
        }
      } else {
        const error = `Unsupported content type: ${contentType}`;
        worker.postMessage({ requestId, isError: true, payload: formatAsSeeqError(error) });
      }
    })
    .then((response) => {
      if (returnSuccessResponse) {
        return response;
      } else {
        worker.postMessage({ requestId, payload: response });
      }
    })
    .catch((error) => {
      worker.postMessage({ requestId, isError: true, payload: formatAsSeeqError(error) });
    });
}
