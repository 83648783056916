import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { Icon, Select } from '@seeqdev/qomponents';
import { Checkbox } from '@/core/Checkbox.atom';
import { TABLE_BUILDER } from '@/tableBuilder/tableBuilder.constants';
import { doTrack } from '@/track/track.service';
import { COLUMNS_AND_STATS } from '@/trendData/trendData.constants';
import { setShowChartView } from '@/tableBuilder/tableBuilder.actions';
import { ReactSelectOption } from '@/core/IconSelect.molecule';

interface ChartSettingsProps {
  settings?: ChartSettingsInterface;
  changeCallback: (settings: Record<string, string | undefined | any[]>) => void;
  seriesNamesOptions: any[];
  xAxisOptions: any[];
  yAxisOptions: any[];
}

export interface ChartSettingsInterface {
  title: string;
  legend: boolean;
  dataLabels: boolean;
  categoryLabels: boolean;
  chartType?: string;
  categoryColumns: string[];
  rows: string[];
  columns: string[];
  showSettings?: boolean;
  position?: { x: number; y: number };
  stacked?: boolean;
}

export const defaultSettings = {
  title: '',
  legend: true,
  dataLabels: true,
  categoryLabels: true,
  rows: [],
  columns: [],
  chartType: undefined,
  categoryColumns: [COLUMNS_AND_STATS.name.key],
  showSettings: false,
  position: { x: 100, y: 100 },
};

export const ChartSettings: React.FunctionComponent<ChartSettingsProps> = ({
  settings,
  changeCallback,
  seriesNamesOptions,
  xAxisOptions,
  yAxisOptions,
}) => {
  const { t } = useTranslation();

  const setShowLegend = (showLegend?: string) => {
    changeCallback({ legend: showLegend });
    doTrack(TABLE_BUILDER, 'legend', showLegend);
  };

  const setShowDataLabels = (showDataLabels: string | undefined) => {
    changeCallback({ dataLabels: showDataLabels });
    doTrack(TABLE_BUILDER, 'data', showDataLabels);
  };

  const setShowCategoryLabels = (showCategoryLabels: string | undefined) => {
    changeCallback({ categoryLabels: showCategoryLabels });
    doTrack(TABLE_BUILDER, 'category', showCategoryLabels);
  };

  const setSelectedRows = (selectedOptions: ReactSelectOption[]) => {
    changeCallback({
      rows: _.map(selectedOptions, 'value'),
    });
    doTrack(TABLE_BUILDER, 'changed series');
  };

  const setSelectedColumns = (selectedOptions: ReactSelectOption[]) => {
    changeCallback({
      columns: _.map(selectedOptions, 'value'),
    });
    doTrack(TABLE_BUILDER, 'changed categories');
  };

  const setChartType = (selectedChartType: string | undefined) => {
    changeCallback({ chartType: selectedChartType });
    doTrack(TABLE_BUILDER, 'show chart view', selectedChartType);
  };

  const setCategoryColumns = (selectedOptions: readonly ReactSelectOption[]) => {
    changeCallback({
      categoryColumns: _.map(selectedOptions, (option) => option.value),
    });
    doTrack(TABLE_BUILDER, 'changed where to get series name');
  };

  const showCharts = () => {
    setShowChartView(true);
  };

  const showTableView = () => {
    setShowChartView(false);
    setChartType(undefined);
    doTrack(TABLE_BUILDER, 'return to table view');
  };

  const chartIcons = [
    { icon: 'fc-bar-chart', type: 'column', tooltip: 'SHOW_COLUMN_CHART' },
    {
      icon: 'fc-stacked-bar-chart',
      type: 'column_stacked',
      tooltip: 'SHOW_STACKED_COLUMN_CHART',
    },
    { icon: 'fa-pie-chart', type: 'pie', tooltip: 'SHOW_PIE_CHART' },
    { icon: 'fc-bar-chart-horizontal', type: 'bar', tooltip: 'SHOW_BAR_CHART' },
    {
      icon: 'fc-stacked-bar-horizontal',
      type: 'bar_stacked',
      tooltip: 'SHOW_STACKED_BAR_CHART',
    },
  ];

  return (
    <div className="settingsContainer">
      <div className="settingsIcons mb10">
        <div className="flexColumnContainer flexAlignCenter flexSpaceBetween">
          <Icon
            id="chartTable"
            extraClassNames={classNames('p5 textPrimaryChartSettings', {
              'sq-bg-theme-dark white': _.isUndefined(settings?.chartType),
            })}
            icon="fc-table fa-xlg"
            testId="table-icon"
            tooltip={t('TABLE_BUILDER.CHART_VIEW_SETTINGS.SHOW_TABLE')}
            tooltipPlacement="top"
            onClick={showTableView}
          />

          {_.map(chartIcons, (iconType) => (
            <Icon
              extraClassNames={classNames('p5 textPrimaryChartSettings', {
                'sq-bg-theme-dark alwaysWhite': settings?.chartType === iconType.type,
              })}
              icon={classNames(iconType.icon, 'fa-xlg')}
              tooltip={t(`TABLE_BUILDER.CHART_VIEW_SETTINGS.${iconType.tooltip}`)}
              tooltipPlacement="top"
              testId={`${iconType.type}_testId`}
              key={iconType.type}
              onClick={() => {
                showCharts();
                setChartType(iconType.type);
              }}
            />
          ))}
        </div>
      </div>
      <div className="mb10 flexColumnContainer flexSpaceBetween">
        <Checkbox
          id="chartShowLegend"
          key="chartShowLegend"
          isChecked={settings?.legend ?? false}
          onChange={(e) => setShowLegend(e.target.checked)}
          label="TABLE_BUILDER.CHART_VIEW_SETTINGS.SHOW_LEGEND"
        />
        <Checkbox
          id="chartShowDataLabels"
          key="chartShowDataLabels"
          isChecked={settings?.dataLabels ?? false}
          onChange={(e) => setShowDataLabels(e.target.checked)}
          label="TABLE_BUILDER.CHART_VIEW_SETTINGS.SHOW_DATA_LABELS"
        />
        <Checkbox
          id="chartShowXLabels"
          key="chartShowCategoryLabels"
          isChecked={settings?.categoryLabels ?? false}
          onChange={(e) => setShowCategoryLabels(e.target.checked)}
          label="TABLE_BUILDER.CHART_VIEW_SETTINGS.SHOW_CATEGORY_LABELS"
        />
      </div>
      <div className="mb10">
        <div className="multiSelectTwoLine" data-testid="seriesNamesSelector">
          <label>{t('TABLE_BUILDER.CHART_VIEW_SETTINGS.SERIES_NAME')}</label>
          <Select
            isSearchable={true}
            isMulti={true}
            value={_.map(settings?.categoryColumns, (index) => _.find(seriesNamesOptions, { value: index }))}
            options={seriesNamesOptions}
            onChange={setCategoryColumns}
            key="chartViewSeriesNamesSelector"
          />
        </div>
      </div>
      <div className="mb10">
        <div className="multiSelectTwoLine" data-testid="categoriesSelector">
          <label>{t('TABLE_BUILDER.CHART_VIEW_SETTINGS.X_AXIS')}</label>
          <Select
            isSearchable={true}
            isMulti={true}
            value={_.map(settings?.columns, (index) => _.find(xAxisOptions, { value: index }))}
            options={xAxisOptions}
            onChange={setSelectedColumns}
            key="chartViewXAxisSelector"
          />
        </div>
      </div>
      <div className="mb10">
        <label>{t('TABLE_BUILDER.CHART_VIEW_SETTINGS.Y_AXIS')}</label>
        <div className="multiSelectTwoLine" data-testid="seriesSelector">
          <Select
            isSearchable={true}
            isMulti={true}
            value={_.map(settings?.rows, (index) => _.find(yAxisOptions, { value: index }))}
            options={yAxisOptions}
            onChange={setSelectedRows}
            extraClassNames="chartViewYAxisSelector"
            key="chartViewYAxisSelector"
          />
        </div>
      </div>
    </div>
  );
};
