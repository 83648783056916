import { ITEM_TYPES, SAMPLE_OPTIONS } from '@/trendData/trendData.constants';
import { FormatOptions } from '@/utilities/numberHelper.utilities';
import { UnitOfMeasure } from '@/utilities/unit.utilities';

export const POINT_MARKER_SIZE = 4;

export const ZERO_CAPSULE_TOLERANCE = 10;
export const LABEL_X_OFFSET = 4;
export const LABEL_CAPSULE_LINE_HEIGHT = 6;
export const Z_INDEX = 10001;

export interface CursorPoint {
  stringEnum: [{ key: number; stringValue: string }];
  isStringSeries: boolean;
  sampleDisplayOption: SAMPLE_OPTIONS;
  labelText: string;
  showIndicator: boolean;
  unitOfMeasure: UnitOfMeasure;
  yPixel: number;
  yValue: number;
  circle: Highcharts.SVGElement;
  label: Highcharts.SVGElement;
  color: string;
  formatOptions: FormatOptions;
  itemId: string;
  capsuleSetId: string;
  itemType: ITEM_TYPES;
  lane: number;
}

export interface PointerYValue {
  capsuleSetId: string;
  lane: number;
  itemType: ITEM_TYPES;
  stringEnum: [{ key: number; stringValue: string }];
  isStringSeries: boolean;
  formatOptions: { format: string };
  color: string;
  closestPoint: number[];
  id: string;
  interestId?: string;
  pointValue: number | string;
  labelText?: string;
  showIndicator: boolean;
  unitOfMeasure?: UnitOfMeasure;
  sampleDisplayOption: SAMPLE_OPTIONS;
}

/**
 @typedef BasicCursor
 @property xValue - x-value of the cursor location
 @property xPixel - x-pixel location of the cursor
 @property points - Object map of all items for this cursor, with the item id as the key
 @property {Array} points[id] - array of all the points associated with this item
 @property {Number} points[id][].yValue - y-value for the id specified at the cursor location
 @property {Number} points[id][].yPixel - y-pixel for the id specified at the cursor location
 @property {SVGElement} points[id][].circle - SVG element for the point indicator for the id specified
 @property {SVGElement} points[id][].label - SVG element for the point flag for the id specified
 */
export interface BasicCursor {
  xValue: number;
  xPixel: number;
  name?: string;
  selected?: boolean;
  points: Record<string, CursorPoint[]>;
  yValues: PointerYValue[];
}

/**
 @typedef Cursor
 @property crosshair - SVG element for the vertical crosshair
 @property anchor - SVG element for the named anchor
 @property xLabel - SVG element for the x-label flag
 */
export interface Cursor extends BasicCursor {
  crosshair?: Highcharts.SVGElement;
  anchor?: Highcharts.SVGElement;
  xLabel?: Highcharts.SVGElement;
  deleteIcon?: Highcharts.SVGElement;
}

export const DEFAULT_CURSOR_VALUES: Cursor = {
  yValues: [],
  anchor: undefined,
  crosshair: undefined,
  deleteIcon: undefined,
  points: {},
  xLabel: undefined,
  xPixel: 0,
  xValue: 0,
};
