import _ from 'lodash';
import {
  DailyScheduleType,
  Day,
  DayOfTheWeek,
  MonthlyScheduleTypeName,
  ScheduleType,
  ScheduleTypeName,
} from '@/schedule/schedule.types';
import { areAllElementsANumber, defaultScheduleType } from '@/schedule/schedule.utilities';
import { QuartzCronExpressionHelper } from '@/annotation/reportContent.utilities';

interface IScheduleParser {
  /**
   * Determines if the provided schedule corresponds to the schedule type
   *
   * @param cronSchedule - the cronSchedule to check
   * @param quartzCronExpressionHelper - an instance of the {@link quartCronExpressionHelper}
   *
   * @returns - true the provided schedule map to the schedule type; false otherwise
   */
  isType: (cronSchedule: string, quartzCronExpressionHelper: QuartzCronExpressionHelper) => boolean;

  /**
   * Checks if all of the schedules correspond to the schedule type, differing only in time entries
   *
   * @param cronSchedules - the cron schedules to check
   * @param quartzCronExpressionHelper - an instance of the {@link quartCronExpressionHelper}
   *
   * @returns - true the provided all schedules map to the schedule typea; false otherwise
   */
  allMatchesType: (cronSchedules: string[], quartzCronExpressionHelper: QuartzCronExpressionHelper) => boolean;

  /**
   * Generates the state for the schedule type
   *
   * @param cronSchedules - the cron schedules used to initialize the schedule
   * @param quartzCronExpressionHelper - an instance of the {@link quartCronExpressionHelper}
   *
   * @returns - object that describes non-live schedules
   * @throws {Error} if there's an error parsing the schedule
   */
  initializeSchedule: (cronSchedules: string[], quartzCronExpressionHelper: QuartzCronExpressionHelper) => ScheduleType;
}

export class ScheduleParser {
  static SecondMinuteOrHourly: IScheduleParser = {
    isType(cronSchedule, quartzCronExpressionHelper) {
      const cronData = quartzCronExpressionHelper.parse(cronSchedule);
      const { daysOfMonth, months, daysOfWeek, years } = cronData;

      if (_.isEmpty(cronData) || !_.isNil(years)) {
        return false;
      }

      // Seconds (Every 10 seconds)   0/10 * * * * ?
      // Minutes (Every 15 minutes)   0 0/15 * * * ?
      // Hourly (Every 2 hours)       0 0 */2 * * ?
      return daysOfMonth.join(',') === '*' && months.join(',') === '*' && daysOfWeek.join(',') === '?';
    },

    allMatchesType(cronSchedules, quartzCronExpressionHelper) {
      return _.every(cronSchedules, (schedule) =>
        ScheduleParser.SecondMinuteOrHourly.isType(schedule, quartzCronExpressionHelper),
      );
    },

    initializeSchedule(cronSchedule, quartzCronExpressionHelper) {
      return _.merge(defaultScheduleType(), {
        selectedType: ScheduleTypeName.LIVE,
      });
    },
  };

  static Daily: IScheduleParser = {
    isType(cronSchedule, quartzCronExpressionHelper) {
      const cronData = quartzCronExpressionHelper.parse(cronSchedule);
      const { seconds, daysOfMonth, months, daysOfWeek, years } = cronData;
      const EVERY_DAY: string[] = _.map(quartzCronExpressionHelper.EVERY_DAY, (ele) => ele.toString());

      if (_.isEmpty(cronData) || seconds.join(',') !== '0' || !_.isNil(years)) {
        return false;
      }

      //  Daily (Every day)                         0   0   8   ?   *   1-7   *
      return daysOfMonth.join(',') === '?' && months.join(',') === '*' && _.isEqual(EVERY_DAY, daysOfWeek);
    },

    allMatchesType(cronSchedules, quartzCronExpressionHelper) {
      return _.every(cronSchedules, (schedule) => ScheduleParser.Daily.isType(schedule, quartzCronExpressionHelper));
    },

    initializeSchedule(cronSchedule, quartzCronExpressionHelper) {
      return _.merge(defaultScheduleType(), {
        selectedType: ScheduleTypeName.DAILY,
        data: {
          [ScheduleTypeName.DAILY]: DailyScheduleType.EVERY_DAY,
        },
      });
    },
  };

  static Weekdays: IScheduleParser = {
    isType(cronSchedule, quartzCronExpressionHelper) {
      const cronData = quartzCronExpressionHelper.parse(cronSchedule);
      const { seconds, daysOfMonth, months, daysOfWeek, years } = cronData;
      const EVERY_WEEKDAY: string[] = _.map(quartzCronExpressionHelper.EVERY_WEEKDAY, (ele) => ele.toString());

      if (_.isEmpty(cronData) || seconds.join(',') !== '0' || !_.isNil(years)) {
        return false;
      }

      //  Daily (Every weekday)                     0   0   8   ?   *   2-6   *
      return daysOfMonth.join(',') === '?' && months.join(',') === '*' && _.isEqual(EVERY_WEEKDAY, daysOfWeek);
    },

    allMatchesType(cronSchedules, quartzCronExpressionHelper) {
      return _.every(cronSchedules, (schedule) => ScheduleParser.Weekdays.isType(schedule, quartzCronExpressionHelper));
    },

    initializeSchedule(cronSchedule, quartzCronExpressionHelper) {
      return _.merge(defaultScheduleType(), {
        selectedType: ScheduleTypeName.DAILY,
        data: {
          [ScheduleTypeName.DAILY]: DailyScheduleType.EVERY_WEEKDAY,
        },
      });
    },
  };

  static Weekly: IScheduleParser = {
    isType(cronSchedule, quartzCronExpressionHelper) {
      const cronData = quartzCronExpressionHelper.parse(cronSchedule);
      const { seconds, daysOfMonth, months, daysOfWeek, years } = cronData;
      if (_.isEmpty(cronData) || seconds.join(',') !== '0' || !_.isNil(years)) {
        return false;
      }

      //  Weekly (specific days):                   0   0   8   ?   *   1,2   *
      return (
        daysOfMonth.join(',') === '?' &&
        months.join(',') === '*' &&
        daysOfWeek.length > 0 &&
        areAllElementsANumber(daysOfWeek)
      );
    },

    allMatchesType(cronSchedules, quartzCronExpressionHelper) {
      const isSameScheduleType = _.every(cronSchedules, (schedule) =>
        ScheduleParser.Weekly.isType(schedule, quartzCronExpressionHelper),
      );
      if (!isSameScheduleType) return false;

      const cronDataForAllSchedules = _.map(cronSchedules, (schedule) => quartzCronExpressionHelper.parse(schedule));
      const matchDaysOfWeek = cronDataForAllSchedules[0].daysOfWeek;
      return _.reduce(
        cronDataForAllSchedules,
        (result, { daysOfWeek }) => result && _.isEqual(daysOfWeek, matchDaysOfWeek),
        true,
      );
    },

    initializeSchedule(cronSchedule, quartzCronExpressionHelper) {
      const cronData = quartzCronExpressionHelper.parse(cronSchedule[0]);
      const { daysOfWeek } = cronData;
      const days = {
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
      };

      const CRON_TO_DAY_OF_THE_WEEK: Record<string, Day> = {
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.SUN]: 'sunday',
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.MON]: 'monday',
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.TUE]: 'tuesday',
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.WED]: 'wednesday',
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.THU]: 'thursday',
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.FRI]: 'friday',
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.SAT]: 'saturday',
      };

      _.forEach(daysOfWeek, (day) => {
        if (!(_.toInteger(day) in CRON_TO_DAY_OF_THE_WEEK)) {
          return false;
        }
        days[CRON_TO_DAY_OF_THE_WEEK[_.toInteger(day)]] = true;
      });

      return _.merge(defaultScheduleType(), {
        selectedType: ScheduleTypeName.WEEKLY,
        data: {
          [ScheduleTypeName.WEEKLY]: {
            ...days,
          },
        },
      });
    },
  };

  static MonthlyByDaysOfWeek: IScheduleParser = {
    isType(cronSchedule, quartzCronExpressionHelper) {
      const cronData = quartzCronExpressionHelper.parse(cronSchedule);
      const { seconds, daysOfMonth, months, daysOfWeek, years } = cronData;
      if (_.isEmpty(cronData) || seconds.join(',') !== '0' || !_.isNil(years)) {
        return false;
      }

      //  Monthly (nth day of week every n months): 0   0   8   ?   1/2 1#1   *
      return !!(
        daysOfMonth.join(',') === '?' &&
        months.join(',').match(/^(\*|\d\/\d+)$/) &&
        daysOfWeek.join(',').match(/^\d#\d$/)
      );
    },

    allMatchesType(cronSchedules, quartzCronExpressionHelper) {
      const isSameScheduleType = _.every(cronSchedules, (schedule) =>
        ScheduleParser.MonthlyByDaysOfWeek.isType(schedule, quartzCronExpressionHelper),
      );
      if (!isSameScheduleType) return false;

      const cronDataForAllSchedules = _.map(cronSchedules, (schedule) => quartzCronExpressionHelper.parse(schedule));
      const matchMonths = cronDataForAllSchedules[0].months;
      const matchDaysOfWeek = cronDataForAllSchedules[0].daysOfWeek;
      return _.reduce(
        cronDataForAllSchedules,
        (result, { months, daysOfWeek }) =>
          result && _.isEqual(months, matchMonths) && _.isEqual(daysOfWeek, matchDaysOfWeek),
        true,
      );
    },

    initializeSchedule(cronSchedule, quartzCronExpressionHelper) {
      const cronData = quartzCronExpressionHelper.parse(cronSchedule[0]);
      const { months, daysOfWeek } = cronData;
      const [cronDayOfWeek, nth] = daysOfWeek[0].split('#');
      const [startingMonth, repeatEveryNMonths = 1] = _.map(months[0].split('/'), (ele) => _.toInteger(ele));
      const CRON_TO_DAY_OF_THE_WEEK = {
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.SUN]: DayOfTheWeek.SUNDAY,
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.MON]: DayOfTheWeek.MONDAY,
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.TUE]: DayOfTheWeek.TUESDAY,
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.WED]: DayOfTheWeek.WEDNESDAY,
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.THU]: DayOfTheWeek.THURSDAY,
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.FRI]: DayOfTheWeek.FRIDAY,
        [quartzCronExpressionHelper.CRON_DAYS_OF_WEEK.SAT]: DayOfTheWeek.SATURDAY,
      };

      const day: keyof typeof CRON_TO_DAY_OF_THE_WEEK = _.toInteger(cronDayOfWeek);
      if (!(day in CRON_TO_DAY_OF_THE_WEEK)) {
        throw new Error('Unable to parse the days of the week from the schedule');
      }

      const dayOfWeek = CRON_TO_DAY_OF_THE_WEEK[day];

      return _.merge(defaultScheduleType(), {
        selectedType: ScheduleTypeName.MONTHLY,
        data: {
          [ScheduleTypeName.MONTHLY]: {
            selectedType: MonthlyScheduleTypeName.BY_DAY_OF_WEEK,
            data: {
              [MonthlyScheduleTypeName.BY_DAY_OF_WEEK]: {
                nth: _.toInteger(nth),
                dayOfWeek,
                numberOfMonths: repeatEveryNMonths,
              },
            },
          },
        },
      });
    },
  };

  static MonthlyByDaysOfMonth: IScheduleParser = {
    isType(cronSchedule, quartzCronExpressionHelper) {
      const cronData = quartzCronExpressionHelper.parse(cronSchedule);
      const { seconds, daysOfMonth, months, daysOfWeek, years } = cronData;
      if (_.isEmpty(cronData) || seconds.join(',') !== '0' || !_.isNil(years)) {
        return false;
      }

      //  Monthly (day of month d every m months):  0   0   8   9   1/2 ?     *
      return !!(
        daysOfMonth.length === 1 &&
        daysOfMonth.join(',').match(/^\d+$/) &&
        months.join(',').match(/^(\*|\d\/\d+)$/) &&
        daysOfWeek.join(',') === '?'
      );
    },

    allMatchesType(cronSchedules, quartzCronExpressionHelper) {
      const isSameScheduleType = _.every(cronSchedules, (schedule) =>
        ScheduleParser.MonthlyByDaysOfMonth.isType(schedule, quartzCronExpressionHelper),
      );
      if (!isSameScheduleType) return false;

      const cronDataForAllSchedules = _.map(cronSchedules, (schedule) => quartzCronExpressionHelper.parse(schedule));
      const matchDaysOfMonth = cronDataForAllSchedules[0].daysOfMonth;
      const matchMonths = cronDataForAllSchedules[0].months;
      return _.reduce(
        cronDataForAllSchedules,
        (result, { daysOfMonth, months }) =>
          result && _.isEqual(daysOfMonth, matchDaysOfMonth) && _.isEqual(months, matchMonths),
        true,
      );
    },

    initializeSchedule(cronSchedule, quartzCronExpressionHelper) {
      const cronData = quartzCronExpressionHelper.parse(cronSchedule[0]);
      const { daysOfMonth, months } = cronData;
      // This will need to be adjusted if we support multiple days of the month in the future
      const day = _.toInteger(daysOfMonth[0]);
      const [startingMonth, repeatEveryNMonths = 1] = _.map(months[0].split('/'), (ele) => _.toInteger(ele));

      return _.merge(defaultScheduleType(), {
        selectedType: ScheduleTypeName.MONTHLY,
        data: {
          [ScheduleTypeName.MONTHLY]: {
            selectedType: MonthlyScheduleTypeName.BY_DAY_OF_MONTH,
            data: {
              [MonthlyScheduleTypeName.BY_DAY_OF_MONTH]: {
                day,
                numberOfMonths: repeatEveryNMonths,
              },
            },
          },
        },
      });
    },
  };

  /**
   * Uses various information extracted from a cron schedule to populate the state used for the UI
   * NOTE: This function only has limited support for cron schedules. The function and helpers will
   * take arrays so that functionality can be expanded in the future.
   *
   * @param {string[]} cronSchedules - all cron expression strings for schedule
   *
   * @returns {@link ScheduleType} object that describes non-live schedules
   * @throws {Error} if there's an error parsing the schedule
   */
  static determineScheduleType(
    cronSchedules: string[],
    quartzCronExpressionHelper: QuartzCronExpressionHelper,
  ): ScheduleType {
    const scheduleParsers: IScheduleParser[] = [
      ScheduleParser.SecondMinuteOrHourly,
      ScheduleParser.Daily,
      ScheduleParser.Weekdays,
      ScheduleParser.Weekly,
      ScheduleParser.MonthlyByDaysOfWeek,
      ScheduleParser.MonthlyByDaysOfMonth,
    ];

    // Since multiple cron schedules are supported, we only treat the schedule as valid if all cron schedules match
    // that specific type of schedule
    const initializedSchedule = _.chain(scheduleParsers)
      .map((parser) => {
        if (parser.allMatchesType(cronSchedules, quartzCronExpressionHelper)) {
          return parser.initializeSchedule(cronSchedules, quartzCronExpressionHelper);
        } else {
          return undefined;
        }
      })
      .reject((type) => _.isUndefined(type))
      .head()
      .value();

    if (initializedSchedule) return initializedSchedule;

    throw new Error('Unable to parse the schedule');
  }
}
