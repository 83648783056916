import _ from 'lodash';
import HttpCodes from 'http-status-codes';
import { errorToast } from '@/utilities/toast.utilities';
import { isCanceled } from '@/utilities/http.utilities';
import { getWorkstep, pushWorkstep, WorkstepOutput } from '@/worksteps/worksteps.utilities';
import { WORKSTEP_PUSH_DISABLED } from '@/worksteps/worksteps.constant';
import { flux } from '@/core/flux.module';
import { sqWorkstepsStore } from '@/core/core.stores';
import { PUSH_IGNORE } from '@/core/flux.service';

/**
 * Pushes worksheet state on the workstep queue
 *
 * @param workbookId - The workbook id to push.
 * @param worksheetId - The worksheet id to push.
 * @param worksheetState An object containing the current state of the worksheet
 * @returns {Promise} A promise that resolves once the worksheet state has been pushed as a workstep
 */
export function pushWorkstepAction(workbookId: string, worksheetId: string, worksheetState: object) {
  return pushWorkstep(workbookId, worksheetId, worksheetState)
    .then((response) => {
      flux.dispatch('WORKSTEPS_SET', _.pick(response, 'previous', 'current', 'next', 'last'), PUSH_IGNORE);
      return response;
    })
    .catch((error) => {
      if (error !== WORKSTEP_PUSH_DISABLED) {
        // Push failed, so clear store
        clearWorkstepAction(_.get(error, 'status'), !isCanceled(error));
      }

      return Promise.reject(error);
    });
}

/**
 * Clears the worksteps store, with an optional error flag. Displays a notification when first transitioning to an
 * error state and the status code is not 403. Does not display a notification when the response is 403 because
 * the HttpHelpers forbiddenInterceptor automatically routes to the unauthorized route. Also does not display a
 * notification for 404 errors since it means the worksheet is gone.
 *
 * @param isError - True if worksteps are being cleared due to an error that occurred
 * @param status - an optional HTTP status code for the error
 */
export function clearWorkstepAction(status?: number, isError = false) {
  if (isError && !sqWorkstepsStore.isError && !_.includes([HttpCodes.FORBIDDEN, HttpCodes.NOT_FOUND], status)) {
    errorToast({ messageKey: 'WORKSTEPS.ERROR' });
  }

  flux.dispatch('WORKSTEPS_SET', {
    previous: undefined,
    current: {},
    next: undefined,
    last: undefined,
    isError,
  });
}

/**
 * Gets the current workstep
 *
 * @param workbookId - The workbook ID
 * @param worksheetId - The worksheet ID
 * @returns {Object} The current workstep
 */
export function currentWorkstepAction(workbookId: string, worksheetId: string): Promise<WorkstepOutput> {
  return getWorkstepAction(workbookId, worksheetId);
}

/**
 * Gets the next workstep and set it as the current one.
 *
 * @param workbookId - The workbook ID
 * @param worksheetId - The worksheet ID
 * @returns {Promise} A promise that resolves with the next workstep
 */
export function nextWorkstepAction(workbookId: string, worksheetId: string) {
  return sqWorkstepsStore.next
    ? getWorkstepAction(workbookId, worksheetId, sqWorkstepsStore.next, true)
    : Promise.reject();
}

/**
 * Gets the last workstep and set it as the current one.
 *
 * @param workbookId - The workbook ID
 * @param worksheetId - The worksheet ID
 * @returns {Promise} A promise that resolves with the last workstep
 */
export function lastWorkstepAction(workbookId: string, worksheetId: string) {
  return sqWorkstepsStore.last
    ? getWorkstepAction(workbookId, worksheetId, sqWorkstepsStore.last, true)
    : Promise.reject();
}

/**
 * Gets the previous workstep and set it as the current one.
 *
 * @param workbookId - The workbook ID
 * @param worksheetId - The worksheet ID
 * @returns {Promise} A promise that resolves with the previous workstep
 */
export function previousWorkstepAction(workbookId: string, worksheetId: string) {
  return sqWorkstepsStore.previous
    ? getWorkstepAction(workbookId, worksheetId, sqWorkstepsStore.previous, true)
    : Promise.reject();
}

/**
 * Gets the specified workstep and sets it in the store.
 *
 * @param workbookId - The workbook ID
 * @param worksheetId - The worksheet ID
 * @param workstepId - The workstep ID. If undefined the current workstep will be found.
 * @param setAsCurrent - True if it should be set as the current workstep on the server. Only
 *   applicable if `workstepId` is set.
 * @return {Promise} A promise that resolves with the workstep information.
 */
export function getWorkstepAction(
  workbookId: string,
  worksheetId: string,
  workstepId?: string,
  setAsCurrent?: boolean,
): Promise<WorkstepOutput> {
  return getWorkstep(workbookId, worksheetId, workstepId, setAsCurrent).then((response) => {
    // cloneDeep is used to ensure that the "current" value isn't frozen because that state may be modified before
    // being sent to the state synchronizer
    flux.dispatch('WORKSTEPS_SET', _.cloneDeep(_.pick(response, 'previous', 'current', 'next', 'last')));
    return response;
  });
}
