import { saveContent } from '@/reportEditor/report.actions';
import _ from 'lodash';
import { replaceContentIfExists } from '@/annotation/reportContent.utilities';
import { Content, CONTENT_STATE } from '@/reportEditor/report.constants';
import { clearReportContent, setModalName } from '@/reportEditor/reportContent.actions';

/**
 * Resizes Image content to the provided width and height if the image isn't already sized correctly.
 */
export function resizeContentToGridSize(content: Content, width: number, height: number, force: boolean) {
  if (!_.isEmpty(content) && !content.isReact) {
    if (content.width === width && content.height === height && !force) {
      return Promise.resolve();
    }
    return saveContent({ ...content, width, height }, false, true).then(() => {
      replaceContentIfExists(content.id);
    });
  }
  return Promise.resolve();
}

export function showAddContentModal() {
  clearReportContent();
  setModalName(CONTENT_STATE.WORKBOOK);
}

const TOOLBAR_HEIGHT = 35;
const HEIGHT_CORRECTION = 10;

export function getAdjustedContentHeight(height: number, showTitle = true) {
  return height - (showTitle ? TOOLBAR_HEIGHT : HEIGHT_CORRECTION);
}
