// @ts-strict-ignore
import React from 'react';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { Field, useForm } from 'react-final-form';
import { FormControl } from 'react-bootstrap';
import { getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import _ from 'lodash';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';

export interface ValueWithSymbolIF extends ValidatingFormComponent<string> {
  component: 'ValueWithSymbolFormComponent';
  symbol: string | JSX.Element;
  label?: string;
  id?: string;
  min?: number;
  max?: number;
}

/**
 * Text input field (form component) with symbol/unit
 */
export const ValueWithSymbolFormComponent: React.FunctionComponent<ValueWithSymbolIF> = (props) => {
  const {
    name,
    testId = 'valueWithSymbol',
    label,
    symbol,
    onChange,
    validation,
    extendValidation,
    customErrorText,
    min,
    max,
  } = props;

  const defaultValidation = (value: string) => {
    if (!value) return true;

    const hasMin = _.isNumber(min),
      hasMax = _.isNumber(max);
    const numberValue = Number(value);
    if (hasMin || hasMax) {
      return (
        !_.isNumber(numberValue) ||
        _.isNaN(numberValue) ||
        (hasMin && numberValue < min) ||
        (hasMax && numberValue > max)
      );
    }

    return false;
  };

  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);
  const formState = useForm().getState();
  const modified =
    _.chain(formState.modified)
      .entries()
      .map((entry) => entry[1])
      .compact()
      .size()
      .value() > 0;

  const showError =
    _.has(formState.errors, name) && formState.hasValidationErrors && (modified || formState.submitFailed);

  return (
    <FormFieldWrapper
      testId={testId}
      label={label}
      showError={showError}
      customErrorText={customErrorText}
      wrapperClassNames="flexBaseline">
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          const formFieldProps = getFormFieldProps(formState, input, meta, props);
          return (
            <div className="input-group width-80">
              <FormControl
                name="value"
                value={formFieldProps.value}
                onChange={(e) => onChange(e.target.value)}
                data-testid={`${testId}Input`}
                min={min}
                max={max}
                isInvalid={showError}
              />
              <span className="input-group-text sq-btn input-md" data-testid={`${testId}Symbol`}>
                {symbol}
              </span>
            </div>
          );
        }}
      </Field>
    </FormFieldWrapper>
  );
};
