export interface AssetGroupInputParameter {
  assetGroupColumnBased?: boolean; // is it a "regular" input param or is it based on the column?
  name: string;
  item: AssetGroupItem;
}

export interface AssetGroupItem {
  id: string;
  name: string;
  type?: string;
}

export const isAssetGroupAsset = (item: AssetGroupAsset | AssetGroupChild): item is AssetGroupAsset => {
  return 'children' in item;
};

export interface AssetGroupChild {
  id?: string; // only previously saved children will have one
  name: string;
  formula: string;
  parameters: AssetGroupInputParameter[];
  manuallyAdded?: boolean; // if a user hand picks it
  originalMapping?: string;
  assetPath?: string;
  dependencies?: AssetGroupChild[];
  // Note: columnType and type are different! columnType is what is used to make asset group editor related
  // decisions of how a cell is rendered (e.g: checkmarks vs. function icons). The "type" is the proper Seeq type of
  // the item!
  columnType: 'Calculation' | 'Item';
  type?: string;
  addedBy?: string;
}

export interface AssetAncestor {
  name: string;
  assetPath?: string | undefined;
  id?: string;
  type?: string;
  ancestors?: AssetAncestor[];
}

export interface AssetGroupAsset {
  path?: string; // this is a helper to dedupe names
  children?: AssetGroupChild[];
  assetPath?: string | undefined;
  name: string;
  id?: string;
  type?: string;
  ancestors: AssetAncestor[];
  manuallyAdded?: boolean; // if a user hand picks it
}

export interface AssetGroup {
  id?: string;
  name: string;
  description?: string;
  assets: AssetGroupAsset[];
  addedBy: string;
}

export interface AssetGroupStoreState extends AssetGroup {
  errors: string[];
  isLoading: boolean;
  hasUnsavedChanges: boolean;
  nameError: boolean;
}
