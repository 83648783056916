import _ from 'lodash';
import { useEffect } from 'react';

export const useUpdateHeaderBackground = (headerBackgroundColor: string | undefined, eGridHeader: HTMLElement) => {
  useEffect(() => {
    const shouldUpdateBackgroundColor =
      !_.isNil(headerBackgroundColor) &&
      !_.isNil(eGridHeader) &&
      eGridHeader.style.backgroundColor !== headerBackgroundColor;

    if (shouldUpdateBackgroundColor) {
      eGridHeader.style.backgroundColor = headerBackgroundColor;
    }
  }, [headerBackgroundColor]);
};
