// @ts-strict-ignore
import React from 'react';
import _ from 'lodash';
import { sqLicenseManagementStore, sqWorkbenchStore } from '@/core/core.stores';
import { getUserTrackingData } from '@/utilities/utilities';
import {
  adminContactEmail,
  getFeedbackEnabled,
  isSeeqPreviewEnabled,
  isTelemetryAnonymized,
  seeqVersion,
} from '@/services/systemConfiguration.utilities';
import { isSystemTest } from '@/core/utilities';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';

export const MOPINION_DEPLOYMENT_ID = 'PofxdKZReEC2L0ZHGVf4pmX2Hw6xfVLx7DKkjbOu';

export const shouldFeedbackDisplay = () => getFeedbackEnabled() && !isSystemTest() && !headlessRenderMode();
export const getFeedbackScript: () => HTMLElement | null = () => document.getElementById('mopinionScript');
export const getFeedbackButton: () => HTMLElement | null = () => document.querySelector('.btn-open-survey');

/**
 * This component includes a mopinion feedback collection form as part of the application.
 */
export const GetFeedback: React.FunctionComponent = () => {
  React.useEffect(() => {
    if (shouldFeedbackDisplay()) {
      const contractId = _.isEmpty(sqLicenseManagementStore.license.contractNumber)
        ? 'DEV'
        : sqLicenseManagementStore.license.contractNumber;
      const { serverEmail, userName, userEmail } = getUserTrackingData({
        anonymizeTelemetry: isTelemetryAnonymized(),
        adminEmail: adminContactEmail(),
        username: sqWorkbenchStore.currentUser.username,
        userEmail: sqWorkbenchStore.currentUser.email,
      });

      // This data will be exposed globally so that we can include it as part of the feedback information collected.
      // To ensure this data is persisted as part of the feedback collection the form must contain a "block" that
      // sends website data and points to the mopinionTargetData variable.
      const mopinionTargetData = {
        contractId,
        userName,
        userEmail,
        serverEmail,
        seeqVersion: seeqVersion(),
        isPreview: isSeeqPreviewEnabled(),
        language: sqWorkbenchStore.userLanguage,
      };
      window['mopinionTargetData'] = mopinionTargetData;
      addMOpinionForm(MOPINION_DEPLOYMENT_ID);
    }
  }, []);

  return null;
};

// This piece of code is copy & paste from mopinion - it adds a script tag that loads the feedback forms.
export function addMOpinionForm(deploymentId) {
  let blockedByCSP = false; // Loading this script can fail if the Content Security Policy blocks it
  const js = document.createElement('script');
  js.setAttribute('type', 'text/javascript');
  js.setAttribute('src', 'https://deploy.mopinion.com/js/pastease.js');
  js.setAttribute('id', 'mopinionScript');
  js.async = true;
  js.onerror = () => {
    blockedByCSP = true;
  };
  document.getElementsByTagName('body')[0].appendChild(js);

  // Repeat until the script is loaded and the button is shown
  let tries = 0;
  let loaded = false;
  const t = setInterval(function () {
    try {
      if (blockedByCSP) {
        clearInterval(t);
        return;
      }

      if (!loaded) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Pastease not from our environment
        Pastease.load(deploymentId);
        loaded = true;
      }

      const feedbackButton = getFeedbackButton();
      if (feedbackButton) {
        // Modify the style to match the width of the Worksheets panel (note: mopinion styles are always inlined)
        feedbackButton.style.width = '132px';
        clearInterval(t);
      } else if (tries++ >= 100) {
        // Give up, the button may not show after all
        clearInterval(t);
      }
    } catch (e) {
      // do nothing
    }
  }, 50);
}
