import { Dispatch, SetStateAction, useEffect, useState } from 'react';

/**
 * Combines useState and useEffect to manage state with side effects.
 * @template T - The type of the state value.
 * @param initialState - The initial state value.
 * @returns A tuple containing the current state value and a function to update the state.
 */
export function useStateEffect<T>(initialState: T): [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState<T>(initialState);

  useEffect(() => {
    setState(initialState);
  }, [initialState]);

  return [state, setState];
}
