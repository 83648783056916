import _ from 'lodash';
import tinycolor from 'tinycolor2';

const HSL_REGEX = /hsl(a?)\(\s*(\d+)\s*,\s*(\d*(?:\.\d+)?%)\s*,\s*(\d*(?:\.\d+)?%)(,\s*(\d*(?:\.\d+)?))?\)/gi;

// hsla(321, 10%, 45%, 0.5)  =>  #EE34A2A0
const hslaToHex = (h: number, s: number, l: number, alpha: number | null = null): string => {
  let hslString = hslToHex(h, s, l);
  if (!_.isNull(alpha)) {
    hslString += rgbToHex(alpha);
  }
  return hslString;
};

const hslToHex = (h: number, s: number, l: number): string => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

const rgbToHex = (rgb: number): string => {
  let hex = Number(rgb).toString(16);
  if (hex.length < 2) {
    hex = `0${hex}`;
  }
  return hex;
};

export const convertContentHslToHex = (input: string): string => {
  const allColors = input.matchAll(HSL_REGEX);
  let iterator = allColors.next();
  let convertedString = '';
  let oldIndex = 0;
  while (!iterator.done) {
    const match = iterator.value;
    const rgbValue = hslaToHex(
      _.parseInt(match[2]),
      _.parseInt(match[3]),
      _.parseInt(match[4]),
      match[6] ? _.toInteger(_.toNumber(match[6]) * 255) : null,
    );
    convertedString += `${input.substring(oldIndex, match.index)}${rgbValue}`;
    oldIndex = (match?.index ?? 0) + match[0].length;
    iterator = allColors.next();
  }
  convertedString += `${input.substring(oldIndex)}`;

  return convertedString;
};

export const convertImagesSrc = (input: string): string => {
  const containerElement = window.document.createElement('div');
  containerElement.innerHTML = input;

  containerElement.querySelectorAll('img').forEach((img) => {
    const srcData = getImageDataURL(img);
    img.setAttribute('src', srcData);
  });

  return containerElement.innerHTML;
};

/**
 * Creates the dataURL of an image by converting it to base64. If it is already in base64 or does not have a dimension
 * then the original src is returned.
 *
 * @param img - the input image
 * @returns dataURL of the image that can be used as the src of an image
 */
export const getImageDataURL = (img: HTMLImageElement): string => {
  const canvas = document.createElement('canvas');
  const src = img.getAttribute('src');
  if (!src || src.startsWith('data:') || !img.naturalWidth || !img.naturalHeight) {
    return src ?? '';
  }
  // naturalWidth & naturalHeight are used because width and height can result in cropped images on paste
  canvas.width = img.naturalWidth;
  canvas.height = img.naturalHeight;
  const ctx = canvas.getContext('2d');
  ctx?.drawImage(img as HTMLImageElement, 0, 0, canvas.width, canvas.height);
  return canvas.toDataURL('image/png');
};

/**
 * Compute a color so that it is as close to white (#fff) as possible.
 *
 * @param color - The color for which we calculate the factor
 * @param darkenFactor - A fractional number that darkens the color. Default of 0 means it does not
 * darken at all.
 * @param prevFactor - previous value of the factor (used internally for recursive search)
 * @param factor - factor value to check if we reached white color (used internally for recursive search)
 * @param maxFactor - the maximum factor value (used internally for recursive search)
 * @return {string} the color, as a hex string, that is closest to white, but adjusted using the darkenFactor
 */
export const computeLightestColor = (
  color: string,
  darkenFactor = 0,
  prevFactor = 0,
  factor = 50,
  maxFactor = 100,
): string | undefined => {
  if (factor >= maxFactor) {
    return tinycolor(color)
      .lighten(prevFactor - prevFactor * darkenFactor)
      .toString('rgb');
  }
  if (tinycolor(color).lighten(factor).toString() === '#ffffff') {
    return computeLightestColor(color, darkenFactor, factor, Math.round(factor / 2), factor);
  }
  if (tinycolor(color).lighten(factor).toString() !== '#ffffff') {
    return computeLightestColor(color, darkenFactor, factor, factor + Math.round((maxFactor - factor) / 2), maxFactor);
  }
};

/**
 * Adds a listener that will show the built-in browser confirmation dialog if the callback returns true and the page is
 * closed. Requires some strange hacks to get it to work on different browsers: https://stackoverflow.com/a/61404006
 *
 * @param showConfirmationCallback - If it returns true the dialog will be shown
 * @return A callback that will remove the listener
 */
export function addBeforeUnloadConfirmation(showConfirmationCallback: () => boolean): () => void {
  const listener = (e: BeforeUnloadEvent) => {
    if (showConfirmationCallback()) {
      e.preventDefault();
      e.returnValue = '';
      return;
    }

    delete e['returnValue'];
  };

  window.addEventListener('beforeunload', listener);
  return () => window.removeEventListener('beforeunload', listener);
}
