// @ts-strict-ignore
import _ from 'lodash';
import moment from 'moment-timezone';
import { parseDuration } from '@/datetime/dateTime.utilities';
import { InitializeMode, PersistenceLevel, Store } from '@/core/flux.service';
import { ValueWithUnitsItem } from '@/trend/ValueWithUnits.atom';
import {
  AUTO_UPDATE,
  DEFAULT_DISPLAY_RANGE_DURATION_DAYS,
  DEFAULT_INVESTIGATE_RANGE_DURATION_DAYS,
} from '@/trendData/trendData.constants';

export interface RangeExport {
  start: moment.Moment;
  end: moment.Moment;
  duration: moment.Duration;
}

export type AutoUpdateMode = 'AUTO' | 'MANUAL' | 'OFF';

export interface AutoUpdateData {
  mode: AutoUpdateMode;
  offset: number;
  interval: number;
  manualInterval: ValueWithUnitsItem;
  autoInterval: number;
  displayPixels: number;
  now: number;
}

export class DurationStore extends Store {
  persistenceLevel: PersistenceLevel = 'WORKSHEET';
  static readonly storeName = 'sqDurationStore';

  initialize(initializeMode: InitializeMode) {
    moment.locale(window.navigator.language);
    const now = moment.utc().valueOf();
    const saveState = this.state && initializeMode !== 'FORCE';

    this.state = this.immutable(
      {
        // Avoid clearing state that is not dehydrated when the store is re-initialized. Async calls will repopulate
        // these properties as needed
        autoUpdate: {
          mode: AUTO_UPDATE.MODES.OFF,
          offset: 0,
          interval: saveState ? this.state.get(['autoUpdate', 'interval']) : AUTO_UPDATE.MIN_INTERVAL,
          manualInterval: AUTO_UPDATE.DEFAULT_INTERVAL,
          autoInterval: saveState ? this.state.get(['autoUpdate', 'autoInterval']) : 0,
          displayPixels: saveState ? this.state.get(['autoUpdate', 'displayPixels']) : 0,
          now,
        } as AutoUpdateData,

        displayRange: {
          start: moment.utc(now).subtract(DEFAULT_DISPLAY_RANGE_DURATION_DAYS, 'days').valueOf(),
          end: now,
          momentRange: this.monkey(
            ['displayRange', 'start'],
            ['displayRange', 'end'],
            (start, end) => ({
              start: moment.utc(start),
              end: moment.utc(end),
              duration: moment.duration(end - start),
            }),
            { immutable: false },
          ),
        },
        investigateRange: {
          start: moment.utc(now).subtract(DEFAULT_INVESTIGATE_RANGE_DURATION_DAYS, 'days').valueOf(),
          end: now,
          momentRange: this.monkey(
            ['investigateRange', 'start'],
            ['investigateRange', 'end'],
            (start, end) => ({
              start: moment.utc(start),
              end: moment.utc(end),
              duration: moment.duration(end - start),
            }),
            { immutable: false },
          ),
        },
      },
      {
        asynchronous: false, // This store emits instantly to ensure the chart scrolls smoothly
      },
    );
  }

  get autoUpdate(): AutoUpdateData {
    return this.state.get('autoUpdate');
  }

  get displayRange(): RangeExport {
    return this.state.get('displayRange', 'momentRange');
  }

  get investigateRange(): RangeExport {
    return this.state.get('investigateRange', 'momentRange');
  }

  get displayPixels(): number {
    return this.state.get(['autoUpdate', 'displayPixels']);
  }

  /*********************************************** Auto Update ***************************************************/

  /**
   * Helper function that computes autoInterval and interval values.
   */
  private autoUpdateComputeIntervals() {
    let interval, autoInterval;
    const mode = this.state.get(['autoUpdate', 'mode']);
    const displayPixels = this.state.get(['autoUpdate', 'displayPixels']);
    const manualInterval = this.state.get(['autoUpdate', 'manualInterval']);
    const displayDuration = this.state.get(['displayRange', 'momentRange', 'duration']);

    // Compute the auto interval based on the display range duration and number of display pixels
    if (displayPixels && displayDuration.valueOf()) {
      autoInterval = Math.round(Math.max(AUTO_UPDATE.MIN_INTERVAL, displayDuration.valueOf() / displayPixels));
    } else {
      autoInterval = parseDuration(AUTO_UPDATE.DEFAULT_INTERVAL.value + AUTO_UPDATE.DEFAULT_INTERVAL.units).valueOf();
    }

    this.state.set(['autoUpdate', 'autoInterval'], autoInterval);

    if (mode === AUTO_UPDATE.MODES.AUTO) {
      interval = autoInterval;
    } else if (mode === AUTO_UPDATE.MODES.MANUAL) {
      interval = parseDuration(manualInterval.value + manualInterval.units).valueOf();
    } else {
      interval = AUTO_UPDATE.MIN_INTERVAL;
    }

    this.state.set(['autoUpdate', 'interval'], interval);
  }

  /********************************************* Display Range *************************************************/

  /**
   * Updates display range start and end times. Duration is automatically updated to reflect the new range.
   *
   * @param {Object} payload - Object container for arguments
   * @param {moment} payload.start - New display range start time
   * @param {moment} payload.end - New display range end time
   */
  private updateDisplayRangeTimes(payload) {
    const endMoment = moment.utc(payload.end);
    const startMoment = moment.utc(payload.start);

    this.state.merge('displayRange', {
      start: startMoment.valueOf(),
      end: endMoment.valueOf(),
    });
    this.autoUpdateComputeIntervals();
  }

  /******************************************** Investigate Range ************************************************/

  /**
   * Updates investigate range start and end times. Duration is automatically updated to reflect the new range.
   *
   * @param {Object} payload - Object container for arguments
   * @param {moment} payload.start - New investigate range start time
   * @param {moment} payload.end - New investigate range end time
   */
  private updateInvestigateRangeTimes(payload) {
    const endMoment = moment.utc(payload.end);
    const startMoment = moment.utc(payload.start);

    this.state.merge('investigateRange', {
      start: startMoment.valueOf(),
      end: endMoment.valueOf(),
    });
  }

  protected readonly handlers = {
    AUTO_UPDATE_SET_NOW: ({ now }) => {
      this.state.set(['autoUpdate', 'now'], now);
    },
    AUTO_UPDATE_SET_MODE: ({ mode }) => {
      this.state.set(['autoUpdate', 'mode'], mode);
      this.autoUpdateComputeIntervals();
    },
    AUTO_UPDATE_COMPUTE_OFFSET: () => {
      const offset = this.state.get('displayRange', 'end') - this.state.get('autoUpdate', 'now');
      this.state.set(['autoUpdate', 'offset'], offset);
    },
    AUTO_UPDATE_CLEAR_OFFSET: () => {
      this.state.set(['autoUpdate', 'offset'], 0);
    },
    AUTO_UPDATE_SET_MANUAL_INTERVAL: ({ manualInterval }) => {
      this.state.set(['autoUpdate', 'manualInterval'], manualInterval);
      this.autoUpdateComputeIntervals();
    },
    AUTO_UPDATE_SET_DISPLAY_PIXELS: ({ displayPixels }) => {
      this.state.set(['autoUpdate', 'displayPixels'], displayPixels);
      this.autoUpdateComputeIntervals();
    },
    UPDATE_DISPLAY_RANGE_TIMES: this.updateDisplayRangeTimes,
    UPDATE_INVESTIGATE_RANGE_TIMES: this.updateInvestigateRangeTimes,
  };

  dehydrate() {
    const state = this.state.serialize();
    state.autoUpdate = _.omit(state.autoUpdate, ['now', 'interval', 'autoInterval', 'displayPixels']);

    return state;
  }

  rehydrate(newState) {
    this.state.merge('autoUpdate', newState.autoUpdate);
    this.autoUpdateComputeIntervals();

    if (newState.investigateRange) {
      this.updateInvestigateRangeTimes({
        end: newState.investigateRange.end,
        start: newState.investigateRange.start,
      });
    }

    if (newState.displayRange) {
      this.updateDisplayRangeTimes({
        end: newState.displayRange.end,
        start: newState.displayRange.start,
      });
    }
  }
}
