import React from 'react';
import { isValueCaptureToolEnabled } from '@/services/systemConfiguration.utilities';
import { Unauthorized } from '@/main/Unauthorized.page';

export const ValueCapture: React.FunctionComponent = () => {
  if (!isValueCaptureToolEnabled()) return <Unauthorized />;

  return (
    <div className="flexColumnContainer flexFill flexCenter height-maximum">
      <div>Coming soon</div>
    </div>
  );
};
