import { TREND_SIGNAL_STATS, TREND_VIEWS } from '@/trendData/trendData.constants';
import _ from 'lodash';
import { getAllItems } from '@/trend/trendDataHelper.utilities';
import { RefObject } from 'react';
import i18next from 'i18next';
import {
  sqTrendCapsuleStore,
  sqTrendScalarStore,
  sqTrendSeriesStore,
  sqTrendStore,
  sqWorksheetStore,
} from '@/core/core.stores';
import { tabsetChangeTab } from '@/worksheet/worksheet.actions';
import { selectTrendItems } from '@/trendData/trend.actions';

export const hasChainViewDimmingError = (): boolean => {
  const hasSelectedConditions = _.some(sqTrendCapsuleStore.chartItems, ['selected', true]);
  const noSelectedConditions = !hasSelectedConditions && _.some(getAllItems({}), ['selected', true]);

  const noCapsulesForSelectedConditions =
    hasSelectedConditions &&
    _.chain(sqTrendCapsuleStore.chartItems)
      .filter(['selected', true])
      .filter((item) => _.some(item.capsules, (capsule) => capsule.endTime - capsule.startTime > 0))
      .value().length === 0;

  return noSelectedConditions || noCapsulesForSelectedConditions || !sqTrendCapsuleStore.hasDisplayedCapsules();
};

type CanShowChartParams = {
  initialLoadFinished: boolean;
  isCompareMode: boolean;
  view: TREND_VIEWS;
  chartItems: any[];
};

/**
 * Determines if the chart should be shown based on the mode and the chart items.
 *
 * @returns {Boolean} True if the chart should be shown, false otherwise
 */
export const checkCanShowChart = ({
  initialLoadFinished,
  isCompareMode,
  view,
  chartItems,
}: CanShowChartParams): boolean => {
  if (!initialLoadFinished && !isCompareMode) {
    // Assume we can show the chart for the first 2000ms (see timeout in zero-dependency useEffect).
    // This avoids a 'blue flash' of help text that disappears as soon as capsules load in chain view (see below).
    return true;
  }

  if (isCompareMode) {
    return (
      sqTrendCapsuleStore.hasDisplayedCapsules() &&
      (!!sqTrendSeriesStore.capsuleSeries.length || !!sqTrendScalarStore.items.length) &&
      !!sqTrendStore.separateByProperty &&
      !!sqTrendStore.colorByProperty
    );
  }

  if (view === TREND_VIEWS.CAPSULE) {
    if (sqWorksheetStore.capsuleGroupMode && _.isEmpty(sqWorksheetStore.conditionToSeriesGrouping)) {
      return false;
    }

    return (
      sqTrendCapsuleStore.hasDisplayedCapsules() &&
      (!!sqTrendSeriesStore.capsuleSeries.length || !!sqTrendScalarStore.items.length)
    );
  }

  if (view === TREND_VIEWS.CHAIN) {
    return sqTrendStore.hideUnselectedItems ? !hasChainViewDimmingError() : sqTrendCapsuleStore.hasDisplayedCapsules();
  }

  return !!chartItems?.length;
};

/**
 * Helper function that exposes the browse panel and switches to the Tools tab.
 */
export const showToolPanels = (): void => {
  tabsetChangeTab('sidebar', 'investigate');
};

type DetermineBarChartHeightParams = {
  chartItems: any[];
  displayTables: any[];
  trendDisplayAreaRef: RefObject<HTMLDivElement>;
};

/**
 * Determines the height for the tableVisualisation directives. Based on the number of lanes the height will vary
 */
export const determineBarChartHeight = ({
  chartItems,
  displayTables,
  trendDisplayAreaRef,
}: DetermineBarChartHeightParams) => {
  const displayedItemsCount = _.get(_.chain(chartItems).map('lane').uniq().value(), 'length', 0);
  const displayedTablesCount = _.get(displayTables, 'length', 0);

  return (trendDisplayAreaRef.current?.clientHeight ?? 1) / (displayedItemsCount + displayedTablesCount);
};

type SelectItemsParams = {
  /** The item to be selected */
  item: any;
  /** The collection that this item belongs to */
  items: any[];
  /** The click event */
  e: any;
};

export const selectItems = ({ item, items, e }: SelectItemsParams) => {
  if (item.notFullyVisible) {
    return;
  }

  selectTrendItems(item, items, e);
};

/**
 * Gets the title for a column from the capsules pane
 *
 * @param {Object} column - One of the columns
 *
 * @return {string} The title of the column
 */
export function getCapsuleColumnTitle(column: any) {
  return (
    i18next.t(column.title) ||
    column.propertyName ||
    `${sqTrendSeriesStore.findItem(column.referenceSeries)?.name} ${i18next.t(
      _.find(TREND_SIGNAL_STATS, ['key', column.statisticKey])?.title ?? '',
    )}`
  );
}
