import { DURATION_TIME_UNITS } from '@/main/app.constants';
import _ from 'lodash';
import { useState } from 'react';
import { quartzCronExpressionHelper } from '@/annotation/reportContent.utilities';
import { ScheduleParser } from '@/schedule/scheduleParser';
import {
  AutoUpdateTimeScheduleEntry,
  LiveUpdateRate,
  ScheduleType,
  ScheduleTypeName,
  UpdatedSchedule,
} from '@/schedule/schedule.types';
import { DEFAULT_LIVE_RATE, DEFAULT_TIME_ENTRY } from '@/schedule/schedule.constants';
import { defaultScheduleType, determineTimeEntries } from '@/schedule/schedule.utilities';
import { Timezone } from '@/datetime/timezone.service';

export const useSchedule = (
  cronSchedule: string[] | undefined,
  updatedSchedule: UpdatedSchedule | undefined,
  initialScheduleType: ScheduleTypeName,
  timezoneDefault: Timezone | undefined,
  defaultLiveRate: LiveUpdateRate = DEFAULT_LIVE_RATE,
) => {
  /**
   * Determine whether the schedule has been configured or not.
   *
   * @returns - false if a cronSchedule is missing or set to the pending schedule true otherwise
   */
  function isFirstTimeConfiguring(): boolean {
    return _.isEmpty(cronSchedule);
  }

  const [timezone, setTimezone] = useState<Timezone | undefined>(timezoneDefault);
  const [irregularSchedule, setIrregularSchedule] = useState<string[]>();

  const [selectedScheduleType, setSelectedScheduleType] = useState((): ScheduleType => {
    if (updatedSchedule?.selectedScheduleType) {
      return updatedSchedule.selectedScheduleType;
    }

    if (isFirstTimeConfiguring()) {
      return _.merge(defaultScheduleType(), { selectedType: initialScheduleType });
    }

    try {
      return ScheduleParser.determineScheduleType(cronSchedule ?? [], quartzCronExpressionHelper());
    } catch (e) {
      setIrregularSchedule(cronSchedule);
    }

    return defaultScheduleType();
  });

  const [timeEntries, setTimeEntries] = useState((): AutoUpdateTimeScheduleEntry[] => {
    if (updatedSchedule?.timeEntries) {
      return updatedSchedule.timeEntries;
    }

    // "Live" date range does not have time entries
    if (isFirstTimeConfiguring() || selectedScheduleType.selectedType === ScheduleTypeName.LIVE) {
      return [DEFAULT_TIME_ENTRY];
    }

    try {
      const allTimeEntries: AutoUpdateTimeScheduleEntry[] = [];
      _.forEach(cronSchedule, (schedule) => {
        const { minutes, hours } = quartzCronExpressionHelper().parse(schedule);
        allTimeEntries.push(...determineTimeEntries(minutes, hours));
      });

      return allTimeEntries;
    } catch (e) {
      setIrregularSchedule(cronSchedule);
    }

    return [DEFAULT_TIME_ENTRY];
  });

  const [liveRate, setLiveRate] = useState((): LiveUpdateRate => {
    if (updatedSchedule?.liveRate) {
      return updatedSchedule.liveRate;
    }

    if (isFirstTimeConfiguring() || selectedScheduleType.selectedType !== ScheduleTypeName.LIVE) {
      return defaultLiveRate;
    }

    const helper = quartzCronExpressionHelper();
    const rate = cronSchedule?.map(helper.cronScheduleToRate).find((rate) => rate !== undefined);

    if (_.isNil(rate)) {
      setIrregularSchedule(cronSchedule);

      return defaultLiveRate;
    }

    return {
      value: rate.value,
      unit: DURATION_TIME_UNITS.find(({ unit }) => unit.includes(rate.units))!,
    };
  });

  const onLiveScheduleInvalidInput = () => {
    setLiveRate(defaultLiveRate);
    setIrregularSchedule(cronSchedule);
  };

  return {
    selectedScheduleType,
    setSelectedScheduleType,
    isLive: selectedScheduleType.selectedType === ScheduleTypeName.LIVE,
    liveRate,
    setLiveRate,
    timeEntries,
    setTimeEntries,
    irregularSchedule,
    setIrregularSchedule,
    onLiveScheduleInvalidInput,
    timezone,
    setTimezone,
  };
};
